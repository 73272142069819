import { useAppSelector } from '../../../../Redux/Store';
import { TenantSettingsConstant } from '../../../../utils/constants/TenantSettingsConstant';
import { IFilterSearchBox } from '../BuyPo/interface/SettingsInterface';

export const FilterSearchBox = ({
    filterType,
    handleFilter,
    searchQuery,
    setSearchQuery,
}: IFilterSearchBox) => {
    const { filter } = useAppSelector(state => state.tenantSetting);
    return (
        <>
            <div
                className="o-switch btn-group show-toggle my-2"
                data-toggle="buttons"
                role="group"
            >
                {TenantSettingsConstant.filter.filterOptions.map(option => (
                    <label
                        key={option.value}
                        className={`btn btn-secondary py-1 ${
                            filter[filterType].show === option.value
                                ? TenantSettingsConstant.filter.active.value
                                : ''
                        }`}
                    >
                        <input
                            type="radio"
                            onChange={() =>
                                handleFilter(option.name, option.value)
                            }
                            checked={filter[filterType].show === option.value}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
            <input
                className="text-secondary "
                maxLength={TenantSettingsConstant.filter.killGroup.maxLength}
                type="text"
                placeholder={
                    TenantSettingsConstant.filter.killGroup.placeholder
                }
                name={TenantSettingsConstant.filter.killGroup.value}
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
            />
        </>
    );
};
