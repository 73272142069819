import { useEffect, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { getToastContent } from '../../../../../utils/ToastContent';
import FullPageLoader from '../../../../../components/common/FullPageLoader';
import { validateAccordingType } from '../../../../../utils/validation';
import {
    BuyPOGlobalInfoConstants,
    BuyPoOrderConstants,
} from '../../BuyPo/constants/BuyPoConstants';
import { useAppDispatch } from '../../../../../Redux/Store';
import { CommonConstants } from '../../../../../utils/constants/CommonConstants';
import {
    IBreedDetail,
    IBreedModal,
    IFetchBreedResponse,
    IValueAndMaxLength,
} from '../interface/BuyPoInterface';
import {
    activateBreed,
    createNewBreed,
    fetchInactiveBreedInfo,
    handleEditBreed,
} from '../actions/buyPoActions';
import { toastMessage } from '../../../../../Redux/Reducer/common';
import { useModalStatus } from '../../../../../commonHooks/useModalStatus';
import { ActivateItemModal } from './ActivateItemModal';

const CreateBreedModal = ({
    showModal,
    closeModalHandler,
    editBreedDetails,
    onUpdate,
}: IBreedModal) => {
    const [breedDetail, setBreedDetail] = useState<IBreedDetail>({
        ...BuyPoOrderConstants.initialState.breedDetail,
    });
    const [breedError, setBreedError] = useState({
        ...BuyPoOrderConstants.initialState.breedError,
    });
    const [inActiveBreed, setInActiveBreed] = useState<IBreedDetail>();
    const {
        isOpen: isActivateModalOpen,
        open: openActivateModal,
        close: closeActivateModal,
    } = useModalStatus();

    const [loader, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    const setBreedErrorFields = (fieldName: string, value: boolean) => {
        const breedErrorCopy = { ...breedError };
        breedErrorCopy[fieldName] = value;
        setBreedError(breedErrorCopy);
    };

    const setBreedDetailFields = (
        fieldName: string,
        fieldValue: string,
        type?: string,
    ) => {
        const isValid = validateAccordingType(type, fieldValue);

        if (isValid) {
            const breedDetailCopy = { ...breedDetail };
            breedDetailCopy[fieldName] = fieldValue;
            setBreedDetail(breedDetailCopy);
            if (breedError[fieldName]) {
                setBreedErrorFields(fieldName, false);
            }
        }
    };

    const fetchBreedPayload = () => {
        return { ...breedDetail, name: breedDetail.name.trim() };
    };

    const fetchBreedId = (response: IFetchBreedResponse) => {
        return response.data ? response.data.data.id : '';
    };

    const fetchEditBreedPayload = () => ({
        name: breedDetail.name,
        id: editBreedDetails?.id,
        isActive: breedDetail.isActive,
    });

    const handleCreateBreedResponse = (response: IFetchBreedResponse) => {
        onUpdate?.(fetchEditBreedPayload());
        const breedId = fetchBreedId(response);
        dispatch(toastMessage(getToastContent(response.status)));
        closeModalHandler(true, breedId);
    };

    const handleActivateBreed = async () => {
        const inactiveBreed = await fetchInactiveBreedInfo(
            breedDetail.name.trim(),
        );
        setInActiveBreed(inactiveBreed[0]);
        openActivateModal();
    };

    const dynamicForm = (
        inputKey: string,
        inputLabel: string,
        valueAndMaxLength: IValueAndMaxLength,
        valueType?: string,
        inputType = 'text',
    ) => {
        return (
            <>
                <Form.Label className="text-secondary">
                    {inputLabel}
                    <span className="necessaryFields">*</span>
                </Form.Label>
                <Form.Control
                    {...valueAndMaxLength}
                    onChange={e =>
                        setBreedDetailFields(
                            inputKey,
                            e.target.value,
                            valueType,
                        )
                    }
                    style={{
                        borderColor: breedError[inputKey] ? 'red' : '',
                    }}
                    className="text-secondary"
                    size="sm"
                    type={inputType}
                />
            </>
        );
    };

    const validateBreedFields = () => {
        const breedDetailKeys = Object.keys(breedDetail);
        const breedErrorCopy = { ...breedError };
        let isError = false;

        breedDetailKeys.forEach(breedDetailKey => {
            if (
                !breedDetail[breedDetailKey] &&
                breedDetailKey !== BuyPOGlobalInfoConstants.isActive
            ) {
                breedErrorCopy[breedDetailKey] = true;
                isError = true;
            }
        });

        setBreedError(breedErrorCopy);
        return isError;
    };

    const handleCreateBreed = () => {
        const isError = validateBreedFields();

        if (!isError) {
            if (!editBreedDetails) {
                dispatch(
                    createNewBreed(
                        setLoading,
                        fetchBreedPayload,
                        handleCreateBreedResponse,
                        handleActivateBreed,
                    ),
                );
            } else {
                dispatch(
                    handleEditBreed(
                        setLoading,
                        handleCreateBreedResponse,
                        fetchEditBreedPayload,
                        handleActivateBreed,
                    ),
                );
            }
        }
    };

    const handleCloseActivateModal = async (
        isActive: boolean,
    ): Promise<void> => {
        if (isActive) {
            if (inActiveBreed) {
                const inActiveBreedCopy = {
                    ...inActiveBreed,
                    isActive: true,
                };
                await dispatch(activateBreed(inActiveBreedCopy));
                closeActivateModal();
                closeModalHandler(true, inActiveBreed.id);
            }
        } else {
            closeActivateModal();
        }
    };

    useEffect(() => {
        setBreedDetail({
            ...BuyPoOrderConstants.initialState.breedDetail,
        });
        setBreedError({ ...BuyPoOrderConstants.initialState.breedError });
    }, [showModal]);

    useEffect(() => {
        if (editBreedDetails && Object.keys(editBreedDetails).length > 0) {
            setBreedDetail(editBreedDetails);
        }
    }, [editBreedDetails]);

    return (
        <>
            <ActivateItemModal
                showModal={isActivateModalOpen}
                closeModalHandler={handleCloseActivateModal}
                itemName={breedDetail.name.trim()}
                item={BuyPoOrderConstants.form.breed.activateModalConstant}
            />
            <Modal
                className="px-2 pt-5"
                size="lg"
                show={showModal}
                centered
                animation
                onHide={() => closeModalHandler(false, '')}
            >
                {loader && <FullPageLoader />}
                <Modal.Header closeButton className="border-bottom-0">
                    <Modal.Title className="text-dark">
                        {!editBreedDetails
                            ? BuyPoOrderConstants.form.breed.headingLabel.create
                            : BuyPoOrderConstants.form.breed.headingLabel
                                  .update}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="border-bottom">
                        <div className="col-xl-6 col-lg-6 start-top mb-4">
                            {dynamicForm(
                                BuyPoOrderConstants.dynamicFields.breedName.key,
                                BuyPoOrderConstants.dynamicFields.breedName
                                    .label,
                                {
                                    value: breedDetail.name,
                                    maxLength:
                                        BuyPoOrderConstants.dynamicFields
                                            .breedName.maxLength,
                                },
                                BuyPoOrderConstants.dynamicFields.breedName
                                    .valueType,
                            )}
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer className="border-top-0">
                    <Button
                        variant="secondary"
                        className="secondary"
                        onClick={() => closeModalHandler(false, '')}
                    >
                        {CommonConstants.en.cancel}
                    </Button>
                    <Button variant="secondary" onClick={handleCreateBreed}>
                        {!editBreedDetails
                            ? CommonConstants.en.create
                            : CommonConstants.en.update}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateBreedModal;
