export const ProvincesTerritoryList = [
    {
        name: 'Select ProvincesTerritory',
        value: '',
        abbreviation: '',
    },
    {
        name: 'Alberta',
        value: 'AB',
        abbreviation: 'AB',
    },
    {
        name: 'British Columbia',
        value: 'BC',
        abbreviation: 'BC',
    },
    {
        name: 'Manitoba',
        value: 'MB',
        abbreviation: 'MB',
    },
    {
        name: 'New Brunswick',
        value: 'NB',
        abbreviation: 'NB',
    },
    {
        name: 'Newfoundland and Labrador',
        value: 'NL',
        abbreviation: 'NL',
    },
    {
        name: 'Northwest Territories',
        value: 'NT',
        abbreviation: 'NT',
    },
    {
        name: 'Nova Scotia',
        value: 'NS',
        abbreviation: 'NS',
    },
    {
        name: 'Nunavut',
        value: 'NU',
        abbreviation: 'NU',
    },
    {
        name: 'Ontario',
        value: 'ON',
        abbreviation: 'ON',
    },
    {
        name: 'Prince Edward Island',
        value: 'PE',
        abbreviation: 'PE',
    },
    {
        name: 'Quebec',
        value: 'QC',
        abbreviation: 'QC',
    },
    {
        name: 'Saskatchewan',
        value: 'SK',
        abbreviation: 'SK',
    },
    {
        name: 'Yukon',
        value: 'YT',
        abbreviation: 'YT',
    },
];
