import {
    IProcessingProtocolFilter,
    IWorksheetProcessing,
} from '../../utils/Interface/HealthSection/MedicalItemInterface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMedicalItem } from '../../utils/Interface/HealthSection/MedicalItemInterface';
import { IProcessingProtocol } from '../../utils/Interface/ProcessingInterface';
import { PROCESSING_PROTOCOL_INITIAL_STATE } from '../Constants/processingProtocolInitialState';

const processProtocolSlice = createSlice({
    name: 'processingProtocol',
    initialState: PROCESSING_PROTOCOL_INITIAL_STATE,
    reducers: {
        setMedicalItems: (state, action: PayloadAction<IMedicalItem[]>) => {
            state.processingProtocolMedicalItems = action.payload;
        },
        setProcessingProtocols: (
            state,
            action: PayloadAction<IProcessingProtocol[]>,
        ) => {
            state.processingProtocols = action.payload;
        },
        appendProcessingProtocols: (
            state,
            action: PayloadAction<IProcessingProtocol[]>,
        ) => {
            state.processingProtocols = [
                ...state.processingProtocols,
                ...action.payload,
            ];
        },
        setWorksheetProcessing: (
            state,
            action: PayloadAction<IWorksheetProcessing>,
        ) => {
            state.worksheetProcessing = action.payload;
        },
        setGeneratedInvoiceCutoffDate: (
            state,
            action: PayloadAction<string>,
        ) => {
            state.generatedInvoiceCutoffDate = action.payload;
        },
        setFilter: (
            state,
            action: PayloadAction<Partial<IProcessingProtocolFilter>>,
        ) => {
            state.processingFilter = {
                ...state.processingFilter,
                ...action.payload,
            };
        },
        setInsufficientInventory: (state, action: PayloadAction<number>) => {
            if (!state.insufficientInventory.includes(action.payload)) {
                state.insufficientInventory = [
                    ...state.insufficientInventory,
                    action.payload,
                ];
            }
        },
        removeInsufficientInventory: (state, action: PayloadAction<number>) => {
            const indexToRemove = action.payload;
            state.insufficientInventory = state.insufficientInventory
                .map(index => (index > indexToRemove ? index - 1 : index))
                .filter(index => index !== indexToRemove);
        },
        clearInsufficientInventory: state => {
            state.insufficientInventory = [];
        },
        setSubmitPending: (state, action: PayloadAction<boolean>) => {
            state.processingProtocolSubmitPending = action.payload;
        },
        resetState: () => {
            return PROCESSING_PROTOCOL_INITIAL_STATE;
        },
    },
});

export const processingProtocolActions = processProtocolSlice.actions;
export const processingProtocolReducer = processProtocolSlice.reducer;
