import { ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Store';
import {
    fetchSettingVisibility,
    updateSettingVisibility,
} from '../../../../Redux/Actions/tenantSettingsAction';
import { useEffect } from 'react';

export const KillGroupCheckbox = () => {
    const { SettingVisibility } = useAppSelector(state => state.tenantSetting);

    const dispatch = useAppDispatch();

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { id, settingName, displayName } = SettingVisibility.KillGroup;
        const isActive = event.target.checked;
        const payload = { id, settingName, displayName, isActive };
        dispatch(updateSettingVisibility(payload, 'KillGroup'));
    };
    useEffect(() => {
        dispatch(fetchSettingVisibility('KillGroup'));
    }, []);

    return (
        <div className="d-flex align-items-center mt-3">
            <input
                type="checkbox"
                checked={SettingVisibility.KillGroup?.isActive}
                onChange={handleCheckboxChange}
                className="mx-3 custom-checkbox"
            />
            <label>{SettingVisibility.KillGroup?.displayName || ''}</label>
        </div>
    );
};
