import { useCallback, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import useAuthContext from '../../apps/common/modules/auth/hooks/useAuthContext';
import { apiCall } from '../../Services/AxiosService';
import StorageService from '../../Services/storage/storage.service';
import Constants from '../../utils/Constants';
import { INavFeedlot } from '../../utils/Interface/NavInterface';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { setSelectedFeedlot } from '../../Redux/Reducer/common';
import { FeedlotManagerModelsResponseModelsFeedlotApiResponse } from '../../Redux/Apis/FMFeedlot/baseFMFeedlotApi';

const SELECTED_FEEDLOT = 'selectedFeedlot';

export default function FeedlotDropdown() {
    const dispatch = useAppDispatch();
    const [usersFeedlots, setUsersFeedlots] = useState<
        Required<FeedlotManagerModelsResponseModelsFeedlotApiResponse>[]
    >([]);
    const { selectedFeedlot } = useAppSelector(state => state.common);
    const [storage] = useState(new StorageService());
    const { getUsersFeedlots } = useAuthContext();

    const updateSelectedFeedlot = useCallback(
        (
            feedlotDetail: Required<FeedlotManagerModelsResponseModelsFeedlotApiResponse>,
        ) => {
            dispatch(setSelectedFeedlot(feedlotDetail));
            storage.setValue(
                SELECTED_FEEDLOT,
                feedlotDetail,
                Constants.Storage.LOCAL,
            );
        },
        [dispatch, storage],
    );

    useEffect(() => {
        const updateFeedlotDropdownAndSelection = async () => {
            const allFeedlots: Required<FeedlotManagerModelsResponseModelsFeedlotApiResponse>[] =
                await apiCall(
                    'get',
                    `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.FM_FEEDLOT}`,
                );

            const usersFeedlots = getUsersFeedlots(allFeedlots).sort((a, b) => {
                if (a.label && b.label) {
                    return a.label > b.label ? 1 : -1;
                } else {
                    return 0;
                }
            });
            setUsersFeedlots(usersFeedlots);

            const selectedFeedlotFromStorage: INavFeedlot =
                storage.getValue(SELECTED_FEEDLOT);
            const matchingFeedlot = usersFeedlots.find(
                usersFeedlot =>
                    usersFeedlot.id === selectedFeedlotFromStorage?.id,
            );
            updateSelectedFeedlot(matchingFeedlot ?? usersFeedlots?.[0] ?? {});
        };
        updateFeedlotDropdownAndSelection();
    }, [getUsersFeedlots, storage, updateSelectedFeedlot]);

    return (
        <div className="mx-3">
            <Dropdown className="top-header-dropdown">
                {usersFeedlots.length > 1 ? (
                    <>
                        <Dropdown.Toggle className="p-0 d-flex align-items-center">
                            {selectedFeedlot?.labelColorHex && (
                                <div
                                    className="colored-circle mb-1 me-2"
                                    style={{
                                        backgroundColor:
                                            '#' +
                                            selectedFeedlot?.labelColorHex,
                                    }}
                                ></div>
                            )}
                            {selectedFeedlot.label || ''}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {usersFeedlots.map((feedlot, index) => (
                                <Dropdown.Item
                                    key={feedlot.id}
                                    onClick={() =>
                                        updateSelectedFeedlot(feedlot)
                                    }
                                    className="p-0 px-1 d-flex align-items-center"
                                >
                                    {feedlot.labelColorHex ? (
                                        <div
                                            className="colored-circle mb-1 me-2"
                                            style={{
                                                backgroundColor:
                                                    '#' + feedlot.labelColorHex,
                                            }}
                                        ></div>
                                    ) : (
                                        <div className="ms-4"></div>
                                    )}
                                    {feedlot.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </>
                ) : (
                    <Dropdown.Toggle
                        className="cursor-default p-0 d-flex align-items-center"
                        bsPrefix="p-0"
                    >
                        {selectedFeedlot?.labelColorHex && (
                            <div
                                className="colored-circle mb-1 me-1"
                                style={{
                                    backgroundColor:
                                        '#' + selectedFeedlot?.labelColorHex,
                                }}
                            ></div>
                        )}
                        {selectedFeedlot.label || ''}
                    </Dropdown.Toggle>
                )}
            </Dropdown>
        </div>
    );
}
