import {
    useState,
    useEffect,
    useRef,
    ChangeEvent,
    FC,
    useCallback,
} from 'react';
import {
    useHandleArrowDown,
    useHandleArrowUp,
    useHandleEnter,
} from './components/keyHandlers';
import {
    handleClearInput,
    useResetOnSelectedChange,
} from './components/handleSelectChangeFunctions';
import { toggleDropdown } from './components/dropdownFunctions';
import useUpdateOnSelected from './components/useUpdateOnSelected';
import useScrollOnHighlight from './components/useScrollOnHighlight';
import DropdownInput from './components/DropdownInput';
import useOutsideClick from './components/useOutsideClick';
import DropdownOptions from './components/DropdownOptions';
export interface ICustomInputProps {
    options: object[];
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
    shouldMatchOption?: boolean;
    placeholder?: string;
    disabled?: boolean;
    styleError?: boolean | string | number;
    selected?: string | number;
    inputName?: string;
    nameKey: string;
    valueKey: string;
    staticOptions?: object[];
    tooltipPlacement?: 'auto' | 'top' | 'bottom' | 'left' | 'right';
    tooltipTitle?: string;
    size?: 'small' | undefined;
    isRequired?: boolean;
}

const AutocompleteDropdown: FC<ICustomInputProps> = ({
    options,
    onChange,
    onBlur,
    shouldMatchOption = true,
    disabled,
    styleError,
    selected,
    placeholder,
    inputName,
    nameKey,
    valueKey,
    staticOptions,
    tooltipPlacement,
    tooltipTitle,
    size,
    isRequired,
}) => {
    const [inputValue, setInputValue] = useState(
        selected?.toString() ??
            (options.length === 1 ? options[0][nameKey] : ''),
    );
    const [showDropdown, setShowDropdown] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [filterValue, setFilterValue] = useState('');
    const inputRef = useRef<HTMLDivElement>(null);
    const optionRefs = useRef<(HTMLLIElement | null)[]>([]);
    const [openUpwards, setOpenUpwards] = useState(false);
    const [isInputCleared, setIsInputCleared] = useState(false);
    const [isInputChanged, setIsInputChanged] = useState(false);
    const blurTriggered = useRef(false);

    useUpdateOnSelected(selected, options, setInputValue, nameKey, valueKey);
    useScrollOnHighlight(highlightedIndex, optionRefs);
    useOutsideClick(inputRef, () => setShowDropdown(false));

    const handleOptionClick = useCallback(
        (option: object) => {
            if (blurTriggered.current) return;
            blurTriggered.current = true;
            if (
                option &&
                Object.hasOwn(option, nameKey) &&
                option[nameKey] !== inputValue
            ) {
                setInputValue(option[nameKey]);
                setShowDropdown(false);
                const event = new Event('input');
                const inputElement = inputRef.current?.querySelector('input');
                if (inputElement) {
                    inputElement.value = option[valueKey]
                        ? option[valueKey].toString()
                        : '';
                    inputElement.dispatchEvent(event);
                    onChange(event as unknown as ChangeEvent<HTMLInputElement>);
                    setTimeout(() => {
                        inputElement.focus();
                        setShowDropdown(false);
                    }, 0);
                }
            }
            setTimeout(() => {
                blurTriggered.current = false;
            }, 0);
        },
        [onChange, nameKey, valueKey],
    );

    const handleClearInputCallback = handleClearInput(
        handleOptionClick,
        disabled,
    );

    useResetOnSelectedChange({
        options,
        nameKey,
        valueKey,
        selected,
        isInputChanged,
        isInputCleared,
        setInputValue,
        setIsInputCleared,
        handleClearInputCallback,
    });

    const onChangeCallback = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const inputValue = event.target.value;
            setInputValue(inputValue);
            setFilterValue(inputValue);
            setShowDropdown(true);
            setIsInputChanged(true);

            if (inputValue === '') {
                handleClearInputCallback(
                    setInputValue,
                    setIsInputCleared,
                    nameKey,
                    valueKey,
                );
            }
        },
        [
            setInputValue,
            setShowDropdown,
            nameKey,
            valueKey,
            handleClearInputCallback,
        ],
    );

    useEffect(() => {
        if (showDropdown && inputRef.current) {
            const rect = inputRef.current.getBoundingClientRect();
            const spaceTop = rect.top;
            const spaceBottom = window.innerHeight - rect.bottom;
            setOpenUpwards(spaceBottom < 400 && spaceTop > spaceBottom);
        }
    }, [showDropdown]);

    const toggleDropdownCallback = toggleDropdown(setShowDropdown, disabled);

    const defaultHandleBlurCallback = useCallback(() => {
        if (blurTriggered.current) return;
        const matchedOption = options.find(
            option => option[nameKey]?.toString() === inputValue,
        );
        if (matchedOption) {
            handleOptionClick(matchedOption);
            setFilterValue('');
        } else {
            const selectedOption = options.find(
                option => option[valueKey]?.toString() === selected?.toString(),
            );
            if (selectedOption && Object.hasOwn(selectedOption, nameKey)) {
                setInputValue(selectedOption[nameKey]);
                setFilterValue('');
            } else {
                setInputValue('');
                setFilterValue('');
            }
        }
    }, [options, inputValue, handleOptionClick, nameKey, valueKey, selected]);

    const handleOptionClickRef = useRef(handleOptionClick);
    handleOptionClickRef.current = handleOptionClick;

    const filterOptions = () => {
        const searchText = filterValue.toLowerCase();
        const startsWithFilter = options.filter(option =>
            option[nameKey]?.toString().toLowerCase().startsWith(searchText),
        );
        const containsFilter = options.filter(
            option =>
                !option[nameKey]
                    ?.toString()
                    .toLowerCase()
                    .startsWith(searchText) &&
                option[nameKey]?.toString().toLowerCase().includes(searchText),
        );

        startsWithFilter.sort((a, b) =>
            a[nameKey].toString().localeCompare(b[nameKey].toString()),
        );
        containsFilter.sort((a, b) =>
            a[nameKey].toString().localeCompare(b[nameKey].toString()),
        );

        const filtered = [...startsWithFilter, ...containsFilter];

        if (staticOptions) {
            filtered.unshift(...staticOptions);
        }

        return filtered;
    };

    const allOptions = () => {
        return staticOptions ? [...staticOptions, ...options] : options;
    };

    const filteredOptions = filterValue === '' ? allOptions() : filterOptions();

    useEffect(() => {
        const selectedOption = options?.find(
            option => option[valueKey]?.toString() === selected?.toString(),
        );

        if (!isInputChanged) {
            if (
                selectedOption &&
                selectedOption[nameKey].toLowerCase().includes('create')
            ) {
                handleClearInputCallback(
                    setInputValue,
                    setIsInputCleared,
                    nameKey,
                    valueKey,
                );
            } else {
                setInputValue(selectedOption ? selectedOption[nameKey] : '');
            }
        }
    }, [
        handleClearInputCallback,
        options,
        nameKey,
        valueKey,
        isInputChanged,
        selected,
    ]);

    const handleArrowDownEvent = useHandleArrowDown(
        setShowDropdown,
        setHighlightedIndex,
        filteredOptions,
        showDropdown,
    );
    const handleArrowUpEvent = useHandleArrowUp(setHighlightedIndex);

    const handleEnterEvent = useHandleEnter(
        handleOptionClick,
        filteredOptions,
        highlightedIndex,
    );

    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            const dropdown = inputRef.current?.querySelector(
                '.custom-autocomplete-dropdown',
            ) as HTMLElement;

            switch (event.key) {
                case 'ArrowDown':
                    event.preventDefault();
                    handleArrowDownEvent(dropdown);
                    break;
                case 'ArrowUp':
                    event.preventDefault();
                    handleArrowUpEvent(dropdown);
                    break;
                case 'Enter':
                    event.preventDefault();
                    handleEnterEvent();
                    break;
                case 'Tab':
                    shouldMatchOption
                        ? handleEnterEvent()
                        : setShowDropdown(false);
                    break;
            }
        },
        [handleArrowDownEvent, handleArrowUpEvent, handleEnterEvent],
    );

    useEffect(() => {
        const inputElement = inputRef.current?.querySelector('input');

        if (inputElement) {
            inputElement.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            if (inputElement) {
                inputElement.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if (showDropdown) {
            setHighlightedIndex(0);
        }
    }, [showDropdown]);

    const closeDropdown = () => {
        setShowDropdown(false);
    };

    useEffect(() => {
        if (showDropdown) {
            const selectedOptionIndex = options.findIndex(
                option => option[valueKey]?.toString() === selected?.toString(),
            );
            setHighlightedIndex(
                selectedOptionIndex !== -1 ? selectedOptionIndex : 0,
            );
            if (optionRefs.current[selectedOptionIndex]) {
                optionRefs.current[selectedOptionIndex]?.scrollIntoView({
                    block: 'nearest',
                });
            }
        } else {
            setHighlightedIndex(-1);
        }
    }, [showDropdown, options, selected, valueKey]);

    return (
        <div className="position-relative select-w-100" ref={inputRef}>
            <DropdownInput
                handleBlur={onBlur ?? defaultHandleBlurCallback}
                handleInputChange={onChangeCallback}
                handleToggleDropdown={toggleDropdownCallback}
                value={inputValue}
                disabled={disabled}
                inputName={inputName}
                placeholder={placeholder}
                styleError={styleError}
                tooltipPlacement={tooltipPlacement}
                tooltipTitle={tooltipTitle}
                size={size}
                isRequired={isRequired}
            />
            {showDropdown && (
                <DropdownOptions
                    options={filteredOptions}
                    highlightedIndex={highlightedIndex}
                    handleOptionClick={handleOptionClick}
                    nameKey={nameKey}
                    openUpwards={openUpwards}
                    setHighlightedIndex={setHighlightedIndex}
                    closeDropdown={closeDropdown}
                />
            )}
        </div>
    );
};

export default AutocompleteDropdown;
