import { ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Store';
import { useEffect } from 'react';
import {
    fetchSettingVisibility,
    updateSettingVisibility,
} from '../../../../Redux/Actions/tenantSettingsAction';

export const BreedVisibilityCheckbox = () => {
    const { SettingVisibility } = useAppSelector(state => state.tenantSetting);
    const dispatch = useAppDispatch();

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { id, settingName, displayName } = SettingVisibility.Breed;
        const isActive = event.target.checked;
        const payload = { id, settingName, displayName, isActive };
        dispatch(updateSettingVisibility(payload, 'Breed'));
    };

    useEffect(() => {
        dispatch(fetchSettingVisibility('Breed'));
    }, []);

    return (
        <div className="d-flex align-items-center mt-3">
            <input
                type="checkbox"
                checked={SettingVisibility.Breed?.isActive}
                onChange={handleCheckboxChange}
                className="mx-3 custom-checkbox"
            />
            <label>{SettingVisibility.Breed?.displayName || ''}</label>
        </div>
    );
};
