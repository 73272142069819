import { AnyAction } from '@reduxjs/toolkit';
import { ActionTypes } from '../Actions';
import { FEED_CALL_INITIAL_STATE } from '../Constants/feedCallInitialState';

const FeedCallReducer = (
    state = FEED_CALL_INITIAL_STATE,
    action: AnyAction,
) => {
    const stateCopy = { ...state };

    switch (action.type) {
        case ActionTypes.FETCH_FEED_CALL_PENS:
            stateCopy.feedCallPensList = action.payload;
            return stateCopy;
        case ActionTypes.FETCH_FEED_CALL_DETAILS:
            stateCopy.feedCallInfo = action.payload;
            return stateCopy;
        case ActionTypes.ORIGINAL_FEED_CALL_ASSESSMENT:
            stateCopy.originalFeedCallAssessment = action.payload;
            return stateCopy;
        case ActionTypes.ORIGINAL_FEED_CALL_ASSESSMENT_PAYLOAD:
            stateCopy.originalFeedCallAssessmentPayload = action.payload;
            return stateCopy;
        case ActionTypes.FETCH_FEED_CALL_RATION_SCHEDULE:
            stateCopy.feedCallPenRationSchedule = action.payload;
            return stateCopy;
        case ActionTypes.FEED_CALL_SELECT_PEN_ID:
            stateCopy.feedCallSelectedPenId = action.payload;
            return stateCopy;
        case ActionTypes.SET_FEED_CALL_CURRENT_VALUE:
            stateCopy.bunkScoreCurrentValue = action.payload;
            return stateCopy;
        case ActionTypes.SET_SELECTED_SCHEDULE_TEMPLATE:
            stateCopy.selectedTemplateId = action.payload;
            return stateCopy;
        case ActionTypes.SET_CURRENT_PEN_INDEX:
            stateCopy.feedCallCurrentPenIndex = action.payload;
            return stateCopy;
        case ActionTypes.SET_PEN_RATION_SCHEDULE_ID:
            stateCopy.feedCallPenRationScheduleId = action.payload;
            return stateCopy;
        case ActionTypes.FETCH_FEED_CALL_BUNK_SCORE_LIST:
            stateCopy.bunkScoreList = action.payload;
            return stateCopy;
        case ActionTypes.FETCH_FEED_CALL_SHIPPING_LIST:
            stateCopy.feedCallShippingList = action.payload;
            return stateCopy;
        case ActionTypes.SET_FEED_CALL_TABLE:
            stateCopy.feedCallTableList = action.payload;
            return stateCopy;
        case ActionTypes.SET_IS_ALL_FEED_CALL_LOADED:
            stateCopy.isFeedCallTableLoaded = action.payload;
            return stateCopy;
        case ActionTypes.SET_FEED_CALL_FILTER:
            stateCopy.filter = action.payload;
            return stateCopy;
        case ActionTypes.SET_FEED_CALL_LOADING:
            stateCopy.loading = action.payload;
            return stateCopy;
        case ActionTypes.SET_FEED_CALL_CAN_CALL_API:
            stateCopy.canCallApi = action.payload;
            return stateCopy;
        case ActionTypes.FEED_CALL_SELECT_PEN_HD_COUNT:
            stateCopy.feedCallSelectedPenHdCount = action.payload;
            return stateCopy;
        case ActionTypes.FEED_CALL_SELECT_EST_HD_COUNT:
            stateCopy.feedCallEstimatedHeadCount = action.payload;
            return stateCopy;
        case ActionTypes.SET_FEED_CALL_TOTAL_CALL_ESTIMATED_HD_FIELD_NAME:
            stateCopy.totalCallEstimatedHdCountFieldName = action.payload;
            return stateCopy;
        case ActionTypes.SET_BUNK_SCORE_API_RESPONSE:
            stateCopy.bunkScoreApiResponse = action.payload;
            return stateCopy;
        case ActionTypes.FEED_CALL_EST_HEAD_COUNT:
            stateCopy.estimatedHdCount = action.payload;
            return stateCopy;
        case ActionTypes.FEED_CALL_TOTAL_CALL:
            stateCopy.feedCallTotalCall = action.payload;
            return stateCopy;
        case ActionTypes.SET_FEED_CALL_GRAPH_LIMIT:
            stateCopy.feedCallGraphDays = action.payload;
            return stateCopy;
        case ActionTypes.FETCH_FEED_CALL_GRAPH:
            stateCopy.feedCallGraph = action.payload;
            return stateCopy;
        case ActionTypes.FETCH_FEED_CALL_BUNK_SCORE_DETAILS: {
            const feedCall = {
                ...stateCopy.feedCallInfo.feedCall,
                ...action.payload,
            };
            stateCopy.feedCallInfo.feedCall = feedCall;
            return stateCopy;
        }
        case ActionTypes.SET_FEED_CALL_NO_OF_SHIPMENT:
            stateCopy.feedCallNoOfFeedingBeforeShipment = action.payload;
            return stateCopy;
        case ActionTypes.SET_EXISTING_ESTIMATED_HEAD_COUNT:
            stateCopy.existingEstHeadCountModalLotDetails = action.payload;
            return stateCopy;
        case ActionTypes.SET_ESTIMATED_HEAD_COUNT_LOTS:
            stateCopy.lots = action.payload;
            return stateCopy;
        case ActionTypes.SET_ESTIMATED_HEAD_COUNT_MODAL_LOTS_CACHE:
            stateCopy.estHeadCountModalLotDetailsCache = action.payload;
            return stateCopy;
        case ActionTypes.SET_ESTIMATED_HEAD_COUNT_MODAL_LOTS:
            stateCopy.estHeadCountModalLotDetails = action.payload;
            return stateCopy;
        case ActionTypes.RESET_EST_HEAD_COUNT:
            return {
                ...state,
                ...action.payload,
            };
        case ActionTypes.SET_IS_ESTIMATED_HEAD_COUNT_FIELD:
            stateCopy.isEstimatedHdCountFieldName = action.payload;
            return stateCopy;
        case ActionTypes.SET_EXISTING_ESTIMATED_HEAD_COUNT_CACHE:
            stateCopy.existingEstHeadCountModalLotDetailsCache = action.payload;
            return stateCopy;
        case ActionTypes.SET_CAN_SHOW_RATION_CONFIRMATION_MODAL:
            stateCopy.canShowRationConfirmationModal = action.payload;
            return stateCopy;
        case ActionTypes.SET_CAN_SHOW_RATION_CHANGE_REMINDER_MODAL:
            stateCopy.canShowRationChangeReminderModal = action.payload;
            return stateCopy;
        case ActionTypes.SET_FEEDCALL_ASSESSMENT_LOADER:
            stateCopy.feedCallAssessmentLoader = action.payload;
            return stateCopy;
        case ActionTypes.SET_SAVE_PREV_NEXT_BTN_DISABLED:
            stateCopy.savePrevNextBtnDisabled = action.payload;
            return stateCopy;
        case ActionTypes.SET_FEED_CALL_RATIONS:
            stateCopy.rations = action.payload;
            return stateCopy;
        case ActionTypes.SET_IS_RATION_FILTER_CHANGED:
            stateCopy.isRationFilterChanged = action.payload;
            return stateCopy;
        case ActionTypes.SET_IS_SCHEDULED_RATION_CHANGED:
            stateCopy.isScheduledRationChanged = action.payload;
            return stateCopy;
        case ActionTypes.SET_CAN_SHOW_AF_CHANGE_CONFIRMATION_MODAL:
            stateCopy.canShowAfChangeConfirmationModal = action.payload;
            return stateCopy;
        case ActionTypes.SET_IS_FEED_CALL_CREATED:
            stateCopy.isFeedCallCreated = action.payload;
            return stateCopy;
        case ActionTypes.SET_IS_SAVE_AND_NEXT_BTN_CLICKED:
            stateCopy.isSaveAndNextBtnClicked = action.payload;
            return stateCopy;
        case ActionTypes.SET_IS_EST_HD_COUNT_AVAILABLE:
            stateCopy.isEstHdCountAvailable = action.payload;
            return stateCopy;
        case ActionTypes.SET_SHOW_PEN_RATION_TEMPLATE_MODAL:
            stateCopy.showPenRationTemplateModal = action.payload;
            return stateCopy;
        case ActionTypes.SET_PEN_RATION_TEMPLATE_MODAL_CLOSED:
            stateCopy.isPenRationTemplatedModalClosed = action.payload;
            return stateCopy;
        case ActionTypes.SET_OLD_FEED_CALL_TOTAL_CALL:
            stateCopy.oldFeedCallTotalCall = action.payload;
            return stateCopy;
        case ActionTypes.SET_OLD_DM_CHANGE_PER_HD_VALUE:
            stateCopy.oldDmChangePerHdValue = action.payload;
            return stateCopy;
        case ActionTypes.SET_IS_SHOW_FULL_PAGE_LOADER:
            stateCopy.showFullPageLoader = action.payload;
            return stateCopy;
        case ActionTypes.SET_FEED_CALL_LOT_INFORMATION:
            stateCopy.feedCallLotInformation = action.payload;
            return stateCopy;
        case ActionTypes.SET_FEED_CALL_RATION_INFORMATION:
            stateCopy.feedCallRationInformation = action.payload;
            return stateCopy;
        case ActionTypes.SET_IS_DISABLED_FIELDS_BUTTONS:
            stateCopy.isButtonsAndFieldsDisabled = action.payload;
            return stateCopy;
        case ActionTypes.SET_IS_PEN_RATION_SCHEDULE_LOADING:
            stateCopy.isPenRationScheduleLoading = action.payload;
            return stateCopy;
        default:
            return stateCopy;
    }
};

export default FeedCallReducer;
