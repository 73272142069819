import StorageService from '../../../../../../Services/storage/storage.service';
import Constants from '../../../../../../utils/Constants';
import { FeedCallConstants } from '../../../../../../utils/constants/FeedCallConstants';
import { IFeedCallPageSliceInitialState } from './interfaces';

export const feedCallPageSliceInitialState: IFeedCallPageSliceInitialState = {
    selectedPenId: undefined,
    isFirstPageLoad: true,
    showRationChangeReminderModal: false,
    isRationChangeReminderModalConfirmedForPen: false,
    showAFPerHeadLimitModal: false,
    isAFPerHeadLimitModalConfirmedForPen: false,
    localAssessmentValues: {
        afChangePerHd: 0,
        dmChangePerHd: 0,
        totalCall: 0,
    },
    feedCallPayload: {
        feedCallId: 0,
        penId: 0,
        hdCount: 0,
        afChangePerHd: 0,
        dmChangePerHd: 0,
        bunkScore: 0,
        totalCall: 0,
        afPerHd: 0,
        dmPerHd: 0,
        wtPerDM: 0,
        adg: 0,
        estimatedHdCount: null,
        hayFeeding1: null,
        hayFeeding2: null,
        comments: null,
        noOfFeedingsBeforeShipment: null,
        estimatedHeadTracker: undefined,
        daysOnFeed: 0,
        daysOnCurrentRation: 0,
        penRationScheduleId: 0,
        startRationName: '',
        startRationId: 0,
        startRationPercentage: 0,
        endRationName: '',
        endRationId: 0,
        endRationPercentage: 0,
        createdDateTime: '',
        dayOnFeedCall: 0,
        rationName: '',
        isScheduledRationChangeValid: false,
        rationSortOrder: 0,
        pen: undefined,
        variancePercentage: 0,
        penRationSchedule: undefined,
        fedToday: 0,
        endRation: undefined,
        startRation: undefined,
    },
    lastAssessment: {
        penId: 0,
        hdCount: 0,
        estimatedHdCount: null,
        afChangePerHd: 0,
        dmChangePerHd: 0,
        totalCall: 0,
        afPerHd: 0,
        wtPerDM: 0,
        adg: 0,
        dmPerHd: 0,
        noOfFeedingsBeforeShipment: null,
        estimatedHeadTracker: undefined,
    },
    daysOfHistoricalTableData: 'days14',
    daysOfHistoricalGraphData: 'days14',
    penFilterByRation:
        new StorageService().getValue(
            FeedCallConstants.feedCallLocalStorage.rationId,
            Constants.Storage.LOCAL,
        ) ?? 'all',
    showPenRationScheduleModal: false,
    extendRationSchedule: false,
    assessmentProcessing: false,
    shouldCallSaveAndNextPen: false,
};
