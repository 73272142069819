import { getFeatureFlagValue } from '../../common/featureFlags/helpers';
import { IFeedlotManagerFeatures } from './interface';

export const feedlotManagerFeatures: IFeedlotManagerFeatures = {
    isCA1234On: getFeatureFlagValue(process.env.REACT_APP_FEATURE_CA1234),
    isCA4879On_CloseALot: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA4879_CLOSE_A_LOT,
    ),
    isCA9919On_ManageUsersPage: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9919_MANAGE_USERS_PAGE,
    ),
    isCA7454On_ReceiveCattleExportModal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA7454_RECEIVE_CATTLE_EXPORT_MODAL,
    ),
    isCA10241On_OrderTreatmentHistoryTable: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10241_ORDER_TREATMENT_HISTORY_TABLE,
    ),
    isCA6632On_ShipCattleReportsModal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA6632_SHIP_CATTLE_REPORTS_MODAL,
    ),
    isCA10953On_ProcessingInInvoicePeriod: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10953_PROCESSING_IN_INVOICE_PERIOD,
    ),
    isCA9148On_GetInactiveItems: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9148_TREATMENT_GET_INACTIVE_ITEMS,
    ),
    isCA7794On_TreatmentHistoricalGiveRecords: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA7794_TREATMENT_HISTORICAL_GIVE_RECORDS,
    ),
    isCA9886On_CommoditiesNaNFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9886_COMMODITIES_NAN_FIX,
    ),
    isCA8202On_ScaleTicket_Sort: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8202_SCALE_TICKET_SORT,
    ),
    isCA10449On_ProcessingRemoveInventoryRestriction: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10449_PROCESSING_REMOVE_INVENTORY_RESTRICTION,
    ),
    isCA9656On_AllowZeroProcessingDose: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9656_ALLOW_ZERO_PROCESSING_DOSE,
    ),
    isCA8333On_PenList: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8333_SETTING_PEN_LIST,
    ),
    isCA8444_FeedStatusRationFilter: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA8444_FEED_STATUS_RATION_FILTER_INCORRECT_VALUE,
    ),
    isCA10695On_NamingTabs: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10695_NAMING_TABS,
    ),
    isCA10397On_MortalityTreatmentHistoryModal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10397_MORTALITY_TREATMENT_HISTORY_MODAL,
    ),
    isCA10156On_GeneratingInvoicesModal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10156_GENERATING_INVOICES_MODAL,
    ),
    isCA10624On_FinalInvoiceDuplicateProtection: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10624_FINAL_INVOICE_DUPLICATE_PROTECTION,
    ),
    isCA10655_AssignFeedChargeDropdownFilter: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10655_ASSIGN_FEED_CHARGE_DROPDOWN_FILTER,
    ),
    isCA7788On_RefundCancellationPolicy: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA7788_REFUND_CANCELLATION_POLICY,
    ),
    isCA9511On_CommoditiesDateFilterDebounce: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9511_COMMODITIES_DATE_FILTER_DEBOUNCE,
    ),
    isCA10477On_RealizerCompleteWithZeroRevenue: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10477_REALIZER_COMPLETE_WITH_ZERO_REVENUE,
    ),
    isCA10601On_CommodityAdjustmentFormOnlyActive: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10601_COMMODITY_ADJUSTMENT_FORM_ONLY_ACTIVE,
    ),
    isCA10679On_MortalityDetailsExport: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10679_MORTALITY_DETAILS_EXPORT,
    ),
    isCA10155On_ScaleTicketDownloadFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10155_SCALE_TICKET_DOWNLOAD_FIX,
    ),
    isCA7052On_HdHistoryList: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA7052_HD_HISTORY_LIST,
    ),
    isCA10509On_ProcessingFilterClosedLots: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA1509_PROCESSING_FILTER_CLOSED_LOTS,
    ),
    isCA10896On_ScaleTicketDataSize: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10896_SCALE_TICKET_DATA_SIZE,
    ),
    isCA10154On_PreventDoubleSaving: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10154_PREVENT_DOUBLE_SAVING,
    ),
    isCA6962On_LotTreatmentHistory: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA6962_LOT_TREATMENT_HISTORY,
    ),
    isCA10275On_TreatmentReportCustomDateFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10275_TREATMENT_REPORT_CUSTOM_DATE_FIX,
    ),
    isCA10204On_PreventDoubleSavingReceiveCattleFooter: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10204_PREVENT_DOUBLE_SAVING_RECEIVE_CATTLE_FOOTER,
    ),
    isCA9800On_ContractsMVP: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9800_CONTRACTS_MVP,
    ),
    isCA10418On_EnhanceScaleTicketsExports: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10418_ENHANCE_SCALE_TICKETS_EXPORTS,
    ),
    isCA10369On_ScrollBarSupport: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10369_SCROLL_BAR_SUPPORT,
    ),
    isCA10517On_ClosingLotFeesEnable: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10517_CLOSING_LOT_FEES_ENABLE,
    ),
    isCA10816On_PenSpecificDisableMFE: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10816_PEN_SPECIFIC_DISABLE_MFE,
    ),
    isCA9990On_RationValidationPrecision: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9990_RATION_VALIDATION_PRECISION,
    ),
    isCA10264On_RealizerDeleteRowBug: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10264_REALIZER_DELETE_ROW_BUG,
    ),
    isCA8557On_CreateNewRiderOptionForTreatment: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA8557_CREATE_NEW_RIDER_OPTION_FOR_TREATMENT,
    ),
    isCA9973On_RationScheduleDeleteBug: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9973_RATION_SCHEDULE_DELETE_BUG,
    ),
    isCA9488On_MortalityODataValidCheck: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9488_MORTALITY_ODATA_VALID_CHECK,
    ),
    isCA8496On_RealizerFormMultipleApiCalls: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8496_REALIZER_FORM_MULTIPLE_API_CALLS,
    ),
    isCA10316On_TreatmentAnimalTextInput: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10316_TREATMENT_ANIMAL_TEXT_INPUT,
    ),
    isCA8154On_GeneratePriorInvoiceWithFinal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8154_GENERATE_PRIOR_INVOICE_WITH_FINAL,
    ),
    isCA10390On_TreatmentsForDeadAnimals: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10390_TREATMENTS_FOR_DEAD_ANIMALS,
    ),
    isCA8191On_DatabricksRowUpdate: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8191_DATABRICKS_ROW_UPDATE,
    ),
    isCA9601On_DoNotProcessLoop: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9601_DO_NOT_PROCESS_LOOP,
    ),
    isCA9612On_ScrollHandlerScreenSize: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9612_SCROLL_HANDLER_SCREEN_SIZE,
    ),
    isCA10957On_CommodityHistoryReport: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10957_COMMODITY_HISTORY_REPORT,
    ),
    is8642On_MakeTreatmentProtocolsEditable: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8642_MAKE_TREATMENT_PROTOCOLS_EDITABLE,
    ),
    is8643On_MakeProcessingProtocolsEditable: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8643_MAKE_PROCESSING_PROTOCOLS_EDITABLE,
    ),
    isCA8644On_RenameRationFields: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8644_RENAME_RATION_FIELDS,
    ),
    isCA8348On_PenHistoryModal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8348_ENABLE_PEN_HISTORY_MODAL,
    ),
    isCA10247On_ChangeDoseToDosage: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10247_CHANGE_DOSE_TO_DOSAGE,
    ),
    isCA8683On_AnimalIdAutocompleteDropdownFix: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA8683_ANIMAL_ID_AUTOCOMPLETE_DROPDOWN_FIX,
    ),
    isCA8390On_DisplayReleventBillingPeriods: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8390_DISPLAY_RELEVENT_BILLING_PERIODS,
    ),
    isCA10398On_AnimalDropdownBareText: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10398_ANIMAL_DROPDOWN_BARE_TEXT,
    ),
    isCA8629On_RenameMedicalFields: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8629_RENAME_MEDICAL_FIELDS,
    ),
    isCA10448On_InvoicesValidateProcessing: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10448_INVOICES_VALIDATE_PROCESSING,
    ),
    isCA8591On_DeleteGiveMedItems: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8591_DELETE_GIVE_MED_ITEMS,
    ),
    isCA9498On_ResolveTreatmentLotSelectionLag: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA9498_RESOLVE_TREATMENT_LOT_SELECTION_LAG,
    ),
    isCA4897On_TruckList: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA4897_SETTINGS_TRUCK_LIST,
    ),
    isCA4898On_LoaderList: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA4898_SETTINGS_LOADER_LIST,
    ),
    isCA9089On_RestrictGiveTreatmentSameMedicalItem: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA9089_RESTRICT_GIVE_TREATMENT_SAME_MEDICAL_ITEM,
    ),
    isCA7808On_PreventFutureDateScaleTicket: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA7808_PREVENT_FUTURE_DATE_SCALE_TICKET,
    ),
    isCA4913On_AfChangePerHdModal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA4913_AF_CHANGE_PER_HD_MODAL,
    ),
    isCA8886On_RationTemplatesRationPerLimit: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8886_RATION_TEMPLATES_RATION_PER_LIMIT,
    ),
    isCA9236On_MixingBinList: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9236_SETTINGS_MIXING_BIN_LIST,
    ),
    isCA10029On_SeparateLocationDestination: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10029_SEPARATE_LOCATION_DESTINATION_LIST,
    ),
    isCA5440On_TechAndSeverityBug: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA5440_TECH_AND_SEVERITY_BUG,
    ),
    isCA4896On_LocationList: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA4896_SETTINGS_LOCATION_LIST,
    ),
    isCA5449On_GiveMedicalItemSelectIssue: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA5449_GIVE_MEDICAL_ITEM_SELECT_ISSUE,
    ),
    isCA4943On_DestinationList: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA4943_SETTINGS_DESTINATION_LIST,
    ),
    isCA10423On_ChargeNotCreditBug: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10423_CHARGE_NOT_CREDIT_BUG,
    ),
    isCA10371On_TreatmentPageTreatmentHistoryModal: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10371_TREATMENT_PAGE_TREATMENT_HISTORY_MODAL,
    ),
    isCA8676On_TechList: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8676_SETTINGS_TECH_LIST,
    ),
    isCA10690On_ProcessingFilter: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10690_PROCESSING_FILTER,
    ),
    isCA8679On_TankList: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8679_SETTINGS_TANK_LIST,
    ),
    isCA10570On_VendorList: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10570_SETTINGS_VENDOR_LIST,
    ),
    isCA8340On_CompanyList: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8340_SETTINGS_COMPANY_LIST,
    ),
    isCA10320On_EditDeletePenSettings: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10320_SETTINGS_PEN_EDIT_DELETE,
    ),
    isCA10540AddedRationNameColumn: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10540_ADDED_RATION_NAME_COLUMN,
    ),
    isCA4895On_BuyerList: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA4895_SETTINGS_BUYER_LIST,
    ),
    isCA10579On_NonEditableEstHeadCountPopUp: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10579_MAKE_EST_HEAD_COUNT_POPUP_NON_EDITABLE,
    ),
    isCA4894On_OwnerList: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA4894_SETTINGS_OWNER_LIST,
    ),
    isCA10543_RenamingRations: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10543_RENAMING_RATIONS,
    ),
    isCA8677On_RiderList: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8677_SETTINGS_RIDER_LIST,
    ),
    isCA8401On_YardageList: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8401_SETTINGS_YARDAGE_LIST,
    ),
    isCA11407OnRemoveDailyCommodityRecordsButton: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11407_REMOVE_DAILY_COMMODITY_RECORDS_BUTTON,
    ),
    isCA4888On_FMSettings: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA4888_FM_SETTINGS,
    ),
    isCA10145On_LotSummaryProcessingWeight: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10145_LOT_SUMMARY_PROCESSING_WEIGHT,
    ),
    isCA11572On_CowboyAccessToHotList: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11572_COWBOY_ACCESS_TO_HOT_LIST,
    ),
    isCA9667On_CommasForCommodities: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9667_COMMAS_FOR_COMMODITIES,
    ),
    isCA11016On_EIDMortalities: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11016_EID_MORTALITIES,
    ),
    isCA10876On_UploadEID: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10876_UPLOAD_EID,
    ),
    isCA10904On_EIDTreatments: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10904_EID_TREATMENTS,
    ),
    isCA8929On_LocalUTCDateTimeFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8929_LOCAL_UTC_DATE_TIME_FIX,
    ),
    isCA9515On_PenRationHistory_Feed: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9515_PEN_RATION_HISTORY_FEED,
    ),
    isCA10240On_ExportTreatmentDateFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10240_EXPORT_TREATMENT_DATE_FIX,
    ),
    isCA10895On_realizerInvalidPaymentDate: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10895_REALIZER_INVALID_PAYMENT_DATE,
    ),
    isCA9537On_PenRationScheduleRowDateFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9537_PEN_RATION_SCHEDULE_ROW_DATE_FIX,
    ),
    isCA9558On_ProdAlertMortalityGet502: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9558_PROD_ALERT_MORTALITY_GET_502,
    ),
    isCA9320On_MoveCattleDivideByZeroFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9320_MOVE_CATTLE_DIVIDE_BY_ZERO_FIX,
    ),
    isCA7270On_AddGetEstimatedHeads: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA7270_ADD_GET_ESTIMATED_HEADS_PENCACHE,
    ),
    isCA8700On_RationAddTotalAmount: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8700_RATION_ADD_TOTAL_AMOUNT,
    ),
    isCA8675On_LiquidAndMicroingredientsCommodities: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA8675_LIQUID_MICROINGREDIENT_COMMODITIES,
    ),
    isC9809On_LotNameFromLotInsteadOfAnimalLot: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA9809_LOTNAME_FROM_LOT_INSTEAD_OF_ANIMALLOT_IN_MORTALITY_REPORT,
    ),
    isCA9638On_SplitRationAllowOnly1Feedings: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9638_SPLIT_RATION_ALLOW_ONLY_1_FEEDINGS,
    ),
    isCA9836OnShouldRefreshOnIdle: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9836_SHOULD_REFRESH_ON_IDLE,
    ),
    isCA9574On_FeedCallDmChangeInsteadAfChange: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA9574_FEED_CALL_DM_CHANGE_INSTEAD_AF_CHANGE,
    ),
    isCA9674On_LoadedCommodityReportEnhancement: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA9674_LOADED_COMMODITY_REPORT_ENHANCEMENT,
    ),
    isCA10313On_HospitalPageUnassociatedHomePen: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10313_HOSPITAL_PAGE_UNASSOCIATED_HOME_PEN,
    ),
    isCA10021On_FixMultipleApiCallsRationTemplates: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10021_FIX_MULTIPLE_API_CALLS_RATION_TEMPLATES,
    ),
    isCA9756On_AllowSameIngredientsRationCreation: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA9756_ALLOW_SAME_INGREDIENTS_MULTIPLE_TIMES_IN_RATION_CREATION,
    ),
    isCA9963OnMFE2RationDropdownFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9963_MFE2_RATION_DROPDOWN_FIX,
    ),
    isCA9698On_TreatmentProtocolGiveTableBug: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9698_TREATMENT_PROTOCOL_GIVE_TABLE_BUG,
    ),
    isCA10166On_NumbersWithCommas: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10166_NUMBERS_WITH_COMMAS,
    ),
    isCA9436On_LoaderCommodityReportEnhancement: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA9436_LOADER_COMMODITY_REPORT_ENHANCEMENT,
    ),
    isCA9889OnFeedCallApiBlockLoaderAdd: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9889_FEED_CALL_API_BLOCK_LOADER_ADD,
    ),
    isCA10070OnSpiltScheduleInsertConflict: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10070_SPILT_SCHEDULE_INSERT_CONFLICT,
    ),
    isCA10054On_CommodityCosting: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10054_COMMODITY_COSTING,
    ),
    isCA10118On_ShipCattllePayweightFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10118_SHIP_CATTLE_PAYWEIGHT_FIX,
    ),
    isCA9639On_VariableSplits: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9639_VARIABLE_SPLITS,
    ),
    isCA10003OnFeedCallIncreaseSpeed: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10003_FEED_CALL_INCREASE_SPEED,
    ),
    isCA10260On_duplicateRationSchedule: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10260_DUPLICATE_RATION_SCHEDULE_ROWS,
    ),
    isCA10379On_rationScheduleRowDeleteNoDaysBug: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10379_PEN_RATION_SCHEDULE_NO_DAYS_DELETE_ROW_ISSUE,
    ),
    isCA10428On_MovementHistoryReportModal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10428_MOVEMENT_HISTORY_REPORT_MODAL,
    ),
    isCA10368on_scheduleRationChangeReport: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10368_SCHEDULE_RATION_CHANGE_REPORT,
    ),
    isCA10366On_FeedStatus2: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10366_FEED_STATUS_2,
    ),
    isCA9474On_FixTabClearsHdCount: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9474_FIX_TAB_CLEARS_HDCOUNT,
    ),
    isCA10731On_FixDmChangePerHdCalculationError: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10731_FIX_DM_CHANGE_PER_HD_CALCULATION_ERROR,
    ),
    isCA10455On_FeedCallRationChangeReminderModal: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10455_FEEDCALL_RATION_CHANGE_REMINDER_MODAL,
    ),
    isCA10333ReduceInfoInRequestResponseForMFE: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10333_REDUCE_INFO_IN_REQUEST_RESPONSE_FOR_MFE,
    ),
    isCA10645On_addRTKQuery: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10645_ADD_RTK_QUERY,
    ),
    isCA10584On_FeedCallNoCattleNoFeedCallLoaderFix: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10584_FEEDCALL_NO_CATTLE_NO_FEEDCALL_LOADER_FIX,
    ),
    isCA10586On_refactorFeedCall: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10586_REFACTOR_FEED_CALL,
    ),
    isCA10751On_FeedCallSamApiForGraphAndTable: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10751_FEEDCALL_SAME_API_FOR_GRAPH_AND_TABLE,
    ),
    isCA10761On_FeedCallEstHeadCountModalFix: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10761_FEEDCALL_EST_HEAD_COUNT_MODAL_FIX,
    ),
    isCA11188On_MoveCattleFormReset: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11188_MOVE_CATTLE_FORM_RESET,
    ),
    isCA10818On_FeedCallNoPensFilterMessage: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10818_FEEDCALL_NO_PENS_FILTER_MESSAGE,
    ),
    isCA10888On_FeedCallNoPensDisabledFieldsButtons: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10888_FEEDCALL_NO_PENS_DISABLED_FIELDS_BUTTONS,
    ),
    isCA10819On_FeedCallDmChangeHdAppearAfChangeModal: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA10819_FEEDCALL_DM_CHANGE_HD_APPEAR_AF_CHANGE_MODAL,
    ),
    isCA10959On_rtkQueryImprovements: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10959_RTK_QUERY_IMPROVEMENTS,
    ),
    isCA11189On_FeedCallRationScheduleModalFix: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA11189_FEEDCALL_RATION_SCHEDULE_MODAL_FIX,
    ),
    isCA10721On_MoveCattleEnhancement: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10721_MOVECATTLE_ENHANCEMENT,
    ),
    isCA11177On_FeedCallRouteAbilityAddPen: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11177_FEEDCALL_ROUTE_ABILITY_ADD_PEN,
    ),
};
