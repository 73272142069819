import { Draft } from '@reduxjs/toolkit';
import {
    FeedlotManagerDataEnumsFeedBunkScoreEnum,
    FeedlotManagerModelsFeedFeedCallCreateFeedCallModel,
    FeedlotManagerModelsFeedFeedCallFeedCallResponseModel,
    FeedlotManagerModelsFeedFeedCallUpdateFeedCallModel,
    FeedlotManagerModelsFeedRationRationApiResponse,
} from '../../../../../Redux/Apis/FeedCall/baseFeedCallApi';
import { FeedlotManagerModelsFeedFeedRouteFeedCallRoutePenInfo } from '../../../../../Redux/Apis/FeedCallRoute/baseFeedCallRouteApi';
import { FeedlotManagerModelsFeedPenRationSchedulePenRationSchedulePenInfoApiResponseRead } from '../../../../../Redux/Apis/PenRationSchedule/basePenRationScheduleApi';
import { FeedCallConstants } from '../../../../../utils/constants/FeedCallConstants';
import { IFeedCallPageSliceInitialState } from './slices/interfaces';

export const getFeedCallPutPayload = (
    feedCallPayload: FeedlotManagerModelsFeedFeedCallFeedCallResponseModel,
): FeedlotManagerModelsFeedFeedCallUpdateFeedCallModel | null => {
    const { penId, hdCount, afChangePerHd } = feedCallPayload;
    let feedCallPutPayload: FeedlotManagerModelsFeedFeedCallUpdateFeedCallModel | null =
        null;

    if (penId && hdCount && (afChangePerHd || afChangePerHd === 0)) {
        feedCallPutPayload = {
            penId,
            hdCount,
            //IRFTODO look into this
            bunkScore:
                feedCallPayload.bunkScore as unknown as FeedlotManagerDataEnumsFeedBunkScoreEnum,
            afChangePerHd,
            dmChangePerHd: feedCallPayload.dmChangePerHd,
            totalCall: feedCallPayload.totalCall,
            afPerHd: feedCallPayload.afPerHd,
            dmPerHd: feedCallPayload.dmPerHd,
            wtPerDM: feedCallPayload.wtPerDM ?? 0,
            adg: feedCallPayload.adg,
            estimatedHdCount: feedCallPayload.estimatedHdCount,
            estimatedHeadTracker: feedCallPayload.estimatedHeadTracker, //IRFTODO estimatedHeadTracker has involved logic (see feedCallBunkScore method feedCallPayload)
            noOfFeedingsBeforeShipment:
                feedCallPayload.noOfFeedingsBeforeShipment, //we have custom variable feedCallNoOfFeedingBeforeShipment in old feed call
            hayFeeding1: feedCallPayload.hayFeeding1,
            hayFeeding2: feedCallPayload.hayFeeding2,
            comments: feedCallPayload.comments,
        };
    }
    return feedCallPutPayload;
};

export const getFeedCallPostPayload = (
    feedCallPayload: FeedlotManagerModelsFeedFeedCallFeedCallResponseModel,
    extendRationSchedule: boolean,
    rations: FeedlotManagerModelsFeedRationRationApiResponse[],
    feedCallPenRationSchedule: FeedlotManagerModelsFeedPenRationSchedulePenRationSchedulePenInfoApiResponseRead,
): FeedlotManagerModelsFeedFeedCallCreateFeedCallModel | null => {
    const feedCallPutPayload = getFeedCallPutPayload(feedCallPayload);

    if (!feedCallPutPayload) {
        return null;
    }

    let startRationName: string | null | undefined,
        endRationName: string | null | undefined,
        startRationId: number | null | undefined,
        endRationId: number | null | undefined,
        startRationPercentage: number | undefined,
        endRationPercentage: number | undefined;
    //IRFTODO need to pop modal at some point that sets the 'extendRationSchedule' bool
    if (extendRationSchedule) {
        startRationName = feedCallPayload.startRationName;
        endRationName = feedCallPayload.endRationName;
        startRationId = feedCallPayload.startRationId;
        endRationId = feedCallPayload.endRationId;
        startRationPercentage = feedCallPayload.startRationPercentage;
        endRationPercentage = feedCallPayload.endRationPercentage;
    } else {
        const startRation = rations[0];
        const endRation = rations[1];

        startRationName = startRation?.name ?? '';
        startRationId = startRation?.rationId ?? null;
        startRationPercentage = startRation?.rationPercentage || 0;
        //original feed call post doesn't specify any endRation stuff if it doesn't exist - not even defaults of null/''
        endRationName = endRation?.name ?? '';
        endRationId = endRation?.rationId ?? null;
        endRationPercentage = endRation?.rationPercentage || 0;
    }

    const feedCallPostPayload: FeedlotManagerModelsFeedFeedCallCreateFeedCallModel =
        {
            ...feedCallPutPayload,
            afPerHd: feedCallPutPayload.afPerHd ?? 0, //IRFFIX
            bunkScore: feedCallPutPayload.bunkScore ?? 'NoBunkScore',
            totalCall: feedCallPutPayload.totalCall ?? 0,
            startRationName,
            endRationName,
            startRationId,
            endRationId,
            startRationPercentage,
            endRationPercentage,
            daysOnFeed: 0,
            daysOnCurrentRation: feedCallPayload.daysOnCurrentRation,
            penRationScheduleId:
                feedCallPenRationSchedule.penRationScheduleId ?? null,
            extendRationSchedule,
        };
    return feedCallPostPayload;
};

export const getFilteredFeedCallPensList = (
    //clean this up - taken from feedCallActions fetchFeedCallPens method.
    pens:
        | FeedlotManagerModelsFeedFeedRouteFeedCallRoutePenInfo[]
        | null
        | undefined,
    penFilterByRation: string | number,
): FeedlotManagerModelsFeedFeedRouteFeedCallRoutePenInfo[] => {
    if (!pens) {
        return [];
    }
    let filteredRationPens = pens;
    if (penFilterByRation === FeedCallConstants.rationIds.cattleNoFeedCall) {
        filteredRationPens = pens.filter(
            pen =>
                !pen.hasCurrentDayFeedCall &&
                pen.hdCount !== undefined &&
                pen.hdCount > 0,
        );
    } else if (Number(penFilterByRation)) {
        filteredRationPens = pens.filter(pen =>
            pen.applicableRationIds?.includes(Number(penFilterByRation)),
        );
        if (filteredRationPens.length === 0) {
            filteredRationPens = [...pens];
        }
    } else if (penFilterByRation !== FeedCallConstants.rationIds.all) {
        filteredRationPens = pens.filter(
            pen => !pen.applicableRationIds?.length,
        );
    }
    return filteredRationPens;
};

export const setLocalAssessmentValues = (
    state: Draft<IFeedCallPageSliceInitialState>,
    payload: {
        totalCall?: number | undefined;
        afChangePerHd?: number | undefined;
        dmChangePerHd?: number | undefined;
    },
) => {
    const { totalCall, afChangePerHd, dmChangePerHd } = payload;
    if (totalCall || totalCall === 0) {
        state.localAssessmentValues.totalCall = totalCall;
    }
    if (afChangePerHd || afChangePerHd === 0) {
        state.localAssessmentValues.afChangePerHd = afChangePerHd;
    }
    if (dmChangePerHd || dmChangePerHd === 0) {
        state.localAssessmentValues.dmChangePerHd = dmChangePerHd;
    }
};
