import { useState, useMemo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Store';
import {
    IBaseDetail,
    IBuyPoTruckingCompanyDetail,
} from '../BuyPo/interface/SettingsInterface';
import { useModalStatus } from '../../../../commonHooks/useModalStatus';
import StorageService from '../../../../Services/storage/storage.service';
import {
    IDeleteIds,
    ITenantSettingsFilter,
} from '../../../../utils/Interface/TenantSettingsInterface';
import { TenantSettingsConstant } from '../../../../utils/constants/TenantSettingsConstant';
import Constants from '../../../../utils/Constants';
import {
    checkModalItemAssociationNew,
    deleteItem,
    fetchTruckingCompanies,
} from '../../../../Redux/Actions/tenantSettingsAction';
import { activateTruckingCompany } from '../BuyPo/actions/buyPoActions';
import { FilterSearchBox } from '../tenantSettings/FilterSearchBox';
import BuyPoSettingsItemDetail from '../tenantSettings/BuypoSettingsItemDetail';
import { DeleteItemRowNew } from '../../../../components/tenantSettings/DeleteItemRowNew';
import CreateTruckingCompanyModal from './components/CreateTruckingCompanyModal';
import { useDebouncedEffect } from '../../../../components/useDebounceEffect';

const TruckingCompanySettingsNew = () => {
    const { filter, loading } = useAppSelector(state => state.tenantSetting);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedTruckingCompany, setSelectedTruckingCompany] =
        useState<IBuyPoTruckingCompanyDetail>();
    const {
        isOpen: isEditModalOpen,
        open: openEditModal,
        close: closeEditModal,
    } = useModalStatus();
    const {
        isOpen: isDeleteModalOpen,
        open: openDeleteModal,
        close: closeDeleteModal,
    } = useModalStatus();
    const [truckingCompany, setTruckingCompany] = useState<
        IBuyPoTruckingCompanyDetail[]
    >([]);
    const dispatch = useAppDispatch();
    const storage = useMemo(() => new StorageService(), []);

    const handleEditTruckingCompanyDetail = (item: IBaseDetail): void => {
        const truckingCompany = item as IBuyPoTruckingCompanyDetail;
        setSelectedTruckingCompany(truckingCompany);
        openEditModal();
    };

    const setTenantSettingsFilterInStorage = (
        filterValues: ITenantSettingsFilter,
    ) => {
        storage.setValue(
            TenantSettingsConstant.filter.tenantSettingsFilter,
            filterValues,
            Constants.Storage.LOCAL,
        );
    };

    const handleFilter = (name: string, value: string): void => {
        const updatedFilter = { ...filter };
        updatedFilter.truckingCompanyFilter[name] = value;
        dispatch(
            fetchTruckingCompanies(
                setTruckingCompany,
                updatedFilter.truckingCompanyFilter,
            ),
        );
        setTenantSettingsFilterInStorage(updatedFilter);
    };

    const handleChange = async (index: number) => {
        const TruckingCompanyList = [...truckingCompany];
        const updatedTruckingCompanyList = {
            ...TruckingCompanyList[index],
            isActive: !TruckingCompanyList[index].isActive,
        };
        await dispatch(activateTruckingCompany(updatedTruckingCompanyList));
        dispatch(
            fetchTruckingCompanies(
                setTruckingCompany,
                filter.truckingCompanyFilter,
            ),
        );
    };

    const handleItemUpdateFromModal = (
        updatedItem: IBuyPoTruckingCompanyDetail,
    ) => {
        let updatedIndex = truckingCompany.findIndex(
            item => item.companyId === updatedItem.companyId,
        );

        if (updatedIndex !== -1) {
            setTruckingCompany(prevItems => {
                const updatedItemsList = [...prevItems];
                updatedItemsList[updatedIndex] = {
                    ...updatedItem,
                };

                return updatedItemsList;
            });
        }
    };

    const closeModalHandler = () => {
        closeEditModal();
    };

    const handleDeleteModalHandler = async (item: IBaseDetail) => {
        const truckingCompany = item as IBuyPoTruckingCompanyDetail;
        if (truckingCompany.companyId) {
            const isAssociated = await dispatch(
                checkModalItemAssociationNew(
                    Number(truckingCompany.companyId),
                    TenantSettingsConstant.filter.truckingCompany.filterType,
                ),
            );
            let updatedTruckingCompany = {
                ...truckingCompany,
                isAssociated: isAssociated,
            };
            setSelectedTruckingCompany(updatedTruckingCompany);
            openDeleteModal();
        }
    };

    const handleDeleteTruckingCompany = async (shouldTakeAction: boolean) => {
        if (shouldTakeAction && selectedTruckingCompany) {
            const isActiveAndAssociated =
                selectedTruckingCompany.isAssociated &&
                selectedTruckingCompany.isActive;
            if (isActiveAndAssociated) {
                const updatedTruckingCompanyList = {
                    ...selectedTruckingCompany,
                    isActive: !selectedTruckingCompany.isActive,
                };
                await dispatch(
                    activateTruckingCompany(updatedTruckingCompanyList),
                );
            } else {
                const deleteIds = [
                    { id: selectedTruckingCompany.companyId },
                ] as IDeleteIds[];
                await dispatch(
                    deleteItem(
                        deleteIds,
                        TenantSettingsConstant.filter.truckingCompany
                            .filterType,
                    ),
                );
            }
            dispatch(
                fetchTruckingCompanies(
                    setTruckingCompany,
                    filter.truckingCompanyFilter,
                ),
            );
        }
        closeDeleteModal();
    };

    useDebouncedEffect(
        () => {
            if (searchQuery !== undefined) {
                handleFilter('name', searchQuery);
            }
        },
        [searchQuery],
        1000,
    );

    useEffect(() => {
        dispatch(
            fetchTruckingCompanies(
                setTruckingCompany,
                filter.truckingCompanyFilter,
            ),
        );
    }, [dispatch, storage]);

    return (
        <div className="settings-container">
            <div className="my-3">
                <h5>{TenantSettingsConstant.filter.truckingCompany.heading}</h5>
                <FilterSearchBox
                    filterType={
                        TenantSettingsConstant.filter.truckingCompany.filterType
                    }
                    handleFilter={handleFilter}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                ></FilterSearchBox>
                <BuyPoSettingsItemDetail<IBaseDetail>
                    columnDetail={
                        TenantSettingsConstant.filter.truckingCompany
                            .columnDetail
                    }
                    itemDetail={truckingCompany}
                    handleChange={handleChange}
                    handleEditDetail={handleEditTruckingCompanyDetail}
                    handleDeleteModalHandler={handleDeleteModalHandler}
                    loader={loading}
                    filterType={
                        TenantSettingsConstant.filter.truckingCompany.filterType
                    }
                ></BuyPoSettingsItemDetail>
                {isEditModalOpen && selectedTruckingCompany && (
                    <CreateTruckingCompanyModal
                        editTruckingCompanyDetails={selectedTruckingCompany}
                        showModal={isEditModalOpen}
                        closeModalHandler={closeModalHandler}
                        onUpdate={handleItemUpdateFromModal}
                    />
                )}
            </div>
            {isDeleteModalOpen && selectedTruckingCompany && (
                <DeleteItemRowNew
                    item={selectedTruckingCompany}
                    handleDeleteItem={handleDeleteTruckingCompany}
                    showModal={isDeleteModalOpen}
                    itemName={
                        TenantSettingsConstant.filter.truckingCompany.label
                    }
                ></DeleteItemRowNew>
            )}
        </div>
    );
};

export default TruckingCompanySettingsNew;
