import { IItemRow } from '../../../../utils/Interface/TenantSettingsInterface';
import { Col } from 'react-bootstrap';
import DeleteIcon from '../../../../Assets/DeleteIcon';
import EditIcon from '../../../../Assets/EditIcon';
import { TenantSettingsConstant } from '../../../../utils/constants/TenantSettingsConstant';
import { cattlePO } from '../../featureFlags/cattlePOFeatures';

export const ItemRow = ({
    item,
    filterType,
    onChange,
    onUpdate,
    onDelete,
}: IItemRow) => {
    const { isBP888On_truckingCompanyDelete } = cattlePO;
    return (
        <Col className="d-flex align-items-center p-2 item-row">
            <Col xs={3}>{item.name}</Col>
            <Col xs={7}>
                <input
                    type="checkbox"
                    checked={item.isActive}
                    className="mx-2"
                    onChange={onChange}
                />
            </Col>
            <Col xs={2} className="d-flex justify-content-center">
                <EditIcon
                    className={'cursor-pointer delete-icon'}
                    onClick={onUpdate}
                />
                {filterType !==
                TenantSettingsConstant.filter.truckingCompany.filterType ? (
                    <DeleteIcon
                        className={'cursor-pointer delete-icon'}
                        style={{ marginLeft: '10px' }}
                        onClick={onDelete}
                    />
                ) : filterType ===
                      TenantSettingsConstant.filter.truckingCompany
                          .filterType && isBP888On_truckingCompanyDelete ? (
                    <DeleteIcon
                        className={'cursor-pointer delete-icon'}
                        style={{ marginLeft: '10px' }}
                        onClick={onDelete}
                    />
                ) : null}
            </Col>
        </Col>
    );
};
