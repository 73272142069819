import _ from 'lodash';
import { UIEvent } from 'react';
import { thisDateWithinCutoff } from '.';
import { AppDispatch } from '../Redux/Store';
import { apiCall } from '../Services/AxiosService';
import { feedlotManagerFeatures } from '../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import InvoiceAlreadyGeneratedError from '../components/common/InvoiceAlreadyGeneratedError';
import Constants from '../utils/Constants';
import {
    FeedlotManagerNames,
    IGenericFilter,
    IGetCutoffDateResponse,
    ShortenedFeedlotManagerNames,
} from '../utils/Interface/CommonInterface';
import { IFee } from '../utils/Interface/ReceiveCattleInterface';
import dayjs from '../utils/dayjs';
import { LotState } from '../utils/Interface/LotInterface';
import { FeedlotManagerModelsResponseModelsFeedlotApiResponse } from '../Redux/Apis/FMFeedlot/baseFMFeedlotApi';

const {
    isCA9498On_ResolveTreatmentLotSelectionLag,
    isCA9919On_ManageUsersPage,
} = feedlotManagerFeatures;

export const deepClone = <T>(data: T) => {
    return _.cloneDeep(data);
};

export const getQueryString = (filter: IGenericFilter) => {
    const queryString = Object.keys(filter).reduce((queryString, field) => {
        return filter[field] || filter[field] === false || filter[field] === 0
            ? `${queryString}${field}=${filter[field] as string}&`
            : queryString;
    }, '');
    return queryString;
};

/**
 * @deprecated Prefer an implementation of the useElementOnScreen custom hook
 */
export const genericHandleScroll = (
    e: UIEvent<HTMLDivElement>,
    loading: boolean,
    filterHandler: (
        filterFieldName: string,
        filterFieldValues: string | number,
    ) => void,
    filter: IGenericFilter,
    canCallApi: boolean,
) => {
    const bottom =
        e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
        e.currentTarget.clientHeight;
    if (bottom && !loading && canCallApi) {
        filterHandler('offset', Number(filter.limit) + Number(filter.offset));
    }
};

export const resetTimeIds = (
    timeIds: number[],
    setTimeIds: (previousIds) => void,
) => {
    if (timeIds.length) {
        for (const i in timeIds) {
            clearTimeout(timeIds[i]);
        }
        setTimeIds([]);
    }
};

export const processNumberField = (
    newValue: string,
    fieldType: string,
    maxLength: number,
) => {
    if (
        (fieldType === 'number' || fieldType === 'float') &&
        typeof newValue === 'string'
    ) {
        if (newValue.length > maxLength) {
            newValue = newValue.slice(0, maxLength);
        }
    }
    return newValue;
};

// utils.ts
export const handleLotChangeBackdateCheck = async (
    feedlotId: number,
    lotId: number,
    recordDate,
    dispatchFunction,
    setGeneratedInvoiceCutoffDateFunction: (dateString: string) => void,
    setErrorModalFunction?: ({ showModal, errorMessage }) => void,
) => {
    const cutoffDate = await getGeneratedInvoiceCutoffDate(+feedlotId, +lotId);

    dispatchFunction(
        setGeneratedInvoiceCutoffDateFunction(
            cutoffDate ? dayjs(cutoffDate).format('YYYY-MM-DD') : '',
        ),
    );

    const invoiceIsAlreadyGeneratedForDate = cutoffDate
        ? thisDateWithinCutoff(dayjs(recordDate), dayjs(cutoffDate))
        : false;

    if (invoiceIsAlreadyGeneratedForDate && setErrorModalFunction) {
        setErrorModalFunction({
            showModal: true,
            errorMessage: InvoiceAlreadyGeneratedError({ cutoffDate }),
        });
    }
    return invoiceIsAlreadyGeneratedForDate;
};

export const getCutoffDate = async (
    feedlotId: number,
    lotId: number,
    dispatchFunction,
    setGeneratedInvoiceCutoffDateFunction?: (dateString: string) => void,
) => {
    const invoiceCutoffDate = await getGeneratedInvoiceCutoffDate(
        +feedlotId,
        +lotId,
    );
    if (setGeneratedInvoiceCutoffDateFunction) {
        dispatchFunction(
            setGeneratedInvoiceCutoffDateFunction(
                invoiceCutoffDate
                    ? dayjs(invoiceCutoffDate).format('YYYY-MM-DD')
                    : '',
            ),
        );
    }

    let cutoffDateResponse: IGetCutoffDateResponse;

    if (invoiceCutoffDate && isCA9498On_ResolveTreatmentLotSelectionLag) {
        cutoffDateResponse = {
            cutoffDate: invoiceCutoffDate,
            cutoffDateIsDueToInvoice: true,
        };
    } else {
        let receiveCattleCutoffDate = await getFirstReceiveCattleDate(
            `lotId=${lotId}`,
            +feedlotId,
        );

        const invoicesGeneratedAfterReceive = dayjs(
            receiveCattleCutoffDate,
        ).isBefore(dayjs(invoiceCutoffDate), 'day');

        let emptyLotCutoffDate = '';
        if (lotId && feedlotId && receiveCattleCutoffDate) {
            const newEmptyLotCutoffDate = await fetchLatestDateWithNoHdCount(
                feedlotId,
                +lotId,
                invoicesGeneratedAfterReceive
                    ? (invoiceCutoffDate ?? receiveCattleCutoffDate)
                    : receiveCattleCutoffDate,
            );

            emptyLotCutoffDate = newEmptyLotCutoffDate
                ? dayjs(newEmptyLotCutoffDate)
                      .add(1, 'day')
                      .format('YYYY-MM-DD')
                : '';
        }

        receiveCattleCutoffDate =
            dayjs(receiveCattleCutoffDate).isAfter(emptyLotCutoffDate, 'day') ||
            !emptyLotCutoffDate
                ? receiveCattleCutoffDate
                : emptyLotCutoffDate;

        cutoffDateResponse = {
            cutoffDate:
                invoiceCutoffDate > receiveCattleCutoffDate
                    ? dayjs(invoiceCutoffDate)
                          .add(1, 'day')
                          .format('YYYY-MM-DD')
                    : receiveCattleCutoffDate,
            cutoffDateIsDueToInvoice: false,
        };
    }
    return cutoffDateResponse;
};

export const handleFindPenInformation =
    (
        feedlotId: number,
        lotId: number,
        selectedDate: string,
        getCurrentWeight = false,
        ableToBackdateWithoutCausingNegHd = false,
        isShipCattlePens = false,
        setShipCattleLotBackdatedHdCountFunction?: (hdCount: number) => void,
    ) =>
    async (dispatch: AppDispatch): Promise<any[]> => {
        const payloadDate = dayjs(selectedDate).isAfter(dayjs())
            ? dayjs().format('YYYY-MM-DD')
            : selectedDate;
        const allLotSnapshots = await apiCall(
            'get',
            `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.LOTS}${Constants.apiUrls.GET_LIST_OF_SNAPSHOTS}?lotId=${lotId}&selectedDate=${payloadDate}&getCurrentWeight=${getCurrentWeight}`,
        );
        const todaysPenIds = allLotSnapshots[0].lotPenContexts.map(
            pen => pen.penId,
        );

        if (allLotSnapshots.length && selectedDate) {
            const matchingSnapshot = allLotSnapshots.find(snapshot =>
                dayjs(snapshot.date).utc().isSame(selectedDate, 'day'),
            );

            if (matchingSnapshot) {
                if (
                    isShipCattlePens &&
                    setShipCattleLotBackdatedHdCountFunction
                ) {
                    dispatch(
                        setShipCattleLotBackdatedHdCountFunction(
                            +matchingSnapshot.lotHdCount,
                        ),
                    );
                }
                let penOptions = matchingSnapshot.lotPenContexts.filter(
                    pen => pen.lotPenHdCount > 0,
                );
                if (!ableToBackdateWithoutCausingNegHd) {
                    penOptions = penOptions.filter(pen =>
                        todaysPenIds.includes(pen.penId),
                    );
                }
                penOptions = penOptions.map(pen => {
                    return {
                        penId: pen.penId,
                        name: pen.penName,
                        hdCount: pen.lotPenHdCount,
                        hotCattle: pen.hotCattle,
                        kind: pen.kind,
                        averageWeight: pen.weight,
                        ...(ableToBackdateWithoutCausingNegHd
                            ? {
                                  penAssociatedWithLotToday:
                                      todaysPenIds.includes(pen.penId),
                              }
                            : {}),
                        ...(isShipCattlePens ? { penTotalHeadToShip: '' } : {}),
                    };
                });
                return penOptions;
            }
        }
        return [];
    };

export const fetchLatestDateWithNoHdCount = async (
    feedlotId: number,
    lotId: number,
    cutoffDate: string,
) => {
    const allLotSnapshots = await apiCall(
        'get',
        `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.LOTS}${Constants.apiUrls.GET_LIST_OF_SNAPSHOTS}?lotId=${lotId}&selectedDate=${cutoffDate}`,
    );

    if (allLotSnapshots.length) {
        const snapshotsWithZeroHdCount = allLotSnapshots.filter(
            obj => obj.lotHdCount <= 0,
        );

        let latestDateWithNoHdCount = '';
        if (snapshotsWithZeroHdCount.length) {
            latestDateWithNoHdCount = snapshotsWithZeroHdCount.reduce(
                (latestDate, currentSnapshot) => {
                    const currentDate = dayjs(currentSnapshot.date).format(
                        'YYYY-MM-DD',
                    );
                    return currentDate > latestDate ? currentDate : latestDate;
                },
                dayjs(snapshotsWithZeroHdCount[0]?.date).format('YYYY-MM-DD'),
            );
        }

        return latestDateWithNoHdCount;
    }

    return null;
};

export const getFirstReceiveCattleDate = async (
    requestPayload: string,
    feedlotId: number,
) => {
    const queryString = `&include=destination&sort=^DateReceived&sort=^Date`;

    const movementHistory = await apiCall(
        'get',
        `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.LOTS}${Constants.apiUrls.MOVEMENT_HISTORY}?${requestPayload}${queryString}`,
    );

    if (movementHistory) {
        const firstReceiveCattleDate = movementHistory.length
            ? movementHistory[movementHistory.length - 1].date.split('T')[0]
            : '';
        return firstReceiveCattleDate;
    }
};

export const getGeneratedInvoiceCutoffDate = async (
    feedlotId: number,
    lotIds: number | number[],
) => {
    if (Number.isNaN(lotIds)) {
        lotIds = 0;
    }

    const filterStringBuilder = () => {
        let builtString = '';
        if (Array.isArray(lotIds)) {
            lotIds.forEach(lotId => {
                builtString += `LotId eq ${lotId} || `;
            });
            builtString = builtString.slice(0, -3);
        } else {
            builtString = `LotId eq ${lotIds}`;
        }
        builtString += `; IsPreview eq false`;
        return builtString;
    };

    const filterString = filterStringBuilder();

    const latestEndDateRecord = await apiCall(
        'get',
        `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.INVOICE}?flid=${feedlotId}&limit=1&sort=^BillingEndDate&filter=${filterString}`,
    );
    if (latestEndDateRecord.length) {
        const cutoffDate = latestEndDateRecord[0]?.billingEndDate;
        return cutoffDate;
    }
};

export const getEarliestGeneratedInvoicesCutoffDate = async (
    feedlotId: number,
    onlyOpenLots = false,
) => {
    const onlyOpenLotsParameter = `&onlyOpenLots=${onlyOpenLots}`;

    const allGeneratedInvoicesCutoffDates = await apiCall(
        'get',
        `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.INVOICE}${Constants.apiUrls.AVAILABLE_INVOICE}${Constants.apiUrls.DATES}?flid=${feedlotId}${onlyOpenLotsParameter}`,
    );

    let earliestDate = '';

    for (const lotId in allGeneratedInvoicesCutoffDates) {
        if (
            dayjs(allGeneratedInvoicesCutoffDates[lotId]).isBefore(
                dayjs(earliestDate || dayjs().toDate()).format('YYYY-MM-DD'),
                'day',
            )
        ) {
            earliestDate = allGeneratedInvoicesCutoffDates[lotId];
        }
    }

    return dayjs(earliestDate).format('YYYY-MM-DD');
};

export const fetchRemainingPercentage = (currentPercent: number) => {
    const totalPercentage = 100;
    if (!currentPercent) return totalPercentage;

    return totalPercentage - currentPercent;
};

export const fetchCompleteStatus = (
    accessCondition: boolean,
    feeArray: IFee[],
    brandsFeeRequired = false,
) => {
    if (accessCondition) {
        const cattleFees = feeArray.filter(fee => fee.costs === 'Cattle');
        const freightFees = feeArray.filter(fee => fee.costs === 'Freight');
        let feesRequiredToBeComplete = [...cattleFees, ...freightFees];

        if (brandsFeeRequired) {
            const brandsFees = feeArray.filter(fee => fee.costs === 'Brands');
            feesRequiredToBeComplete = [
                ...feesRequiredToBeComplete,
                ...brandsFees,
            ];
        }

        const requiredFeesNotFilled = feesRequiredToBeComplete.some(
            fee => !fee.amount,
        );

        return !requiredFeesNotFilled;
    }
    return false;
};

export const processFees = (fees, id?: number) => {
    return fees.map(fee => {
        const newFee = { ...fee };

        if (newFee.amount) {
            newFee.amount = parseFloat(newFee.amount).toFixed(2);
        } else if (+newFee.amount === 0) {
            newFee.amount = '';
        }

        newFee.type = newFee.costs;

        if (id) {
            newFee.tableId = id;
        }

        newFee.feeId = newFee.id ? newFee.id : 0;

        delete newFee.id;
        delete newFee.isDisable;

        return newFee;
    });
};

export const findDisplayFeedlotName = (
    selectedFeedlot: Required<FeedlotManagerModelsResponseModelsFeedlotApiResponse>,
) => {
    const enumValue = Object.keys(FeedlotManagerNames).find(
        key => FeedlotManagerNames[key] === selectedFeedlot.label,
    );
    if (enumValue) {
        return ShortenedFeedlotManagerNames?.[enumValue] ?? '';
    } else {
        return selectedFeedlot.label?.split(' ')[0] ?? '';
    }
};

export const removeCountryCodePrefix = value => {
    if (value && value.startsWith('+1')) {
        return value.substring(2);
    }
    return value;
};

export const lotFullyClosed = lot => {
    return !!lot?.lotState && lot?.lotState === LotState.Inactive;
};

export const lotNotOpen = lot => {
    return !!lot?.lotState && lot?.lotState !== LotState.Open;
};

export const nameOf = <T>(name: keyof T) => name;

export const sortAlphaNumeric = (theList, fieldName: string) => {
    if (isCA9919On_ManageUsersPage) {
        return theList.sort((a, b) =>
            new Intl.Collator('en', {
                numeric: true,
                sensitivity: 'accent',
            }).compare(a[fieldName], b[fieldName]),
        );
    } else {
        theList.sort((a, b) =>
            new Intl.Collator('en', {
                numeric: true,
                sensitivity: 'accent',
            }).compare(a[fieldName], b[fieldName]),
        );
    }
};
