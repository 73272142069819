export const TenantSettingsConstant = {
    noDataFound: 'No data found',
    killGroupCheckbox: {
        label: 'Display Kill Group',
    },
    filter: {
        filterOptions: [
            {
                label: 'Active',
                name: 'show',
                value: 'active',
            },
            {
                label: 'Inactive',
                name: 'show',
                value: 'inactive',
            },
            {
                label: 'View All',
                name: 'show',
                value: 'all',
            },
        ],
        tenantSettingsFilter: 'tenantSettingsFilter',
        active: {
            label: 'Active',
            value: 'active',
        },
        destination: {
            placeholder: 'Search',
            value: 'name',
            maxLength: 50,
            filterType: 'destinationFilter',
            label: 'Destination',
            heading: 'Manage Destination',
            columnDetail: [
                { label: 'Destination', key: 'name' },
                { label: 'Active', key: 'isActive' },
            ],
        },
        buyLocation: {
            placeholder: 'Search',
            value: 'name',
            maxLength: 50,
            filterType: 'buyLocationFilter',
            label: 'Location',
            heading: 'Manage Location',
            columnDetail: [
                { label: 'Location', key: 'name' },
                { label: 'Active', key: 'isActive' },
            ],
        },
        truckingCompany: {
            placeholder: 'Search',
            value: 'name',
            maxLength: 50,
            filterType: 'truckingCompanyFilter',
            label: 'Trucking Company',
            heading: 'Manage Trucking Company',
            columnDetail: [
                { label: 'Trucking Company', key: 'name' },
                { label: 'Active', key: 'isActive' },
            ],
        },
        killGroup: {
            placeholder: 'Search',
            value: 'name',
            maxLength: 50,
            filterType: 'killGroupFilter',
            label: 'Kill Group',
            heading: 'Manage Kill Group',
            killGroupFilter: 'killGroupFilter',
            columnDetail: [
                { label: 'Kill Group', key: 'name' },
                { label: 'Active', key: 'isActive' },
            ],
        },
        color: {
            placeholder: 'Search',
            value: 'name',
            maxLength: 50,
            filterType: 'colorFilter',
            label: 'Color Group',
            heading: 'Manage Color',
            colorFilter: 'colorFilter',
            columnDetail: [
                { label: 'Color', key: 'name' },
                { label: 'Active', key: 'isActive' },
            ],
        },
        sex: {
            placeholder: 'Search',
            value: 'name',
            maxLength: 50,
            filterType: 'sexFilter',
            label: 'Sex Group',
            heading: 'Manage Sex',
            sexFilter: 'sexFilter',
            columnDetail: [
                { label: 'Sex', key: 'name' },
                { label: 'Active', key: 'isActive' },
            ],
        },
        breed: {
            placeholder: 'Search',
            value: 'name',
            maxLength: 50,
            filterType: 'breedFilter',
            label: 'Breed Group',
            heading: 'Manage Breed',
            breedFilter: 'breedFilter',
            columnDetail: [
                { label: 'Breed', key: 'name' },
                { label: 'Active', key: 'isActive' },
            ],
        },
    },
    deleteConfirmationModal: {
        header: {
            associationHeader: 'Confirm:',
            importantNoticeHeader: 'Important Notice:',
        },
        message: {
            confirmationMessage: 'Are you sure you want to delete this record?',
            associationMessage:
                '{Item Name} is associated with one or more records and cannot be deleted.\n\nWould you like to deactivate the {Item Name} to exclude it from dropdown selection?',
            importantNoticeMessage:
                'The Item is connected to BuyPO and has already been deactivated. \n\nAs a result you cannot delete it. ',
            associatedWithScaleTicket:
                'This trucking company is associated with scale ticket. \n\nAs a result you cannot delete it.  ',
        },
        footer: {
            cancel: 'Cancel',
            ok: 'Ok',
            yes: 'Yes',
            no: 'No',
        },
    },
};
