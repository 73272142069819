import { useState, useMemo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Store';
import {
    IBaseDetail,
    ISettingsColorDetail,
} from '../BuyPo/interface/SettingsInterface';
import { useModalStatus } from '../../../../commonHooks/useModalStatus';
import StorageService from '../../../../Services/storage/storage.service';
import {
    IDeleteIds,
    ITenantSettingsFilter,
} from '../../../../utils/Interface/TenantSettingsInterface';
import { TenantSettingsConstant } from '../../../../utils/constants/TenantSettingsConstant';
import Constants from '../../../../utils/Constants';
import {
    checkModalItemAssociationNew,
    deleteItem,
    fetchColors,
} from '../../../../Redux/Actions/tenantSettingsAction';
import { activateColor } from '../BuyPo/actions/buyPoActions';
import { FilterSearchBox } from '../tenantSettings/FilterSearchBox';
import BuyPoSettingsItemDetail from '../tenantSettings/BuypoSettingsItemDetail';
import { DeleteItemRowNew } from '../../../../components/tenantSettings/DeleteItemRowNew';
import { useDebouncedEffect } from '../../../../components/useDebounceEffect';
import CreateColorModal from '../BuyPo/components/CreateColorModal';
import { ColorVisibilityCheckbox } from './ColorVisibilityCheckbox';

const ColorSettings = () => {
    const { filter, loading } = useAppSelector(state => state.tenantSetting);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedColor, setSelectedColor] = useState<ISettingsColorDetail>();
    const {
        isOpen: isEditModalOpen,
        open: openEditModal,
        close: closeEditModal,
    } = useModalStatus();
    const {
        isOpen: isDeleteModalOpen,
        open: openDeleteModal,
        close: closeDeleteModal,
    } = useModalStatus();
    const [color, setColor] = useState<ISettingsColorDetail[]>([]);
    const dispatch = useAppDispatch();
    const storage = useMemo(() => new StorageService(), []);

    const handleEditColorDetail = (item: IBaseDetail): void => {
        const color = item as ISettingsColorDetail;
        setSelectedColor(color);
        openEditModal();
    };

    const setTenantSettingsFilterInStorage = (
        filterValues: ITenantSettingsFilter,
    ) => {
        storage.setValue(
            TenantSettingsConstant.filter.tenantSettingsFilter,
            filterValues,
            Constants.Storage.LOCAL,
        );
    };

    const handleFilter = (name: string, value: string): void => {
        const updatedFilter = { ...filter };
        updatedFilter.colorFilter[name] = value;
        dispatch(fetchColors(setColor, updatedFilter.colorFilter));
        setTenantSettingsFilterInStorage(updatedFilter);
    };

    const handleChange = async (index: number) => {
        const colorList = [...color];
        const updatedColorList = {
            ...colorList[index],
            isActive: !colorList[index].isActive,
        };
        await dispatch(activateColor(updatedColorList));
        dispatch(fetchColors(setColor, filter.colorFilter));
    };

    const handleItemUpdateFromModal = (updatedItem: ISettingsColorDetail) => {
        const updatedIndex = color.findIndex(
            item => item.id === updatedItem.id,
        );

        if (updatedIndex !== -1) {
            setColor(prevItems => {
                const updatedItemsList = [...prevItems];
                updatedItemsList[updatedIndex] = {
                    ...updatedItem,
                };

                return updatedItemsList;
            });
        }
    };

    const closeModalHandler = () => {
        closeEditModal();
    };

    const handleDeleteModalHandler = async (item: IBaseDetail) => {
        const color = item as ISettingsColorDetail;
        if (color.id) {
            const isAssociated = await dispatch(
                checkModalItemAssociationNew(
                    Number(color.id),
                    TenantSettingsConstant.filter.color.filterType,
                ),
            );
            const updatedColor = {
                ...color,
                isAssociated: isAssociated,
            };
            setSelectedColor(updatedColor);
            openDeleteModal();
        }
    };

    const handleDeleteColor = async (shouldTakeAction: boolean) => {
        if (shouldTakeAction && selectedColor) {
            const isActiveAndAssociated =
                selectedColor.isAssociated && selectedColor.isActive;
            if (isActiveAndAssociated) {
                const updatedColorList = {
                    ...selectedColor,
                    isActive: !selectedColor.isActive,
                };
                await dispatch(activateColor(updatedColorList));
            } else {
                const deleteIds = [{ id: selectedColor.id }] as IDeleteIds[];
                await dispatch(
                    deleteItem(
                        deleteIds,
                        TenantSettingsConstant.filter.color.filterType,
                    ),
                );
            }
            dispatch(fetchColors(setColor, filter.colorFilter));
        }
        closeDeleteModal();
    };

    useDebouncedEffect(
        () => {
            if (searchQuery !== undefined) {
                handleFilter('name', searchQuery);
            }
        },
        [searchQuery],
        1000,
    );

    useEffect(() => {
        dispatch(fetchColors(setColor, filter.colorFilter));
    }, [dispatch, storage]);

    return (
        <div className="settings-container">
            <ColorVisibilityCheckbox />
            <div className="my-3">
                <h5>{TenantSettingsConstant.filter.color.heading}</h5>
                <FilterSearchBox
                    filterType={TenantSettingsConstant.filter.color.filterType}
                    handleFilter={handleFilter}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                ></FilterSearchBox>
                <BuyPoSettingsItemDetail<IBaseDetail>
                    columnDetail={
                        TenantSettingsConstant.filter.color.columnDetail
                    }
                    itemDetail={color}
                    handleChange={handleChange}
                    handleEditDetail={handleEditColorDetail}
                    handleDeleteModalHandler={handleDeleteModalHandler}
                    loader={loading}
                    filterType={TenantSettingsConstant.filter.color.filterType}
                ></BuyPoSettingsItemDetail>
                {isEditModalOpen && selectedColor && (
                    <CreateColorModal
                        editColorDetails={selectedColor}
                        showModal={isEditModalOpen}
                        closeModalHandler={closeModalHandler}
                        onUpdate={handleItemUpdateFromModal}
                    />
                )}
            </div>
            {isDeleteModalOpen && selectedColor && (
                <DeleteItemRowNew
                    item={selectedColor}
                    handleDeleteItem={handleDeleteColor}
                    showModal={isDeleteModalOpen}
                    itemName={TenantSettingsConstant.filter.color.label}
                ></DeleteItemRowNew>
            )}
        </div>
    );
};

export default ColorSettings;
