import { financeManagerFeatures } from '../apps/financeManager/featureFlags/financeManagerFeatures';

const { isFA499AddOwnerDropdownOnOwnerReportPage } = financeManagerFeatures;

const Constants = {
    showMaxViewLimit: 10,
    createBuyPoAllowFileType: [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'image/heic',
        'image/heif',
        'application/msword',
        'application/pdf',
        'text/csv',
        'text/plain',
        'application/octet-stream',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
    validFileSize: 20971520,
    validFileSizeInMB: '20 MB',
    createBuyPoAllowImageType: ['png', 'jpeg', 'jpg', 'heic', 'heif'],
    createBuyPoAllowDocType: ['docx', 'doc'],
    createBuyPoAllowPdfType: ['pdf', 'pdfx'],
    createBuyPoAllowXlsType: ['xlsx', 'xls'],
    createBuyPoAllowCsvType: ['csv'],
    preCalculatedValue: {
        millisecondsPerDay: 86400000,
    },
    monthsList: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],
    createBuyPoAllowFileExtension: [
        'png',
        'jpeg',
        'jpg',
        'heic',
        'heif',
        'docx',
        'doc',
        'txt',
        'pdf',
        'xlsx',
        'xls',
        'csv',
    ],
    apiUrls: {
        USER_AUTH: '/tm/auth',
        TRANSIENT_USER_AUTH: '/tm/auth/transient',
        TENANT: 'tm/tenants',
        RESET_PASSWORD: '/tm/users/password/reset',
        SET_USER_PASSWORD: '/tm/users/password',
        TRUCKING_COMPANY_BY_NAME: '/TruckingCompanyByName',
        KILL_GROUP_BY_NAME: 'KillGroupByName',
        ANIMAL: '/animal',
        CHECKWEIGHT: '/checkweight',
        MORTALITY: '/Mortality',
        MORTALITY_REASON: '/MortalityReason',
        BUNKSCORE: '/bunkScore',
        BUNKDETAIl: '/BunkScore',
        LOT_PDF_DATA: '/pdfdata',
        INVOICE: '/Invoices',
        AVAILABLE_INVOICE: '/available',
        AVAILABLE_OWNERS_PDF: '/available/owners/pdf',
        LOTS_SHIP_LATEST: '/lots/latest',
        AVAILABLE_BINARY: '/available/binary',
        GENBILLING: '/Genbilling',
        DATABRICKS: '/Databricks',
        DATES: '/dates',
        ESTIMATED_HEADS: '/EstimatedHeads',
        BILLING: '/Billing',
        MISC_ADJUSTMENTS: '/miscAdjustments',
        OUTSTANDING_FEED_CHARGES: '/OutstandingFeedCharges',
        ASSESSMENT: '/Assessment',
        FEEDCALL: '/FeedCall',
        PEN_FEEDCALL: '/PenFeedCall',
        SUB_LOTS: '/SubLots',
        PEN_FEEDCALL_RATIONS: '/PenFeedCallRations',
        CONFIRM_PEN_RATION_SEGMENT_CHANGE_FOR_DAY:
            'ConfirmPenRationSegmentChangeForTheDay',
        SHIPPO_LOCATION: '/ShipLocation',
        SHIPPO_DESTINATION: '/Destination',
        BUYPO_BUY_LOCATION: '/sources',
        BUYPO_DESTINATION: '/destinations',
        CHECK_ASSOCIATION: '/CheckAssociation',
        DELETE_RANGE: '/DeleteRange',
        TRUCKING_COMPANY: '/TruckingCompanies',
        FM_TRUCKING_COMPANY: '/FmTruckingCompany',
        KILL_GROUP: '/KillGroup',
        PAYEE: '/Payee',
        TRUCKING_COMPANY_VIEW: '/TruckingCompanies/View',
        COMPANY_OWNERS: '/Owners',
        VENDORS: '/Vendors',
        COLOR: '/Color',
        SEX: '/Sex',
        BREED: '/Breed',
        SLIDE_UNIT: '/SlideUnit',
        BUY_PO_EXCEL_RECORD: '/BuyPOListToExcel',
        BUY_PO_INFO: '/BuyPOs',
        BUY_PO_PAGE_DETAIL: '/BuyPoPageDetail',
        BUY_PO_ORDERS: '/Orders',
        TENANT_SETTINGS: '/TenantSettings',
        GLOBAL_TENANT_SETTINGS: '/GlobalTenantSetting',
        FEES: '/Fees',
        FEES_V2: '/FeesV2',
        ATTACHMENTS: '/Attachments',
        SHIP_PO: '/ShipPO',
        SHIPMENTS: '/Shipments',
        SHIP_PO_EXCEL_REPORT: '/ShipPoExcelReport',
        SHIP_PO_PAGE_DETAIL: '/ShipPoPageDetail',
        DELETE: '/Delete',
        DELETE_V2: '/DeleteV2',
        DELETE_MULTIPLE: '/DeleteMultiple',
        FEED_LOT_MANAGER: '/fm',
        RECEIVE_CATTLE: '/ReceiveCattle',
        BY_LOT: '/ByLot',
        LOT_RATION_HISTORY: '/LotRationHistory',
        LOT_PEN_RATION_HISTORY: '/LotPenRationHistory',
        LOTS: '/Lots',
        GET_LIST_OF_SNAPSHOTS: '/GetListOfSnapshots',
        POWERBI_REPORTS: '/Reports',
        REPORTS: '/Reports',
        LIST: '/List',
        PENS: '/Pens',
        FEED_TRUCK: '/FeedTruck',
        YARDAGE: '/YardageVersion',
        LOADER: '/Loader',
        RATION: '/Ration',
        VERSION: '/Version',
        REPORT: '/Report',
        FC_CHANGE_REPORT: '/changeReport',
        FEED: '/Feed',
        PEN_RATION_HISTORY: '/PenRationHistory',
        MIXER: '/Mixer',
        RIDER: '/Rider',
        FM_LOCATION: '/FmSourceLocation',
        FM_DESTINATION: '/FmDestinationLocation',
        FEED_ENTRIES: '/FeedEntries',
        FEEDING_ENTRIES: '/FeedingEntries',
        BUYERS: '/Buyers',
        CATTLE_TYPES: '/CattleType',
        CATTLE_TREATMENT: '/CattleTreatment',
        SHIP_CATTLE: '/ShipCattle',
        MOVE_CATTLE: '/MoveCattle',
        SOURCE: '/Source',
        SNAPSHOT: '/Snapshot',
        SUBSCRIPTION_CHECKOUT: 'fm/Subscription/GenerateCheckout',
        SUBSCRIPTION_PORTAL: 'fm/Subscription/PortalSession',
        VERIFY_SUBSCRIPTION: 'fm/UserManagement/VerifySubscription',
        SIGN_UP: 'fm/UserManagement/SignUp',
        FM_FEEDLOT: '/FMFeedlot',
        HEADCOUNTS: '/headcounts',
        MOVEMENT_HISTORY: '/MovementHistory',
        GENERAL_SUMMARY: '/GeneralSummary',
        PEN_MOVEMENT: '/PenMovement',
        INVOICE_PREVIEW: '/InvoicePreview',
        INVOICE_PERIOD_PREVIEW: '/InvoicePeriodPreview',
        DESTINATION: '/Destination',
        SUMMARY: '/Summary',
        MEDICAL_INVENTORY: '/MedicalInventory',
        MEDICAL_ITEM: '/MedicalItem',
        MEDICAL_ITEMS: '/MedicalItems',
        MEDICAL_ITEM_VERSION: '/MedicalItemVersion',
        MEDICAL_INVENTORY_ACTION: '/MedicalInventoryAction', //remove MEDICAL_INVENTORY_ACTION when removing ff isCA10959On_rtkQueryImprovements
        MEDICAL_INVENTORY_ACTIONS: '/MedicalInventoryActions',
        HISTORIC_MEDICAL_INVENTORY: '/History',
        GET_ID: '/GetId',
        PEN_RATION_TEMPLATES: '/PenRationScheduleTemplate',
        SCHEDULE_TYPE: '/ScheduleType',
        SPLIT_SCHEDULE: '/SplitSchedule',
        SPLIT_RATION: '/SplitRation',
        DIAGNOSIS: '/Diagnosis',
        TREATMENT_PROTOCOL: '/TreatmentProtocol',
        ODATA: '/odata',
        PROCESSING_PROTOCOL: '/ProcessingProtocol',
        PEN_RATION_SCHEDULE: '/PenRationSchedule',
        RATION_FEED_ORDER: '/RationFeedOrder',
        SCALE_TICKET: '/ScaleTicket',
        ASSIGN_CONTRACT: '/assign-contract',
        VENDOR: '/Vendor',
        VIEW: '/View',
        UNIT: '/Unit',
        COMMODITY: '/Commodity',
        COMMODITY_INVENTORY_ACTION: '/CommodityInventoryAction',
        HISTORIC_COMMODITY_INVENTORY: '/History',
        ADD_COMMODITY_INVENTORY: '/AddCommodityInventory',
        COMMODITY_COST: '/CommodityCost',
        COMMODITY_ADJUSTMENT_HISTORY: '/CommodityAdjustmentHistory',
        VERSIONS: '/Versions',
        CONTRACT: '/Contract',
        PROCESSING_WORKSET: '/ProcessingWorkset',
        EXPORT_TO_EXCEL: '/ExportToExcel',
        RE_IMPLANT_EXCEL_REPORT_O_DATA: '/ReImplantExcelReportOData',
        PROCESSING_WORKSHEET: '/Worksheet',
        PROCESSING_CHARGEOUT: '/Chargeout',
        YARD_LAYOUT: '/YardLayout',
        YARD_SHEET: '/YardSheet',
        YARD_SHEET_REPORT: '/YardSheetReport',
        TECH: '/Tech',
        FEEDROUTE: '/FeedRoute',
        RATIONFEEDORDER: '/RationFeedOrder',
        FEEDCALLROUTE: '/FeedCallRoute',
        BYRATION: '/ByRation',
        HOTLIST: '/hotList',
        FEED_TASK: '/FeedTask',
        FEED_STATUS: '/FeedStatus',
        COMMODITY_VARIANCE_REPORT: '/CommodityVarianceReport',
        TREATMENT: '/treatment',
        MANUAL_FEED_ENTRY: '/ManualFeedEntry',
        COMMODITY_INVENTORY: '/CommodityInventoryAction',
        COMMODITY_INVENTORY_REPORT: '/GetCommodityInventoryReport',
        YARD_DETAIL: '/yard-detail',
        REALIZER_SHIPMENT: '/RealizerShipment',
        CATTLE_TREATMENT_REASON: '/CattleTreatmentReason',
        MANUAL_FEEDING_SHEET_EXCEL: '/ManualFeedingSheetExcel',
        MANUAL_FEEDING_SHEET_PDF: '/ManualFeedingSheetPdf',
        RATION_CALCULATOR_PDF: '/calculatorPdf',
        COMMODITY_USAGE_REPORT: '/CommodityUsageReport',
        LIFE_TO_DATE: '/lifetime',
        AVERAGE_FED: '/AverageFed',
        RATION_VERSIONS: '/RationVersions',
        EXTEND_RATION_BY_GIVEN_DAY: '/ExtendRationChangeByGivenDay',
        WORKSHEETS: '/Worksheets',
        AUDITS: '/Audits',
        TANKS: '/Tanks',
        EIDS: '/eids',
        EXCEL: '/excel',
        USERS: '/tm/Users',
        ROLES: 'tm/Roles',
        ACCOUNTS: 'tm/Accounts',
        SCOPES: 'tm/Scopes',
        TENANTS: 'tm/Tenants',
    },
    Routes: {
        RECEIVE_CATTLE: '/receivecattle',
        CREATE_RECEIVE_CATTLE_FORM: '/createreceivecattle',
        UPDATE_RECEIVE_CATTLE_FORM: '/updatereceivecattle',
        UPDATE_RECEIVE_CATTLE_FORM_WITH_PARAM: '/updatereceivecattle/:id',
        SHIP_CATTLE: '/shipcattle',
        BILLING_CATTLE: '/billing',
        BILLING_FINAL_INVOICE: '/billing/billingFinalInvoicePreview',
        CREATE_SHIP_CATTLE: '/createshipcattle',
        UPDATE_SHIP_CATTLE: '/updateshipcattle',
        MOVE_CATTLE: '/movecattle',
        SHOW_MOVE_CATTLE: '/movecattle/:id',
        PEN_RATION_SCHEDULE_TEMPLATE: '/penrationscheduletemplates',
        CREATE_PEN_RATION_TEMPLATE: '/createpenrationscheduletemplate',
        UPDATE_PEN_RATION_TEMPLATES: '/updatepenrationscheduletemplate',
        LOT_SUMMARY_DETAIL: '/lotsummary/lotsummarydetail',
        MEDICAL_INVENTORY: '/medicalinventory',
        ADD_MEDICAL_INVENTORY: '/medicalinventory/addmedicalinventory',
        LOT_SUMMARY: '/lotsummary',
        RATION: '/rations',
        CREATE_RATION: '/createration',
        UPDATE_RATION: '/updateration',
        PEN_RATION_SCHEDULE: '/penrationschedule',
        FEEDCALL: '/feedcall',
        REFUND_CANCELLATION_POLICY: '/refundcancellationpolicy',
        NEWFEEDCALL: '/feedcall',
        NEWFEEDCALLV2: '/feedcallv2',
        FEED_CALL_ROUTE: '/feedcallroute',
        HOTLIST: '/hotlist',
        MORTALITY_REPORT: '/mortalityreport',
        CREATE_MORTALITY_REPORT: '/mortalityreport/createmortalityreport',
        UPDATE_MORTALITY_REPORT: '/mortalityreport/updatemortalityreport',
        LOADED_COMMODITIES_VARIANCE_REPORT:
            '/feedstatus/loadedCommoditiesVarianceReport',
        PEN_RATION_SCHEDULE_TEMPLATE_LIST: '/penrationscheduletemplates/',
        MANAGE_COMMODITIES: '/managecommodities',
        UPDATE_CONTRACT: '/managecommodities/updatecontract',
        ADD_ADJUSTMENT: '/addadjustment',
        SCALE_TICKET: '/scaletickets',
        CREATE_INBOUND_SCALE_TICKETS: '/scaletickets/createinboundscaletickets',
        CREATE_OUTGOING_SCALE_TICKETS:
            '/scaletickets/createoutgoingscaletickets',
        UPDATE_INBOUND_SCALE_TICKETS: '/scaletickets/updateinboundscaletickets',
        UPDATE_OUTGOING_SCALE_TICKETS:
            '/scaletickets/updateoutgoingscaletickets',
        HEDGES: '/hedges',
        EDITHEDGE: '/edithedge/:hedgeId',
        BUY_POS: '/buypos',
        BUY_PO: '/buypo/',
        UPDATE_BUY_PO: '/updatebuypo/',
        CREATE_BUY_PO: '/buypos/createbuypo',
        BUYPOID: '/buypos/updatebuypo/:buyPOId',
        BUYPOID_OLD: '/buypo/:buyPOId', //remove with BP903_UPDATE_ROUTE_FOR_BREADCRUMBS flag
        SHIP_PO: '/shippo/',
        SHIPPO: '/shippos/',
        CREATE_SHIP_PO: '/shippos/createshippo',
        UPDATE_SHIP_PO: 'updateshippo/',
        SHIPPOID: '/shippos/updateshippo/:shipPOId',
        SHIPPOID_OLD: '/shippo/:shipPOId', //remove with BP903_UPDATE_ROUTE_FOR_BREADCRUMBS flag
        TRUCKINGCOMPANY: '/truckingcompany/*',
        CATTLEPO_CALENDAR: '/calendar',
        CATTLE_PO_SETTINGS: '/tenantsettings',
        KILL_GROUP_SETTINGS: '/killgroupsettings',
        BUY_LOCATION_SETTINGS: '/buylocationsettings',
        TRUCKING_COMPANY_SETTINGS: '/truckingcompanysettings',
        DESTINATION_SETTINGS: '/destinationsettings',
        COLOR_SETTINGS: '/colorsettings',
        SEX_SETTINGS: '/sexsettings',
        BREED_SETTINGS: '/breedsettings',
        FM_DASHBOARD: '/fmdashboard',
        USER_MANAGEMENT: '/usermanagement',
        PENS: '/pens',
        TRUCKS: '/trucks',
        LOADERS: '/loaders',
        YARDAGE: '/yardage',
        RIDERS: '/riders',
        VENDORS: '/vendors',
        MIXING_BINS: '/mixingbins',
        LOCATIONS: '/locations',
        DESTINATIONS: '/destinations',
        TRUCKING_COMPANY: '/fmtruckingcompany',
        BUYERS: '/buyers',
        OWNERS: '/owners',
        TECHS: '/techs',
        TANKS: '/tanks',
        CREATE_PEN: '/pens/createnewpens',
        USER_AUTH: '/auth',
        FORGOT_PASSWORD: '/forgotpassword',
        RESET_TOKEN: '/reset-token',
        LOTSUMMARY: '/lotsummary/*',
        LOTSUMMARY_DETAIL: '/lotsummary/lotsummarydetail/:lotId',
        LOTSUMMARY_DETAIL_INVOICE_PREVIEW:
            '/lotsummary/lotSummaryDetailsInvoicePreview',
        LOT_SUMMARY_DETAIL_INVOICE_PREVIEW:
            '/lotsummary/lotSummaryDetailsInvoicePreview/:lotId',
        HEALTH_PROTOCOLS: '/healthprotocols',
        PROCESSING_PROTOCOL_PAGE: '/healthprotocols/addprocessingprotocol',
        ADD_TREATMENT_PROTOCOL: '/healthprotocols/addtreatmentprotocol',
        EDIT_PEN_RATION_TEMPLATES: '/createpenrationscheduletemplate',
        MEDICAL_ITEM_LIST: '/medicalitemlist',
        ADD_INVENTORY_ADJUSTMENT: '/medicalinventory/addinventoryadjustment',
        FEED_STATUS: '/feedstatus',
        MANUAL_FEED_ENTRY: '/manualfeedentry',
        RATION_FEED_ORDER: '/rationfeedorder',
        FEED_ROUTE_CREATION: '/rationfeedorder/feedroutecreation',
        REPORTS: '/reports',
        TREATMENTS: '/treatments',
        HOT_LIST: '/hotlist',
        ADD_TREATMENT: '/treatments/addtreatment',
        UPDATE_TREATMENT: '/treatments/updatetreatment',
        PROCESSING_LOT_PAGE: '/lotprocessing',
        PROCESSING_WORKSHEET: '/processingworksheet',
        PROCESSING_WORKSHEET_WITH_PARAM: '/processingworksheet/:lotId',
        REPROCESSING_WORKSHEET: '/reprocessingworksheet',
        REPROCESSING_WORKSHEET_WITH_PARAM: '/reprocessingworksheet/:lotId',
        PROCESSING_CHARGEOUT: '/processingchargeout',
        PROCESSING_CHARGEOUT_WITH_PARAM: '/processingchargeout/:lotId',
        REPROCESSING_CHARGEOUT: '/reprocessingchargeout',
        REPROCESSING_CHARGEOUT_WITH_PARAM: '/reprocessingchargeout/:lotId',
        PRODUCTS: '/products',
        UPDATE_MEDICAL_ITEM: '/updatemedicalitem/:medicalItemId',
        YARD_LAYOUT: '/yardlayout',
        COMMODITY_INVENTORY_REPORT: '/commodityinventoryreport',
        REALIZER_HISTORY: '/realizerhistory',
        UPDATE_SHIP_CATTLE_REALIZER: '/updaterealizer',
        SIGN_UP: '/signup',
        SIGN_UP_WITH_PARAM: '/signup/:productFamilyFromUrl',
        HOSPITAL: '/hospital',
    },
    Storage: {
        LOCAL: 'Local',
        SESSION: 'Session',
    },
    Languages: [
        {
            value: 'en',
            name: 'English',
        },
        {
            value: 'es',
            name: 'Spanish',
        },
    ],
    message: {
        type: {
            INFO: 'info',
            SUCCESS: 'success',
            ERROR: 'error',
        },

        commonLog: {
            RECORD_UPDATED_SUCCESS: 'Record updated successfully.',
            RECORD_DELETED_SUCCESS: 'Record deleted successfully.',
            ERROR_400: 'Bad Request.',
            ERROR_401: 'User is not authorized.',
            DUPLICATE: 'Duplicate entries not allowed.',
            VALIDATION: 'Invalid entry.',
            NO_RECORDS_FOUND: 'No records were found.',
            INTERNAL_SERVER_ERROR: 'Internal Server Error.',
            RECORD_NOT_EXIST: 'Record does not exist.',
            UNAUTHORIZE: 'Unauthorized Access.',
            CONFLICT: 'Database conflict. Please retry.',
            NULL_OBJECT: 'Please check all fields and re-submit.',
        },
    },
    listLimit: {
        BUYPO: 20,
        TRUCKING_COMPANY_VIEW: 20,
        SHIP_CATTLE_LIST: 20,
        MOVE_CATTLE_LIST: 20,
        MEDICAL_SECTION: 20,
    },
    customMessage: {
        BAD_REQUEST:
            'There was an issue posting your records, please try again later.',
        LOCATION_DUPLICATE: 'Cannot enter duplicate location name.',
        DESTINATION_DUPLICATE: 'Cannot enter duplicate destination name.',
        OWNER_DUPLICATE: 'Cannot enter duplicate owner name.',
        VENDOR_DUPLICATE: 'Cannot enter duplicate vendor name.',
        COLOR_DUPLICATE: 'Cannot enter duplicate color name.',
        LOADER_DUPLICATE: 'Cannot enter duplicate loader name.',
        SEX_DUPLICATE: 'Cannot enter duplicate sex name.',
        BREED_DUPLICATE: 'Cannot enter duplicate breed name.',
        SLIDE_UNIT_DUPLICATE: 'Cannot enter duplicate slide unit name.',
        TRUCKING_COMPANY_DUPLICATE: 'Cannot enter duplicate trucking company.',
        LOT_DUPLICATE: 'Cannot enter duplicate lot name.',
        MIXING_BIN_DUPLICATE: 'Cannot enter duplicate mixing bin name.',
        PEN_DUPLICATE: 'Cannot enter duplicate pen name.',
        TRUCK_DUPLICATE: 'Cannot enter duplicate truck name.',
        BUYER_DUPLICATE: 'Cannot enter duplicate buyer.',
        PAYEE_DUPLICATE: 'Cannot enter duplicate payee.',
        TECH_DUPLICATE: 'Cannot enter duplicate tech.',
        TANK_DUPLICATE: 'Cannot enter duplicate tank.',
        RIDER_DUPLICATE: 'Cannot enter duplicate rider.',
        KILL_GROUP_DUPLICATE: 'Cannot enter duplicate kill group.',
        MEDICAL_ITEM: 'Cannot enter duplicate medical item.',
        BOTTLE_SIZE: 'Cannot enter duplicate bottle size.',
        TREATMENT_PROTOCOL_DUPLICATE:
            'Cannot enter duplicate treatment protocol.',
        PROCESSING_PROTOCOL_DUPLICATE:
            'Cannot enter duplicate processing protocol.',
        NO_RECORDS_FOUND: 'No Records Found.',
        RECORD_SUBMIT_SUCCESS: 'Record successfully submitted.',
        ZERO_VALUE_NOT_ALLOWED: 'Price must be greater than zero.',
        relotPageDateConflict:
            'Unable to process request due to a data conflict',
    },

    responseCode: {
        SUCCESS: 200,
        SUCCESSFUL_POST: 201,
        NO_CONTENT: 204,
        BAD_REQUEST: 400,
        UNAUTHORIZED: 401,
        NULL_OBJECT: 700,
        VALIDATION: 701,
        DUPLICATE: 702,
        NOT_FOUND: 404,
        INTERNAL_SERVER_ERROR: 500,
        MULTIPLE_CHOICES: 300,
        CONFLICT: 409,
    },
    GRID_VIEW_MEDIUM_SIZE: 768,
    APP_VERSION: `v${process.env.REACT_APP_VERSION || '0.0.0.0'}`,
    INPUT_TYPES: {
        DROPDOWN: 'dropdown',
        NUMBER: 'number',
        STRING: 'string',
        TEXT: 'text',
        EMAIL: 'email',
        FLOAT: 'float',
        COORDINATES: 'coordinates',
        MOBILE_LENGTH: 'mobileLength',
        ZIPCODE: 'zipcode',
        MOBILE: 'mobile',
        DATE: 'date',
        TOOLTIP: 'tooltip',
        EDIT: 'edit',
        RESET_PASSWORD: 'resetPassword',
    },
    headerConstants: {
        ownerreport: 'Owner Report',
        feedcall: 'Feed Call',
        newfeedcall: 'Feed Call',
        feedcallv2: 'Feed Call 2.0',
        mortalityreport: 'Mortality Report',
        createcattledead: 'Create Cattle Dead',
        relot: 'ReLot',
        lotcashflow: 'Lot Cash Flow',
        updatemortalityreport: 'Update Mortality Report',
        billing: 'Billing',
        billingFinalInvoicePreview: 'Final Invoice Preview',
        createmortalityreport: 'Create Mortality Report',
        shipcattle: 'Ship Cattle',
        lenderreport: 'Lender Report',
        createowner: 'Create Owner',
        createfeedbill: 'FeedBill',
        createshipcattle: 'Create Ship Cattle Form',
        updateshipcattle: 'Update Ship Cattle Form',
        receivecattle: 'Receive Cattle',
        createcattlepurchase: 'Cattle Purchase',
        createcattlesale: 'Cattle Sale',
        customercloseout: 'Customer Closeout',
        createrelot: 'ReLot',
        updatereceivecattle: 'Update Receiving Form',
        createreceivecattle: 'Create Receiving Form',
        editPenRationTemplate: 'Create Pen Ration Template',
        penrationtemplates: 'Pen Ration Template',
        editcattlepurchase: 'Edit Cattle Purchase',
        editcattlesale: 'Edit Cattle Sale',
        products: 'Subscriptions',
        editcattledead: 'Edit Cattle Dead',
        editReLot: 'Edit ReLot',
        edithedge: 'Edit Hedge',
        rations: 'Rations',
        createration: 'Create Ration',
        penrationschedule: 'Pen Ration Schedule',
        lotsummary: 'Lot Summary',
        projectedbreakevenreport: 'Projected Breakeven Report',
        shawnaSpreadsheet: 'Lot Cash Flow',
        lotsummarydetail: 'Lot Summary Detail',
        lotSummaryDetailsInvoicePreview: 'Lot Summary Details Invoice Preview',
        movecattle: 'Move Cattle Form',
        medicalinventory: 'Medical Inventory',
        medicalitemlist: 'Medical Items',
        updatemedicalitem: 'Update a Medical Item',
        addmedicalinventory: 'Add Medical Inventory',
        lenderReport: 'Lender Report',
        customers: 'Customers',
        feedlots: 'Feedlots',
        feedlot: 'Feedlots',
        lots: 'Lots',
        lot: 'Lot',
        pens: 'Pens',
        trucks: 'Trucks',
        loaders: 'Loaders',
        vendors: 'Vendors',
        yardage: 'Yardage',
        tanks: 'Tanks',
        riders: 'Riders',
        fmtruckingcompany: 'Trucking Company',
        mixingbins: 'Mixing Bins',
        buyers: 'Buyers',
        techs: 'Techs',
        owners: 'Owners',
        locations: 'Buy Location',
        destinations: 'Destination',
        createnewpens: 'Create New Pens',
        lotbalancesreport: 'Lot Balances Report',
        quarterlyreport: 'Quarterly Report',
        lotsummaryreport: 'Lot Summary Report',
        projectBreakevenReport: 'Projected Breakeven Report',
        ownerMonthlyReport: 'Owner Monthly Report',
        feedbills: 'Feed Bills',
        feedbill: 'Feed Bills',
        editfeedbill: 'Edit FeedBill',
        createcattleweight: 'Cattle Weight',
        hedges: 'Hedges',
        createopenhedge: 'Open Hedge',
        createclosehedge: 'Close Hedge',
        statements: 'Statements',
        interestratechanges: 'Interest Rate Changes',
        cattlefaxes: 'Cattle Faxes',
        settings: 'Settings',
        killgroupsettings: 'Kill Group Settings',
        sexsettings: 'Sex Settings',
        buylocationsettings: 'Buy Location Settings',
        destinationsettings: 'Destination Settings',
        truckingcompanysettings: 'Trucking Company Settings',
        colorsettings: 'Color Settings',
        breedsettings: 'Breed Settings',
        buypos: 'BuyPOs',
        calendar: 'Calendar',
        createbuypo: 'Create BuyPO',
        shippos: 'ShipPOs',
        createshippo: 'Create Ship PO',
        buypo: 'BuyPO',
        updatebuypo: 'Update Buy PO',
        updateshippo: 'Update Ship PO',
        shippo: 'Update Ship PO',
        truckingcompany: 'Trucking Company',
        rationfeedorder: 'Ration Feed Order',
        feedroutecreation: 'Feed Route Creation',
        updateration: 'Update Ration',
        feedstatus: 'Feed Status',
        manualfeedentry: 'Manual Feed Entry',
        reports: 'Reports',
        registercustomer: 'Register Customer',
        createfeedlot: 'Create Feedlot',
        createlot: 'Create Lot',
        newFeedBill: 'New Feed Bill',
        cattlesale: 'Cattle Sale',
        feedcallroute: 'Feed Call Route',
        penrationscheduletemplates: 'Ration Schedule Templates',
        createpenrationscheduletemplate: 'Create Ration Schedule Template',
        updatepenrationscheduletemplate: 'Update Ration Schedule Template',
        managecommodities: 'Manage Commodities',
        updatecontract: 'Update Contract',
        addadjustment: 'Add Adjustment',
        scaletickets: 'Scale Tickets',
        createinboundscaletickets: 'Create Inbound Load Scale Ticket',
        updateinboundscaletickets: 'Update Inbound Load Scale Ticket',
        createoutgoingscaletickets: 'Create Outgoing Load Scale Ticket',
        updateoutgoingscaletickets: 'Update Outgoing Load Scale Ticket',
        healthprotocols: 'Health Protocols',
        lotprocessing: 'Processing',
        processingworksheet: 'Processing Worksheet',
        reprocessingworksheet: 'Reprocessing Worksheet',
        processingchargeout: 'Processing Chargeout',
        reprocessingchargeout: 'Reprocessing Chargeout',
        addtreatmentprotocol: 'Add Treatment Protocol',
        addprocessingprotocol: 'Add Processing Protocol',
        addinventoryadjustment: 'Add Inventory Adjustment',
        loadedCommoditiesVarianceReport: 'Loaded Commodities Variance Report',
        treatments: 'Treatments',
        hotlist: 'Hot List',
        addtreatment: 'Add Treatment',
        updatetreatment: 'Update Treatment',
        yardlayout: 'Yard Layout',
        commodityinventoryreport: 'Commodity Inventory Report',
        realizerhistory: 'Realizer Shipment Form',
        updaterealizer: 'Update Realizer Shipment Form',
        hospital: 'Hospital',
        usermanagement: 'User Management',
    },
    financeLotActionDropdown: {
        lotBalancesReport: 'Lot Balances Report',
        quarterlyReport: 'Quarterly Report',
        lotSummaryReport: 'Lot Summary Report',
        lenderReport: 'Lender Report',
        projectBreakevenReport: 'Project Breakeven Report',
        shawnaSpreadsheet: 'Lot Cash Flow',
        ownerMonthlyReport: 'Owner Monthly report',
    },
    showOwnerDropdown: isFA499AddOwnerDropdownOnOwnerReportPage
        ? ['Feed Bills', 'Lots', 'Lot Cash Flow', 'Owner Report']
        : ['Feed Bills', 'Lots', 'Lot Cash Flow'],
    EVENTS: {
        ARROW_DOWN: 'ArrowDown',
        ARROW_UP: 'ArrowUp',
        ESCAPE: 'Escape',
        ENTER: 'Enter',
        TAB: 'Tab',
    },
    useElementOnScreen: {
        options: {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        },
    },
    exportCSVConstant: {
        fileType:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        fileExtension: '.xlsx',
    },
    exportExcelConstant: {
        fileType: 'application/vnd.ms-excel',
        fileExtension: '.xlsx',
    },
    button: {
        excelExport: 'Export XLSX',
        downloadReport: 'Download Report',
        yes: 'Yes',
        no: 'No',
        penHistory: 'Pen History',
        ok: 'Ok',
    },
    labels: {
        noContentFound: 'No Content Found',
    },
    cancellationModalTitle: 'Confirm Cancellation?',
    monthList: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ],
    refundCancellationPolicy:
        'Refund and Cancellation Policy:  No refunds provided.  Cancel anytime to take effect at the end of the current subscription period.',

    time: {
        idleTimeout: 7200000,
    },
    financeInputFieldSize: { intField: 16 },
};

export default Constants;
