import { currentDate_deprecated } from '../../helpers';
import { ITreatmentsInitialState } from '../../utils/Interface/TreatmentsInterface';

export const TREATMENTS_INITIAL_STATE: ITreatmentsInitialState = {
    loading: false,
    treatmentHistoryDetailLoading: false,
    protocolsAreLoading: false,
    canCallApi: true,
    filter: {
        $top: 20,
        $skip: 0,
        $orderBy: 'Date desc, CreatedDatetime desc',
        $count: true,
        lotName: '',
        ownerName: '',
        eid: '',
        animalTag: '',
        diagnosisName: '',
        treatmentProtocolName: '',
        riderName: '',
        treatmentsFilterFromDate: '',
        treatmentsFilterToDate: '',
    },
    treatments: [],
    completeTreatments: [],
    editTreatmentsDetails: {
        lotId: 0,
        lotName: '',
        animalId: 0,
        animalTag: '',
        eid: '',
        sex: '',
        homePenId: 0,
        currentPenId: 0,
        movePenId: 0,
        owner: '',
        diagnosisId: 0,
        severityId: 0,
        lotPenAverageWeight: '',
        currentWeight: '',
        useLotAverageWeight: false,
        temperature: '',
        cleanDate: '',
        cleanDateStatusInDays: 0,
        date: currentDate_deprecated('YYYY-MM-DD'),
        treatmentProtocolId: 0,
        techId: 0,
        riderId: 0,
        comments: '',
    },
    editTreatmentsDetailsError: {
        lotId: false,
        animalId: false,
        animalTag: false,
        sex: false,
        currentPenId: false,
        owner: false,
        diagnosisId: false,
        severityId: false,
        currentWeight: false,
        date: false,
        treatmentProtocolId: false,
        movePenId: false,
        techId: false,
    },
    originalTreatmentDetails: {
        lotId: 0,
        animalId: 0,
        eid: '',
        animalTag: '',
        sex: '',
        homePenId: 0,
        currentPenId: 0,
        movePenId: 0,
        owner: '',
        diagnosisId: 0,
        severityId: 0,
        lotPenAverageWeight: '',
        currentWeight: '',
        useLotAverageWeight: false,
        temperature: '',
        cleanDate: '',
        cleanDateStatusInDays: 0,
        date: currentDate_deprecated('YYYY-MM-DD'),
        treatmentProtocolId: 0,
        techId: 0,
        riderId: 0,
        comments: '',
    },
    historicalGiveRecords: [],
    giveRecord: {
        id: 0,
        name: '',
    },
    giveRecords: [],
    giveTreatmentProtocolRecords: [],
    chargeoutItemsCollection: [],
    isChargeoutCollectionLoading: true,
    generatedInvoiceCutoffDate: '',
    history: [],
    animalIds: [],
    lots: [],
    diagnosis: [],
    treatmentProtocols: [],
    movePens: [],
    hospitalPens: [],
    lotPens: [],
    techs: [],
    riders: [],
    severities: [],
    sex: [],
    medicalItems: [],
    insufficientInventory: [],
    submitPending: false,
};
