export const HospitalConstants = {
    en: {
        history: {
            adminDate: {
                label: 'Admin Date',
            },
            diagnosis: {
                label: 'Diagnosis',
            },
            protocol: {
                label: 'Protocol',
            },
            rider: {
                label: 'Rider',
            },
            adminHistory: {
                label: 'Admin History',
            },
        },
        accordionHeader: {
            hospitalPen: {
                label: 'Hospital Pen',
            },
            lot: {
                label: 'Lot',
            },
            animalId: {
                label: 'Animal ID',
            },
            homePen: {
                label: 'Home Pen',
            },
        },
        button: {
            createMortality: {
                label: 'Create Mortality',
            },
            move: {
                label: 'Move',
            },
        },
    },
};
