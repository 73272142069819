import { useState, useMemo, useEffect } from 'react';
import { TenantSettingsConstant } from '../../utils/constants/TenantSettingsConstant';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import StorageService from '../../Services/storage/storage.service';
import {
    checkModalItemAssociationNew,
    deleteItem,
    fetchLocations,
} from '../../Redux/Actions/tenantSettingsAction';
import BuyPoSettingsItemDetail from '../../apps/shippingTracker/modules/tenantSettings/BuypoSettingsItemDetail';
import {
    IBaseDetail,
    IInactiveBuyPoLocation,
} from '../../apps/shippingTracker/modules/BuyPo/interface/SettingsInterface';
import { useDebouncedEffect } from '../useDebounceEffect';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { activateLocation } from '../../apps/shippingTracker/modules/BuyPo/actions/buyPoActions';
import {
    IDeleteIds,
    ITenantSettingsFilter,
} from '../../utils/Interface/TenantSettingsInterface';
import { DeleteItemRowNew } from '../tenantSettings/DeleteItemRowNew';
import Constants from '../../utils/Constants';
import { FilterSearchBox } from '../../apps/shippingTracker/modules/tenantSettings/FilterSearchBox';
import LocationModal from '../../apps/shippingTracker/modules/common/LocationModal';
import { IEditLocationDetail } from '../../apps/shippingTracker/modules/BuyPo/interface/BuyPoInterface';

const BuyLocationSettingsNew = () => {
    const { filter, loading } = useAppSelector(state => state.tenantSetting);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedBuyLocation, setSelectedBuyLocation] =
        useState<IInactiveBuyPoLocation>();
    const {
        isOpen: isEditModalOpen,
        open: openEditModal,
        close: closeEditModal,
    } = useModalStatus();
    const {
        isOpen: isDeleteModalOpen,
        open: openDeleteModal,
        close: closeDeleteModal,
    } = useModalStatus();
    const [buyLocation, setBuyLocation] = useState<IInactiveBuyPoLocation[]>(
        [],
    );
    const dispatch = useAppDispatch();
    const storage = useMemo(() => new StorageService(), []);

    const handleEditBuyLocationDetail = (item: IBaseDetail): void => {
        const buyLocation = item as IInactiveBuyPoLocation;
        setSelectedBuyLocation(buyLocation);
        openEditModal();
    };

    const setTenantSettingsFilterInStorage = (
        filterValues: ITenantSettingsFilter,
    ) => {
        storage.setValue(
            TenantSettingsConstant.filter.tenantSettingsFilter,
            filterValues,
            Constants.Storage.LOCAL,
        );
    };

    const handleFilter = (name: string, value: string): void => {
        const updatedFilter = { ...filter };
        updatedFilter.buyLocationFilter[name] = value;
        dispatch(
            fetchLocations(setBuyLocation, updatedFilter.buyLocationFilter),
        );
        setTenantSettingsFilterInStorage(updatedFilter);
    };

    const handleChange = async (index: number) => {
        const BuyLocationList = [...buyLocation];
        const updatedBuyLocationList = {
            ...BuyLocationList[index],
            isActive: !BuyLocationList[index].isActive,
        };
        await dispatch(activateLocation(updatedBuyLocationList));
        dispatch(fetchLocations(setBuyLocation, filter.buyLocationFilter));
    };

    const handleItemUpdateFromModal = (updatedItem: IEditLocationDetail) => {
        let updatedIndex = buyLocation.findIndex(
            item => item.id === updatedItem.id,
        );

        if (updatedIndex !== -1) {
            setBuyLocation(prevItems => {
                const updatedItemsList = [...prevItems];
                const { addressInfo, ...itemDetail } = updatedItem;
                updatedItemsList[updatedIndex] = {
                    ...prevItems[updatedIndex],
                    ...itemDetail,
                    ...addressInfo,
                };
                return updatedItemsList;
            });
        }
    };

    const closeModalHandler = () => {
        closeEditModal();
    };

    const handleDeleteModalHandler = async (item: IBaseDetail) => {
        const buyLocation = item as IInactiveBuyPoLocation;
        if (buyLocation.id) {
            const isAssociated = await dispatch(
                checkModalItemAssociationNew(
                    Number(buyLocation.id),
                    TenantSettingsConstant.filter.buyLocation.filterType,
                ),
            );
            let updatedBuyLocation = {
                ...buyLocation,
                isAssociated: isAssociated,
            };
            setSelectedBuyLocation(updatedBuyLocation);
            openDeleteModal();
        }
    };

    const handleDeleteBuyLocation = async (shouldTakeAction: boolean) => {
        if (shouldTakeAction && selectedBuyLocation) {
            const isActiveAndAssociated =
                selectedBuyLocation.isAssociated &&
                selectedBuyLocation.isActive;
            if (isActiveAndAssociated) {
                const updatedBuyLocationList = {
                    ...selectedBuyLocation,
                    isActive: !selectedBuyLocation.isActive,
                };
                await dispatch(activateLocation(updatedBuyLocationList));
            } else {
                const deleteIds = [
                    { id: selectedBuyLocation.id },
                ] as IDeleteIds[];
                await dispatch(
                    deleteItem(
                        deleteIds,
                        TenantSettingsConstant.filter.buyLocation.filterType,
                    ),
                );
            }
            dispatch(fetchLocations(setBuyLocation, filter.buyLocationFilter));
        }
        closeDeleteModal();
    };

    useDebouncedEffect(
        () => {
            if (searchQuery !== undefined) {
                handleFilter('name', searchQuery);
            }
        },
        [searchQuery],
        1000,
    );

    useEffect(() => {
        dispatch(fetchLocations(setBuyLocation, filter.buyLocationFilter));
    }, [dispatch, storage]);

    return (
        <div className="settings-container">
            <div className="my-3">
                <h5>{TenantSettingsConstant.filter.buyLocation.heading}</h5>
                <FilterSearchBox
                    filterType={
                        TenantSettingsConstant.filter.buyLocation.filterType
                    }
                    handleFilter={handleFilter}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                ></FilterSearchBox>
                <BuyPoSettingsItemDetail<IBaseDetail>
                    columnDetail={
                        TenantSettingsConstant.filter.buyLocation.columnDetail
                    }
                    itemDetail={buyLocation}
                    handleChange={handleChange}
                    handleEditDetail={handleEditBuyLocationDetail}
                    handleDeleteModalHandler={handleDeleteModalHandler}
                    loader={loading}
                    filterType={
                        TenantSettingsConstant.filter.buyLocation.filterType
                    }
                ></BuyPoSettingsItemDetail>
                {isEditModalOpen && selectedBuyLocation && (
                    <LocationModal
                        editLocationDetails={selectedBuyLocation}
                        showModal={isEditModalOpen}
                        closeModalHandler={closeModalHandler}
                        onUpdate={handleItemUpdateFromModal}
                    />
                )}
            </div>
            {isDeleteModalOpen && selectedBuyLocation && (
                <DeleteItemRowNew
                    item={selectedBuyLocation}
                    handleDeleteItem={handleDeleteBuyLocation}
                    showModal={isDeleteModalOpen}
                    itemName={TenantSettingsConstant.filter.buyLocation.label}
                ></DeleteItemRowNew>
            )}
        </div>
    );
};

export default BuyLocationSettingsNew;
