import { Col, Spinner } from 'react-bootstrap';
import {
    IBaseDetail,
    IBuyPoSettingsItemDetail,
} from '../BuyPo/interface/SettingsInterface';
import { CommonConstants } from '../../../../utils/constants/CommonConstants';
import { ItemRow } from './ItemRow';

const BuyPoSettingsItemDetail = <T extends IBaseDetail>({
    columnDetail,
    itemDetail,
    handleChange,
    handleEditDetail,
    handleDeleteModalHandler,
    loader,
    filterType,
}: IBuyPoSettingsItemDetail<T>) => {
    return (
        <>
            <Col className="d-flex align-items-center px-2 pt-3">
                {columnDetail.map(column => (
                    <Col xs={3} key={column.key}>
                        <h5>{column.label}</h5>
                    </Col>
                ))}
            </Col>
            <div className="item-container m-2">
                {loader && (
                    <div className="d-flex justify-content-center align-items-center">
                        <Spinner
                            animation="border"
                            data-size="lg"
                            className="text-center"
                        />
                    </div>
                )}
                {itemDetail.length === 0 && !loader && (
                    <Col>{CommonConstants.en.noDataFound}</Col>
                )}
                {itemDetail.map((item, index) => (
                    <ItemRow
                        key={`item-${index}`}
                        item={item}
                        filterType={filterType}
                        onChange={() => handleChange(index)}
                        onUpdate={() => handleEditDetail(item)}
                        onDelete={() => handleDeleteModalHandler(item)}
                    />
                ))}
            </div>
        </>
    );
};

export default BuyPoSettingsItemDetail;
