import { ICattlePOFeatures } from './interface';
import { getFeatureFlagValue } from '../../common/featureFlags/helpers';

export const cattlePO: ICattlePOFeatures = {
    isBP786On_orderSexDropdown: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP778_ORDER_SEX_DROPDOWN,
    ),
    isBP778On_orderBreedDropdown: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP778_ORDER_BREED_DROPDOWN,
    ),
    isBP787On_orderColorDropdown: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP787_ORDER_COLOR_DROPDOWN,
    ),
    isBP828On_inconsistentLogoBug: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP828_INCONSISTENT_LOGO_BUG,
    ),
    isBP804On_validateMandatoryFields: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP804_VALIDATE_MANDATORY_FIELDS,
    ),
    isBP796On_ccEmailSubmitter: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP796_CC_EMAIL_SUBMITTER,
    ),
    isBP799On_addCanadaDestination: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP799_ADD_CANADA_DESTINATION,
    ),
    isBP798On_addCanadaLocation: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP799_ADD_CANADA_LOCATION,
    ),
    isBP862On_termSlideUnit: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP862_TERM_SLIDE_UNIT,
    ),
    isBP860On_buyPOPickupDateRange: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP860_BUYPO_PICKUP_DATE_RANGE,
    ),
    isBP888On_truckingCompanyDelete: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP888_TRUCKING_COMPANY_DELETE,
    ),
    isBP899On_buyPOGetCombinedApi: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP899_BUYPO_GET_COMBINED_API,
    ),
    isBP878On_filterUniqueEachTab: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP878_FILTER_UNIQUE_EACH_TAB,
    ),
    isBP903On_updateRouteForBreadcrumbs: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP903_UPDATE_ROUTE_FOR_BREADCRUMBS,
    ),
    isBP909On_tooltipOnAttachments: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP909_TOOLTIP_ON_ATTACHMENTS,
    ),
    isBP900On_shipPOGetCombinedApi: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP900_SHIPPO_GET_COMBINED_API,
    ),
    isBP950On_OptimizedSettings: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP970_OPTIMIZED_SETTINGS,
    ),
    isBP971On_buyerPhoneNoNotRequired: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP971_BUYER_PHONE_NO_NOT_REQUIRED,
    ),
    isBP914On_tenantSettingColor: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP914_TENANT_SETTING_COLOR,
    ),
    isBP915On_tenantSettingSex: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP915_TENANT_SETTING_SEX,
    ),
    isBP913On_tenantSettingBreed: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP913_TENANT_SETTING_BREED,
    ),
    isBP961_buyPOSlideDetail: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP961_BUYPO_SLIDE_DETAIL,
    ),
    isBP984_tenantSettingDropdownVisibility: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP984_TENANT_SETTING_DROPDOWN_VISIBILITY,
    ),
};
