import { ActionTypes } from '.';
import { apiCall, apiCall_v2 } from '../../Services/AxiosService';
import Constants from '../../utils/Constants';
import { getToastContent } from '../../utils/ToastContent';
import { AppDispatch } from '../Store';
import {
    IFetchDestinations,
    IFetchLocations,
    ITenantSettingsFilter,
    IFilter,
    IFetchTruckingCompanies,
    IFetchKillGroup,
    IDeleteItemModal,
    IDeleteIds,
    IFetchColor,
    IFetchSex,
    IFetchBreed,
} from '../../utils/Interface/TenantSettingsInterface';
import {
    IInactiveBuyPoDestination,
    IInactiveBuyPoLocation,
} from '../../apps/shippingTracker/modules/BuyPo/interface/BuyPoInterface';
import { TenantSettingsConstant } from '../../utils/constants/TenantSettingsConstant';
import { IGenericSetState } from '../../utils/Interface/CommonInterface';
import { cattlePO } from '../../apps/shippingTracker/featureFlags/cattlePOFeatures';
import { toastMessage } from '../Reducer/common';
import { IvisibilitySetting } from '../../apps/shippingTracker/modules/BuyPo/interface/SettingsInterface';

const { isBP950On_OptimizedSettings } = cattlePO;
export const updateKillGroupVisibility =
    (enableKillGroup: boolean) => async (dispatch: AppDispatch) => {
        const response = await apiCall(
            'put',
            `${Constants.apiUrls.TENANT_SETTINGS}`,
            { enableKillGroup: enableKillGroup },
            true,
        );
        response.status = Number(response.status);
        dispatch(toastMessage(getToastContent(response.status)));
    };

export const fetchKillGroupVisibility = () => async (dispatch: AppDispatch) => {
    const { enableKillGroup } = await apiCall(
        'get',
        `${Constants.apiUrls.TENANT_SETTINGS}`,
    );
    dispatch(setKillGroupVisibility(enableKillGroup));
};

export const setKillGroupVisibility = (isVisible: boolean) => ({
    type: ActionTypes.SET_KILL_GROUP_VISIBILITY,
    payload: isVisible,
});

export const fetchSettingVisibility =
    (settingType: string) => async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.GLOBAL_TENANT_SETTINGS}?filter=SettingName eq '${settingType}'`,
        });
        if (response && response.length > 0) {
            dispatch(setSettingVisibility(settingType, response[0]));
        }
    };

export const updateSettingVisibility =
    (payload: IvisibilitySetting, settingType: string) =>
    async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.GLOBAL_TENANT_SETTINGS}/${payload.id}`,
            payload,
            isResRequired: true,
        });
        dispatch(setSettingVisibility(settingType, payload));
        response.status = Number(response.status);
        dispatch(toastMessage(getToastContent(response.status)));
    };

export const setSettingVisibility = (settingType, payload) => ({
    type: ActionTypes.FETCH_SETTING_VISIBILITY,
    settingType,
    payload,
});

const getFilterUrl = (filter: IFilter) => {
    const filterName = filter?.name ? encodeURIComponent(filter.name) : '';
    let filterUrl = `filter=startswith(Name, '${filterName}')`;
    if (filter?.show && filter?.show !== 'all') {
        const isActive = filter.show === 'active';
        filterUrl += ` and IsActive eq ${isActive}`;
    }
    return filterUrl;
};

export const fetchDestinations: IFetchDestinations =
    (setDestinations, filter) => async (dispatch: AppDispatch) => {
        let filterUrl = '';
        if (filter) {
            filterUrl = getFilterUrl(filter);
        }

        isBP950On_OptimizedSettings && dispatch(setTenantSettingsLoader(true));

        const buyDestinations = await apiCall(
            'get',
            `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BUYPO_DESTINATION}?include=AddressInfo&orderBy=Name&${filterUrl}`,
        );
        const modifiedDestinations = buyDestinations.map(
            ({ addressInfo, ...destinationDetail }) => ({
                ...destinationDetail,
                ...addressInfo,
            }),
        );
        setDestinations(modifiedDestinations);
        dispatch(setTenantSettingsLoader(false));
    };

export const fetchLocations: IFetchLocations =
    (setLocations, filter) => async (dispatch: AppDispatch) => {
        let filterUrl = '';
        if (filter) {
            filterUrl = getFilterUrl(filter);
        }

        isBP950On_OptimizedSettings && dispatch(setTenantSettingsLoader(true));

        const buyLocations = await apiCall(
            'get',
            `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BUYPO_BUY_LOCATION}?include=AddressInfo&orderBy=Name&${filterUrl}`,
        );
        const modifiedLocations = buyLocations.map(
            ({ id, name, isActive, addressInfo }) => ({
                id,
                name,
                isActive,
                address1: addressInfo.address1,
                address2: addressInfo.address2,
                city: addressInfo.city,
                state: addressInfo.state,
                country: addressInfo.country,
                postalCode: addressInfo.postalCode,
                coordinates: addressInfo.coordinates,
            }),
        );
        setLocations(modifiedLocations);
        dispatch(setTenantSettingsLoader(false));
    };

export const fetchTruckingCompanies: IFetchTruckingCompanies =
    (setTruckingCompany, filter) => async (dispatch: AppDispatch) => {
        let filterUrl = '';
        if (filter) {
            filterUrl = getFilterUrl(filter);
        }

        isBP950On_OptimizedSettings && dispatch(setTenantSettingsLoader(true));

        const truckingCompany = await apiCall(
            'get',
            `${Constants.apiUrls.TRUCKING_COMPANY}?orderBy=Name&${filterUrl}`,
        );
        setTruckingCompany(truckingCompany ?? []);
        dispatch(setTenantSettingsLoader(false));
    };

export const fetchKillGroup: IFetchKillGroup =
    (setKillGroup, filter) => async (dispatch: AppDispatch) => {
        let filterUrl = '';
        if (filter) {
            filterUrl = getFilterUrl(filter);
        }

        isBP950On_OptimizedSettings && dispatch(setTenantSettingsLoader(true));

        const killGroup = await apiCall(
            'get',
            `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.KILL_GROUP}?orderBy=Name&${filterUrl}`,
        );
        setKillGroup(killGroup ?? []);
        dispatch(setTenantSettingsLoader(false));
    };

export const fetchColors: IFetchColor =
    (setColor, filter) => async (dispatch: AppDispatch) => {
        let filterUrl = '';
        if (filter) {
            filterUrl = getFilterUrl(filter);
        }

        isBP950On_OptimizedSettings && dispatch(setTenantSettingsLoader(true));

        const colors = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.COLOR}?orderBy=Name&${filterUrl}`,
        });
        setColor(colors ?? []);
        dispatch(setTenantSettingsLoader(false));
    };

export const fetchBreeds: IFetchBreed =
    (setBreed, filter) => async (dispatch: AppDispatch) => {
        let filterUrl = '';
        if (filter) {
            filterUrl = getFilterUrl(filter);
        }

        isBP950On_OptimizedSettings && dispatch(setTenantSettingsLoader(true));

        const breeds = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BREED}?orderBy=Name&${filterUrl}`,
        });
        setBreed(breeds ?? []);
        dispatch(setTenantSettingsLoader(false));
    };

export const fetchSex: IFetchSex =
    (setSex, filter) => async (dispatch: AppDispatch) => {
        let filterUrl = '';
        if (filter) {
            filterUrl = getFilterUrl(filter);
        }

        isBP950On_OptimizedSettings && dispatch(setTenantSettingsLoader(true));

        const sex = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.SEX}?orderBy=Name&${filterUrl}`,
        });
        setSex(sex ?? []);
        dispatch(setTenantSettingsLoader(false));
    };

export const updateDestinations = async (
    destinations: IInactiveBuyPoDestination[],
) => {
    await apiCall(
        'put',
        `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BUYPO_DESTINATION}`,
        { destinations },
        true,
    );
};

export const updateBuyLocation = async (
    buyLocations: IInactiveBuyPoLocation[],
) => {
    await apiCall(
        'put',
        `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BUYPO_BUY_LOCATION}`,
        { buyLocations },
        true,
    );
};

const getFilterAndApi = (
    modalType: string,
    itemId: number | IDeleteIds[] | number,
) => {
    let filter = '';
    let apiUrl = '';

    switch (modalType) {
        case TenantSettingsConstant.filter.destination.filterType:
            filter = `?filter=DestinationId eq ${itemId}&limit=1&show=all`;
            apiUrl = `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BUYPO_DESTINATION}${Constants.apiUrls.DELETE_RANGE}`;
            break;
        case TenantSettingsConstant.filter.buyLocation.filterType:
            filter = `?filter=BuyLocationId eq ${itemId}&limit=1&show=all`;
            apiUrl = `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BUYPO_BUY_LOCATION}${Constants.apiUrls.DELETE_RANGE}`;
            break;
        case TenantSettingsConstant.filter.killGroup.filterType:
            filter = `?filter=KillGroupId eq ${itemId}&limit=1&show=all`;
            apiUrl = `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.KILL_GROUP}${Constants.apiUrls.DELETE_RANGE}`;
            break;
        case TenantSettingsConstant.filter.truckingCompany.filterType:
            filter = `?filter=TruckingCompanyId eq ${itemId}&limit=1&show=all`;
            apiUrl = `${Constants.apiUrls.TRUCKING_COMPANY}/${itemId}`;
            break;
        case TenantSettingsConstant.filter.color.filterType:
            filter = `?colorId=${itemId}&limit=1&show=all`;
            apiUrl = `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.COLOR}${Constants.apiUrls.DELETE_RANGE}`;
            break;
        case TenantSettingsConstant.filter.breed.filterType:
            filter = `?breedId=${itemId}&limit=1&show=all`;
            apiUrl = `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.BREED}${Constants.apiUrls.DELETE_RANGE}`;
            break;
        case TenantSettingsConstant.filter.sex.filterType:
            filter = `?sexId=${itemId}&limit=1&show=all`;
            apiUrl = `${Constants.apiUrls.BUY_PO_INFO}${Constants.apiUrls.SEX}${Constants.apiUrls.DELETE_RANGE}`;
            break;
        default:
            break;
    }

    return { filter, apiUrl };
};

export const checkModalItemAssociation =
    (
        itemId: number,
        isActive: boolean,
        modalType: string,
        setShowDeleteModal: IGenericSetState<IDeleteItemModal>,
        itemIndex: number,
    ) =>
    async () => {
        const { filter } = getFilterAndApi(modalType, itemId);
        if (!filter) return;

        let isAssociated = false;

        const buyPoInfoResponse = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.BUY_PO_INFO}${filter}`,
        });
        if (buyPoInfoResponse && buyPoInfoResponse.data.dataCount > 0) {
            isAssociated = true;
        }

        if (
            modalType ===
            TenantSettingsConstant.filter.truckingCompany.filterType
        ) {
            const shipPoResponse = await apiCall_v2({
                method: 'get',
                url: `${Constants.apiUrls.SHIP_PO}${filter}`,
            });
            if (shipPoResponse && shipPoResponse.data.dataCount > 0) {
                isAssociated = true;
            }
        }

        setShowDeleteModal({
            modalType: modalType,
            show: true,
            isAssociated: isAssociated,
            isDeactivated: !isActive,
            itemIndex,
        });
    };

export const checkModalItemAssociationNew =
    (itemId: number, modalType) => async () => {
        const { filter } = getFilterAndApi(modalType, itemId);
        if (!filter) return;

        let isAssociated = false;

        const buyPoInfoResponse = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.BUY_PO_INFO}${filter}`,
        });
        if (buyPoInfoResponse && buyPoInfoResponse.data.dataCount > 0) {
            isAssociated = true;
        }

        if (
            modalType ===
            TenantSettingsConstant.filter.truckingCompany.filterType
        ) {
            const shipPoResponse = await apiCall_v2({
                method: 'get',
                url: `${Constants.apiUrls.SHIP_PO}${filter}`,
            });
            if (shipPoResponse && shipPoResponse.data.dataCount > 0) {
                isAssociated = true;
            }
        }
        return isAssociated;
    };

export const deleteItem =
    (
        item: IDeleteIds[] | number,
        modalType: string,
        setShowDeleteModal?: IGenericSetState<IDeleteItemModal>,
    ) =>
    async () => {
        const { apiUrl } = getFilterAndApi(modalType, item);
        if (apiUrl) {
            if (
                modalType ===
                TenantSettingsConstant.filter.truckingCompany.filterType
            ) {
                await apiCall('delete', apiUrl, false);
            } else {
                const payload = item;
                await apiCall('post', apiUrl, payload, false);
            }
        }
    };

export const setTenantSettingsFilter = (filter: ITenantSettingsFilter) => {
    return {
        type: ActionTypes.SET_TENANT_SETTINGS_FILTER,
        payload: filter,
    };
};

export const appendTenantSettingsFilter = (filter: ITenantSettingsFilter) => {
    return {
        type: ActionTypes.APPEND_TENANT_SETTINGS_FILTER,
        payload: filter,
    };
};

export const setTenantSettingsLoader = (loading: boolean) => {
    return {
        type: ActionTypes.SET_TENANT_SETTINGS_LOADER,
        payload: loading,
    };
};
