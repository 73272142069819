import { useEffect, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { useAppDispatch } from '../../../../../Redux/Store';
import FullPageLoader from '../../../../../components/common/FullPageLoader';
import {
    IBuyPoKillGroupDetail,
    IFetchKillGroupResponse,
    IKillGroupDetail,
    IKillGroupError,
    IKillGroupModal,
} from '../interface/BuyPoInterface';
import { BuyPOGlobalInfoConstants } from '../constants/BuyPoConstants';
import {
    activateKillGroup,
    handleCreateNewKillGroup,
    fetchInactiveKillGroupInfo,
    handleEditKillGroup,
} from '../actions/buyPoActions';
import { useLoading } from '../../../../../commonHooks/useLoading';
import { useModalStatus } from '../../../../../commonHooks/useModalStatus';
import { ActivateItemModal } from './ActivateItemModal';

const CreateKillGroupModal = ({
    showModal,
    closeModalHandler,
    editKillGroupDetails,
    onUpdate,
}: IKillGroupModal) => {
    const [killGroupDetail, setKillGroupDetail] = useState<IKillGroupDetail>({
        ...BuyPOGlobalInfoConstants.initialState.killGroupDetail,
    });
    const [killGroupError, setKillGroupError] = useState<IKillGroupError>({
        ...BuyPOGlobalInfoConstants.initialState.killGroupError,
    });
    const [inActiveKillGroup, setInActiveKillGroup] =
        useState<IBuyPoKillGroupDetail>();
    const {
        isOpen: isActivateModalOpen,
        open: openActivateModal,
        close: closeActivateModal,
    } = useModalStatus();

    const { loading, setLoading } = useLoading();
    const dispatch = useAppDispatch();

    const setKillGroupErrorFields = (
        fieldName: string,
        value: boolean,
    ): void => {
        setKillGroupError(prevState => ({
            ...prevState,
            [fieldName]: value,
        }));
    };

    const setKillGroupDetailFields = (
        fieldName: string,
        fieldValue: string,
    ): void => {
        setKillGroupDetail(prevState => ({
            ...prevState,
            [fieldName]: fieldValue,
        }));
        if (killGroupError[fieldName]) {
            setKillGroupErrorFields(fieldName, false);
        }
    };

    const fetchKillGroupPayload = (): IKillGroupDetail => {
        return {
            ...killGroupDetail,
            name: killGroupDetail.name.trim(),
        };
    };

    const fetchKillGroupId = (
        response: IFetchKillGroupResponse,
    ): string | number => {
        return response.data ? response.data.data.id : '';
    };

    const handleCreateKillGroupResponse = (
        response: IFetchKillGroupResponse,
    ): void => {
        onUpdate?.(fetchEditKillGroupPayload());
        const killGroupId = fetchKillGroupId(response);
        closeModalHandler({
            isKillGroupCreated: true,
            killGroupId: killGroupId,
        });
    };

    const handleActivateKillGroup = async () => {
        const inactiveKillGroupInfo = await fetchInactiveKillGroupInfo(
            killGroupDetail.name.trim(),
        );
        setInActiveKillGroup(inactiveKillGroupInfo[0]);
        openActivateModal();
    };

    const validateKillGroupFields = (): boolean => {
        const killGroupDetailKeys = Object.keys(killGroupDetail);
        const killGroupErrorCopy = { ...killGroupError };
        let isError = false;

        killGroupDetailKeys.forEach(killGroupDetailKey => {
            if (
                !killGroupDetail[killGroupDetailKey] &&
                killGroupDetailKey !== BuyPOGlobalInfoConstants.isActive
            ) {
                killGroupErrorCopy[killGroupDetailKey] = true;
                isError = true;
            }
        });
        setKillGroupError(killGroupErrorCopy);
        return isError;
    };

    const fetchEditKillGroupPayload = () => ({
        name: killGroupDetail.name,
        killGroupId: editKillGroupDetails?.killGroupId,
        isActive: killGroupDetail.isActive,
    });

    const handleCreateKillGroup = (): void => {
        const isError = validateKillGroupFields();
        const killGroupPayload = fetchKillGroupPayload();

        if (!isError) {
            if (!editKillGroupDetails) {
                dispatch(
                    handleCreateNewKillGroup(
                        setLoading,
                        killGroupPayload,
                        handleCreateKillGroupResponse,
                        handleActivateKillGroup,
                    ),
                );
            } else {
                dispatch(
                    handleEditKillGroup(
                        setLoading,
                        handleCreateKillGroupResponse,
                        fetchEditKillGroupPayload,
                        handleActivateKillGroup,
                    ),
                );
            }
        }
    };

    useEffect(() => {
        setKillGroupDetail({
            ...BuyPOGlobalInfoConstants.initialState.killGroupDetail,
        });
        setKillGroupError({
            ...BuyPOGlobalInfoConstants.initialState.killGroupError,
        });
    }, [showModal]);

    const handleCloseActivateModal = async (
        isActive: boolean,
    ): Promise<void> => {
        if (isActive) {
            if (inActiveKillGroup) {
                const inActiveKillGroupCopy = {
                    ...inActiveKillGroup,
                    isActive: true,
                };
                await dispatch(activateKillGroup(inActiveKillGroupCopy));
                closeActivateModal();
                closeModalHandler({
                    isKillGroupCreated: true,
                    killGroupId: inActiveKillGroup.killGroupId,
                });
            }
        } else {
            closeActivateModal();
        }
    };

    useEffect(() => {
        if (
            editKillGroupDetails &&
            Object.keys(editKillGroupDetails).length > 0
        ) {
            setKillGroupDetail(editKillGroupDetails);
        }
    }, [editKillGroupDetails]);

    return (
        <>
            <ActivateItemModal
                showModal={isActivateModalOpen}
                closeModalHandler={handleCloseActivateModal}
                itemName={killGroupDetail.name.trim()}
                item={
                    BuyPOGlobalInfoConstants.form.killGroup
                        .activateModalConstant
                }
            />
            <Modal
                className="px-2 pt-5"
                size="lg"
                show={showModal}
                centered
                animation
                onHide={() => closeModalHandler({ isKillGroupCreated: false })}
            >
                {loading && <FullPageLoader />}
                <Modal.Header closeButton className="border-bottom-0">
                    <Modal.Title className="text-dark">
                        {!editKillGroupDetails
                            ? BuyPOGlobalInfoConstants.form.killGroup
                                  .headingLabel.create
                            : BuyPOGlobalInfoConstants.form.killGroup
                                  .headingLabel.update}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="border-bottom">
                        <div className="col-xl-6 col-lg-6 start-top mb-4">
                            <Form.Label className="text-secondary">
                                {
                                    BuyPOGlobalInfoConstants.dynamicFields
                                        .killGroupName.label
                                }
                                <span className="necessaryFields">*</span>
                            </Form.Label>
                            <Form.Control
                                value={killGroupDetail.name}
                                placeholder={
                                    BuyPOGlobalInfoConstants.dynamicFields
                                        .killGroupName.placeholder
                                }
                                onChange={e =>
                                    setKillGroupDetailFields(
                                        BuyPOGlobalInfoConstants.dynamicFields
                                            .killGroupName.key,
                                        e.target.value,
                                    )
                                }
                                maxLength={
                                    BuyPOGlobalInfoConstants.dynamicFields
                                        .killGroupName.maxLength
                                }
                                style={{
                                    borderColor: killGroupError[
                                        BuyPOGlobalInfoConstants.dynamicFields
                                            .killGroupName.key
                                    ]
                                        ? 'red'
                                        : '',
                                }}
                                className="text-secondary"
                                size="sm"
                                type="text"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-top-0">
                    <Button
                        variant="secondary"
                        className="secondary"
                        onClick={() =>
                            closeModalHandler({ isKillGroupCreated: false })
                        }
                    >
                        {BuyPOGlobalInfoConstants.cancel}
                    </Button>
                    <Button variant="secondary" onClick={handleCreateKillGroup}>
                        {!editKillGroupDetails
                            ? BuyPOGlobalInfoConstants.create
                            : BuyPOGlobalInfoConstants.update}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateKillGroupModal;
