import { useEffect, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { getToastContent } from '../../../../../utils/ToastContent';
import FullPageLoader from '../../../../../components/common/FullPageLoader';
import { validateAccordingType } from '../../../../../utils/validation';
import {
    BuyPOGlobalInfoConstants,
    BuyPoOrderConstants,
} from '../../BuyPo/constants/BuyPoConstants';
import { useAppDispatch } from '../../../../../Redux/Store';
import { CommonConstants } from '../../../../../utils/constants/CommonConstants';
import {
    ISexModal,
    IFetchSexResponse,
    IValueAndMaxLength,
    ISexDetail,
} from '../interface/BuyPoInterface';
import {
    activateSex,
    createNewSex,
    fetchInactiveSexInfo,
    handleEditSex,
} from '../actions/buyPoActions';
import { toastMessage } from '../../../../../Redux/Reducer/common';
import { useModalStatus } from '../../../../../commonHooks/useModalStatus';
import { ActivateItemModal } from './ActivateItemModal';

const CreateSexModal = ({
    showModal,
    closeModalHandler,
    editSexDetails,
    onUpdate,
}: ISexModal) => {
    const [sexDetail, setSexDetail] = useState({
        ...BuyPoOrderConstants.initialState.sexDetail,
    });
    const [sexError, setSexError] = useState({
        ...BuyPoOrderConstants.initialState.sexError,
    });
    const [loader, setLoading] = useState(false);
    const [inActiveSex, setInActiveSex] = useState<ISexDetail>();
    const {
        isOpen: isActivateModalOpen,
        open: openActivateModal,
        close: closeActivateModal,
    } = useModalStatus();
    const dispatch = useAppDispatch();

    const setSexErrorFields = (fieldName: string, value: boolean) => {
        const sexErrorCopy = { ...sexError };
        sexErrorCopy[fieldName] = value;
        setSexError(sexErrorCopy);
    };

    const setSexDetailFields = (
        fieldName: string,
        fieldValue: string,
        type?: string,
    ) => {
        const isValid = validateAccordingType(type, fieldValue);

        if (isValid) {
            const sexDetailCopy = { ...sexDetail };
            sexDetailCopy[fieldName] = fieldValue;
            setSexDetail(sexDetailCopy);
            if (sexError[fieldName]) {
                setSexErrorFields(fieldName, false);
            }
        }
    };

    const fetchSexPayload = () => {
        return { ...sexDetail, name: sexDetail.name.trim() };
    };

    const fetchSexId = (response: IFetchSexResponse) => {
        return response.data ? response.data.data.id : '';
    };

    const fetchEditSexPayload = () => ({
        name: sexDetail.name,
        id: editSexDetails?.id,
        isActive: sexDetail.isActive,
    });

    const handleCreateSexResponse = (response: IFetchSexResponse) => {
        onUpdate?.(fetchEditSexPayload());
        const sexId = fetchSexId(response);
        dispatch(toastMessage(getToastContent(response.status)));
        closeModalHandler(true, sexId);
    };

    const handleActivateSex = async () => {
        const inactiveSex = await fetchInactiveSexInfo(sexDetail.name.trim());
        setInActiveSex(inactiveSex[0]);
        openActivateModal();
    };

    const dynamicForm = (
        inputKey: string,
        inputLabel: string,
        valueAndMaxLength: IValueAndMaxLength,
        valueType?: string,
        inputType = 'text',
    ) => {
        return (
            <>
                <Form.Label className="text-secondary">
                    {inputLabel}
                    <span className="necessaryFields">*</span>
                </Form.Label>
                <Form.Control
                    {...valueAndMaxLength}
                    onChange={e =>
                        setSexDetailFields(inputKey, e.target.value, valueType)
                    }
                    style={{
                        borderColor: sexError[inputKey] ? 'red' : '',
                    }}
                    className="text-secondary"
                    size="sm"
                    type={inputType}
                />
            </>
        );
    };

    const validateSexFields = () => {
        const sexDetailKeys = Object.keys(sexDetail);
        const sexErrorCopy = { ...sexError };
        let isError = false;

        sexDetailKeys.forEach(sexDetailKey => {
            if (
                !sexDetail[sexDetailKey] &&
                sexDetailKey !== BuyPOGlobalInfoConstants.isActive
            ) {
                sexErrorCopy[sexDetailKey] = true;
                isError = true;
            }
        });

        setSexError(sexErrorCopy);
        return isError;
    };

    const handleCreateSex = () => {
        const isError = validateSexFields();
        if (!isError) {
            if (!editSexDetails) {
                dispatch(
                    createNewSex(
                        setLoading,
                        fetchSexPayload,
                        handleCreateSexResponse,
                        handleActivateSex,
                    ),
                );
            } else {
                dispatch(
                    handleEditSex(
                        setLoading,
                        handleCreateSexResponse,
                        fetchEditSexPayload,
                        handleActivateSex,
                    ),
                );
            }
        }
    };

    const handleCloseActivateModal = async (
        isActive: boolean,
    ): Promise<void> => {
        if (isActive) {
            if (inActiveSex) {
                const inActiveSexCopy = {
                    ...inActiveSex,
                    isActive: true,
                };
                await dispatch(activateSex(inActiveSexCopy));
                closeActivateModal();
                closeModalHandler(true, inActiveSex.id);
            }
        } else {
            closeActivateModal();
        }
    };

    useEffect(() => {
        setSexDetail({
            ...BuyPoOrderConstants.initialState.sexDetail,
        });
        setSexError({ ...BuyPoOrderConstants.initialState.sexError });
    }, [showModal]);

    useEffect(() => {
        if (editSexDetails && Object.keys(editSexDetails).length > 0) {
            setSexDetail(editSexDetails);
        }
    }, [editSexDetails]);

    return (
        <>
            <ActivateItemModal
                showModal={isActivateModalOpen}
                closeModalHandler={handleCloseActivateModal}
                itemName={sexDetail.name.trim()}
                item={BuyPoOrderConstants.form.sex.activateModalConstant}
            />
            <Modal
                className="px-2 pt-5"
                size="lg"
                show={showModal}
                centered
                animation
                onHide={() => closeModalHandler(false, '')}
            >
                {loader && <FullPageLoader />}
                <Modal.Header closeButton className="border-bottom-0">
                    <Modal.Title className="text-dark">
                        {!editSexDetails
                            ? BuyPoOrderConstants.form.sex.headingLabel.create
                            : BuyPoOrderConstants.form.sex.headingLabel.update}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="border-bottom">
                        <div className="col-xl-6 col-lg-6 start-top mb-4">
                            {dynamicForm(
                                BuyPoOrderConstants.dynamicFields.sexName.key,
                                BuyPoOrderConstants.dynamicFields.sexName.label,
                                {
                                    value: sexDetail.name,
                                    maxLength:
                                        BuyPoOrderConstants.dynamicFields
                                            .sexName.maxLength,
                                },
                                BuyPoOrderConstants.dynamicFields.sexName
                                    .valueType,
                            )}
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer className="border-top-0">
                    <Button
                        variant="secondary"
                        className="secondary"
                        onClick={() => closeModalHandler(false, '')}
                    >
                        {CommonConstants.en.cancel}
                    </Button>
                    <Button variant="secondary" onClick={handleCreateSex}>
                        {!editSexDetails
                            ? CommonConstants.en.create
                            : CommonConstants.en.update}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateSexModal;
