import { useNavigate } from 'react-router-dom';
import { cattlePO } from '../../apps/shippingTracker/featureFlags/cattlePOFeatures';
import { setRoute, setShowModal } from '../../Redux/Reducer/feedBillUpdate';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';

const SidebarLogo = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { lastUpdate } = useAppSelector(state => state.feedBillUpdate);
    const handleClick = () => {
        if (lastUpdate) {
            dispatch(setShowModal(true));
            dispatch(setRoute('/'));
        } else {
            navigate('/');
        }
    };
    const { isBP828On_inconsistentLogoBug } = cattlePO;
    const logo = isBP828On_inconsistentLogoBug
        ? 'sidebar_financial.png'
        : 'sidebar_financial_old.png';

    return (
        <img
            {...props}
            alt="./sidebar_financial.png"
            src={require(`./${logo}`)}
            title={`CC v${process.env.REACT_APP_VERSION || '0.0.0.0'}`}
            onClick={handleClick}
        />
    );
};

export default SidebarLogo;
