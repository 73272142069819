import { IMedicalItem, IMedicalLot } from './MedicalItemInterface';

export interface IProcessingWorkset {
    processingWorksetId: number | string;
    lotId: number | string;
    lot?: ILot;
    hdCountProcessed: number | string;
    hdCountReprocessed: number | string;
    processingPens: IProcessingPen[];
    processingMedicalItems: IProcessingMedicalItem[];
    processingProtocolId: number | string;
    status: string;
    createdDatetime: string;
    processedDate: string;
    averageWeight?: number;
    //LotAverageWeight is just for supporting Lot Summary
    lotAverageWeight?: number;
}

export interface ILot {
    lotId: number;
    name: string;
    hdCount: number;
    totalHdCount: number;
    hdCountProcessed: number;
    hdCountReprocessed: number | null;
    owner: {
        id: number;
        name: string;
    };
}

export interface IWorksheetProcessingProtocols {
    lotId: number;
    lot: ILot;
    processingPens: INewProcessingPen[];
    processingMedicalItems: IProcessingMedicalItem[];
    processedDate: string;
    totalPrice: number;
    lotAverageWeight: number;
    processingProtocol: {
        processingProtocolId: number;
        name: string;
    };
}
export interface INewProcessingWorksheet {
    lotId: number | string | undefined;
    processingWorksetId?: number;
    processingPens: INewProcessingPen[];
    previousProcessingPens?: INewProcessingPen[];
    processingMedicalItemIds: number[];
    processingProtocolId: number | string;
    processedDate: string;
    worksheetType: string;
    status: string;
    averageWeight?: number;
}

export interface IProcessingChargeoutPayload {
    lotId: number | string;
    hdCountProcessed: number;
    processingPens: IProcessingPen[];
    processingMedicalItems: IProcessingMedicalItem[];
    processingProtocolId: number | string;
    status?: string;
    processedDate: string;
    averageWeight?: number;
}

export interface IProcessingWorksheetPayload {
    lotId: number | string;
    processingPens: IProcessingPen[];
    processingMedicalItems: INewProcessingMedicalItem[];
    processingProtocolId: number | string | null;
    status: string;
    averageWeight?: number;
    processedDate: string;
}

export interface IExistingMedicalItems {
    medicalItemId?: number;
    medicalLotId?: number;
    hdCountTreated: number;
    actualDose?: number;
}

export interface INewProcessingWorksheetErrors {
    lotId: boolean;
    processingPens: boolean;
    processingProtocolId: boolean;
    processedDate: boolean;
}

export interface INewProcessingPen {
    penId: number | string;
    name: string;
    hdCount: number | string;
    hdCountToProcess: number | string;
    hdCountProcessed?: number | string;
}

export interface IProcessingPen {
    penId: number | string;
    name: string;
    hdCount: number | string;
    hdCountToProcess: number | string;
    hdCountProcessed: number | string;
}

export interface IProcessingMedicalItem {
    medicalItemId: number | string;
    medicalItem?: IMedicalItem;
    hdCountTreated: number | string;
    actualDose?: number;
}

export interface IProcessingChargeoutMedicalItem {
    medicalItemId: number | string;
    medicalItem: IMedicalItem;
    hdCountTreated: number | string;
    medicalLotId?: number | string;
    medicalLotIds?: IMedicalLot[];
    medicalLotIdError: boolean;
    actualDose?: number;
}

export interface INewProcessingMedicalItem {
    medicalItemId: number | string;
}

export interface IProcessingLot {
    lotId: number;
    name: string;
    hdCount: number;
    hdCountReprocessed: number;
    hdCountProcessed: number;
    totalHdCount: number;
    averageDailyGain: number;
    feedLotId: number;
    lotAverageWeight: number;
    owner: {
        id: number;
        name: string;
    };
    processingWorksets: IProcessingWorkset[];
    ownerId: number;
    ownerName?: string;
    cattleTypeId: number;
    sexId: number;
    pens: IPen[];
}

export enum ProcessingSubmitButtonOptions {
    SaveAndPrint = 'Save and Print',
    Save = 'Save',
    DoNotProcess = 'Do Not Process',
    Delete = 'Delete',
    Submit = 'Submit',
    Yes = 'Yes',
    No = 'No',
    NoneSelected = 'None Selected',
}

export enum ProcessingWorksheetStatus {
    All = 'All',
    Started = 'Started',
    InProgress = 'In Progress',
    Complete = 'Complete',
}

export interface IPen {
    penId: number;
    name: string;
    hdCount: number;
    feedLotId: number;
    type: number;
    penRationScheduleId: number;
}

export interface IProcessingReprocessingLotInfoBase {
    worksetTypes: string[];
    header: string;
    headCountLabel: string;
    getHeadCount: (lot: IProcessingLot) => number;
    worksheetType: string;
}
