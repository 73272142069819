import { useState, useMemo, useEffect } from 'react';
import { TenantSettingsConstant } from '../../utils/constants/TenantSettingsConstant';
import { KillGroupCheckbox } from '../../apps/shippingTracker/modules/tenantSettings/KillGroupCheckbox';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import StorageService from '../../Services/storage/storage.service';
import {
    checkModalItemAssociationNew,
    deleteItem,
    fetchKillGroup,
} from '../../Redux/Actions/tenantSettingsAction';
import BuyPoSettingsItemDetail from '../../apps/shippingTracker/modules/tenantSettings/BuypoSettingsItemDetail';
import {
    IBaseDetail,
    ISettingsKillGroupDetail,
} from '../../apps/shippingTracker/modules/BuyPo/interface/SettingsInterface';
import { useDebouncedEffect } from '../useDebounceEffect';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import KillGroupModal from '../../apps/shippingTracker/modules/BuyPo/components/CreateKillGroupModal';
import { activateKillGroup } from '../../apps/shippingTracker/modules/BuyPo/actions/buyPoActions';
import {
    IDeleteIds,
    ITenantSettingsFilter,
} from '../../utils/Interface/TenantSettingsInterface';
import { DeleteItemRowNew } from '../tenantSettings/DeleteItemRowNew';
import Constants from '../../utils/Constants';
import { FilterSearchBox } from '../../apps/shippingTracker/modules/tenantSettings/FilterSearchBox';

const KillGroupSettingsNew = () => {
    const { filter, loading } = useAppSelector(state => state.tenantSetting);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedKillGroup, setSelectedKillGroup] =
        useState<ISettingsKillGroupDetail>();
    const {
        isOpen: isEditModalOpen,
        open: openEditModal,
        close: closeEditModal,
    } = useModalStatus();
    const {
        isOpen: isDeleteModalOpen,
        open: openDeleteModal,
        close: closeDeleteModal,
    } = useModalStatus();
    const [killGroup, setKillGroup] = useState<ISettingsKillGroupDetail[]>([]);
    const dispatch = useAppDispatch();
    const storage = useMemo(() => new StorageService(), []);

    const handleEditKillGroupDetail = (
        itemDetail: ISettingsKillGroupDetail,
    ): void => {
        setSelectedKillGroup(itemDetail);
        openEditModal();
    };

    const setTenantSettingsFilterInStorage = (
        filterValues: ITenantSettingsFilter,
    ) => {
        storage.setValue(
            TenantSettingsConstant.filter.tenantSettingsFilter,
            filterValues,
            Constants.Storage.LOCAL,
        );
    };

    const handleFilter = (name: string, value: string): void => {
        const updatedFilter = { ...filter };
        updatedFilter.killGroupFilter[name] = value;
        dispatch(fetchKillGroup(setKillGroup, updatedFilter.killGroupFilter));
        setTenantSettingsFilterInStorage(updatedFilter);
    };

    const handleChange = async (index: number) => {
        const KillGroupList = [...killGroup];
        const updatedKillGroupList = {
            ...KillGroupList[index],
            isActive: !KillGroupList[index].isActive,
        };
        await dispatch(activateKillGroup(updatedKillGroupList));
        dispatch(fetchKillGroup(setKillGroup, filter.killGroupFilter));
    };

    const handleItemUpdateFromModal = (
        updatedItem: ISettingsKillGroupDetail,
    ) => {
        let updatedIndex = killGroup.findIndex(
            item => item.killGroupId === updatedItem.killGroupId,
        );

        if (updatedIndex !== -1) {
            setKillGroup(prevItems => {
                const updatedItemsList = [...prevItems];
                updatedItemsList[updatedIndex] = {
                    ...updatedItem,
                };

                return updatedItemsList;
            });
        }
    };

    const closeModalHandler = () => {
        closeEditModal();
    };

    const handleDeleteModalHandler = async (
        killGroup: ISettingsKillGroupDetail,
    ) => {
        if (killGroup.killGroupId) {
            const isAssociated = await dispatch(
                checkModalItemAssociationNew(
                    killGroup.killGroupId,
                    TenantSettingsConstant.filter.killGroup.filterType,
                ),
            );
            let updatedKillGroup = {
                ...killGroup,
                isAssociated: isAssociated,
            };
            setSelectedKillGroup(updatedKillGroup);
            openDeleteModal();
        }
    };

    const handleDeleteKillGroup = async (shouldTakeAction: boolean) => {
        if (shouldTakeAction && selectedKillGroup) {
            const isActiveAndAssociated =
                selectedKillGroup.isAssociated && selectedKillGroup.isActive;
            if (isActiveAndAssociated) {
                const updatedKillGroupList = {
                    ...selectedKillGroup,
                    isActive: !selectedKillGroup.isActive,
                };
                await dispatch(activateKillGroup(updatedKillGroupList));
            } else {
                const deleteIds = [
                    { killGroupId: selectedKillGroup.killGroupId },
                ] as IDeleteIds[];
                await dispatch(
                    deleteItem(
                        deleteIds,
                        TenantSettingsConstant.filter.killGroup.filterType,
                    ),
                );
            }
            dispatch(fetchKillGroup(setKillGroup, filter.killGroupFilter));
        }
        closeDeleteModal();
    };

    useDebouncedEffect(
        () => {
            if (searchQuery !== undefined) {
                handleFilter('name', searchQuery);
            }
        },
        [searchQuery],
        1000,
    );

    useEffect(() => {
        dispatch(fetchKillGroup(setKillGroup, filter.killGroupFilter));
    }, [dispatch, storage]);

    return (
        <div className="settings-container">
            <KillGroupCheckbox />
            <div className="my-3">
                <h5>{TenantSettingsConstant.filter.killGroup.heading}</h5>
                <FilterSearchBox
                    filterType={
                        TenantSettingsConstant.filter.killGroup.filterType
                    }
                    handleFilter={handleFilter}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                ></FilterSearchBox>
                <BuyPoSettingsItemDetail<IBaseDetail>
                    columnDetail={
                        TenantSettingsConstant.filter.killGroup.columnDetail
                    }
                    itemDetail={killGroup}
                    handleChange={handleChange}
                    handleEditDetail={handleEditKillGroupDetail}
                    handleDeleteModalHandler={handleDeleteModalHandler}
                    loader={loading}
                    filterType={
                        TenantSettingsConstant.filter.killGroup.filterType
                    }
                ></BuyPoSettingsItemDetail>
                {isEditModalOpen && (
                    <KillGroupModal
                        editKillGroupDetails={selectedKillGroup}
                        showModal={isEditModalOpen}
                        closeModalHandler={closeModalHandler}
                        onUpdate={handleItemUpdateFromModal}
                    />
                )}
            </div>
            {isDeleteModalOpen && selectedKillGroup && (
                <DeleteItemRowNew
                    item={selectedKillGroup}
                    handleDeleteItem={handleDeleteKillGroup}
                    showModal={isDeleteModalOpen}
                    itemName={TenantSettingsConstant.filter.killGroup.label}
                ></DeleteItemRowNew>
            )}
        </div>
    );
};

export default KillGroupSettingsNew;
