import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    Row,
} from 'react-bootstrap';
import { IMoveFromHospitalPenModal } from '../../utils/Interface/HealthSection/HealthScreenInterface';
import { IUpdateAnimalPayload } from '../../utils/Interface/HospitalInterface';
import { useLanguage } from '../../commonHooks/useLocalization';
import { MoveFromHospitalPenModalConstants } from '../../utils/constants/MoveFromHospitalPenModalConstants';
import { useEffect, useState } from 'react';
import { fetchMovePens_deprecated } from '../../Redux/Actions/treatmentsActions';
import {
    fetchAnimalsHospital,
    fetchMovePens,
    moveCattleToDifferentPen,
} from '../../Redux/Actions/hospitalActions';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';

const { isCA10313On_HospitalPageUnassociatedHomePen } = feedlotManagerFeatures;

const MoveFromHospitalPenModal = ({
    showModal,
    closeModalHandler,
    selectedAnimal,
    penFilter,
}: IMoveFromHospitalPenModal) => {
    const lotId = selectedAnimal?.lotId ?? '';
    const lotName = selectedAnimal?.lotName ?? '';
    const animalId = selectedAnimal?.animalId ?? '';
    const animalTag = selectedAnimal?.animalTag ?? '';
    const hospitalPen = selectedAnimal?.currentPenName ?? '';
    const hospitalPenId = selectedAnimal?.currentPenId ?? '';
    const homePenName = selectedAnimal?.homePenName ?? '';
    const homePenId = selectedAnimal?.homePenId ?? '';

    const dispatch = useAppDispatch();

    const language = useLanguage();
    const feedlotId = useFeedlotId();

    const { movePens } = useAppSelector(state => state.treatments);

    const [moveToPen, setMoveToPen] = useState('');
    const [moveToPenError, setmoveToPenError] = useState(false);
    const [showHomePenMessage, setShowHomePenMessage] = useState(false);

    const getTodaysDateStringFormatted = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const moveDate = getTodaysDateStringFormatted();

    useEffect(() => {
        if (lotId && feedlotId) {
            if (isCA10313On_HospitalPageUnassociatedHomePen) {
                dispatch(
                    fetchMovePens(
                        feedlotId ?? 0,
                        +lotId,
                        setShowHomePenMessage,
                    ),
                );
            } else {
                dispatch(fetchMovePens_deprecated(feedlotId ?? 0, +lotId));
            }
        }
    }, [animalId, feedlotId, lotId, dispatch]);

    const onSubmit = () => {
        if (moveToPen && feedlotId) {
            const payload: IUpdateAnimalPayload = {
                animalId: +animalId,
                moveDate: new Date(moveDate).toISOString(),
                currentPenId: hospitalPenId.toString(),
                movePenId: moveToPen,
            };
            dispatch(
                moveCattleToDifferentPen(
                    feedlotId,
                    payload,
                    language.value,
                    penFilter,
                ),
            ).then(() => {
                dispatch(fetchAnimalsHospital(feedlotId, penFilter));
            });
            closeModalHandler(true);
            setMoveToPen('');
        } else {
            setmoveToPenError(true);
        }
    };

    const moveToPenHandler = (penId: string) => {
        setMoveToPen(penId);
        setmoveToPenError(false);
    };

    const addHomePenToMovePens =
        homePenId && !movePens.some(movePen => movePen.penId === homePenId);

    return (
        <Modal
            show={showModal}
            onHide={() => closeModalHandler()}
            className="px-2 pt-5"
            data-size="md"
            centered={true}
        >
            <ModalHeader closeButton>
                <Modal.Title className="text-secondary">
                    <h5 className="modal-title">
                        {
                            MoveFromHospitalPenModalConstants[language.value]
                                .moveFromHospitalPen
                        }
                    </h5>
                </Modal.Title>
            </ModalHeader>
            <ModalBody>
                <div className="modal-body-inner">
                    <Row className="mb-4">
                        <Form.Group className="col-sm-4">
                            <Form.Label>
                                {
                                    MoveFromHospitalPenModalConstants[
                                        language.value
                                    ].moveDate
                                }
                            </Form.Label>
                            <Form.Control
                                type="date"
                                name="date"
                                value={moveDate}
                                readOnly
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group className="col-sm-4">
                            <Form.Label>
                                {
                                    MoveFromHospitalPenModalConstants[
                                        language.value
                                    ].lot
                                }
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="lot"
                                value={lotName}
                                readOnly
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group className="col-sm-4">
                            <Form.Label>
                                {
                                    MoveFromHospitalPenModalConstants[
                                        language.value
                                    ].animalId
                                }
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="animalId"
                                value={animalTag}
                                readOnly
                            ></Form.Control>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="col-sm-4">
                            <Form.Label>
                                {
                                    MoveFromHospitalPenModalConstants[
                                        language.value
                                    ].hospitalPen
                                }
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="hospitalPen"
                                value={hospitalPen}
                                readOnly
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group className="col-sm-4">
                            <Form.Label>
                                {
                                    MoveFromHospitalPenModalConstants[
                                        language.value
                                    ].homePen
                                }
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="homePen"
                                value={homePenName}
                                readOnly
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group className="col">
                            <Form.Label className="me-0">
                                {
                                    MoveFromHospitalPenModalConstants[
                                        language.value
                                    ].moveToPen
                                }
                            </Form.Label>
                            <span className="necessaryFields">*</span>

                            <Form.Control
                                onChange={e => {
                                    moveToPenHandler(e.target.value);
                                }}
                                name="moveToPen"
                                required
                                as="select"
                                value={moveToPen}
                                className={
                                    moveToPenError ? 'border-danger' : ''
                                }
                            >
                                <option value="">
                                    {
                                        MoveFromHospitalPenModalConstants[
                                            language.value
                                        ].selectPen
                                    }
                                </option>
                                {!isCA10313On_HospitalPageUnassociatedHomePen &&
                                    addHomePenToMovePens && (
                                        <option value={homePenId}>
                                            {homePenName}
                                        </option>
                                    )}
                                {movePens?.map(pen => (
                                    <option key={pen.penId} value={pen.penId}>
                                        {pen.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="col-sm-12">
                            <Form.Label>
                                {isCA10313On_HospitalPageUnassociatedHomePen &&
                                    showHomePenMessage &&
                                    MoveFromHospitalPenModalConstants[
                                        language.value
                                    ].noHomePensMessage}
                            </Form.Label>
                        </Form.Group>
                    </Row>
                </div>
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    onClick={() => closeModalHandler()}
                    className="secondary"
                    data-bs-dismiss="modal"
                >
                    {MoveFromHospitalPenModalConstants[language.value].cancel}
                </button>
                <button type="button" onClick={onSubmit}>
                    {MoveFromHospitalPenModalConstants[language.value].move}
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default MoveFromHospitalPenModal;
