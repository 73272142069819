import { useState, useEffect, ReactElement } from 'react';

import SideNavTab from './sidenav/SideNavTab';
import SideNavBuyPO from './sidenav/SideNavBuyPO';
import SideNavFM from './sidenav/SideNavFM';
import SideNavFinancial from './sidenav/SideNavFinancial';
import SidebarLogoBuyPo from '../Assets/sidebarlogos/SidebarLogoBuyPo';
import SidebarLogoFinancial from '../Assets/sidebarlogos/SidebarLogoFinancial';
import SidebarLogoNGAT from '../Assets/sidebarlogos/SidebarLogoNGAT';
import SidebarLogoFM from '../Assets/sidebarlogos/SidebarLogoFM';
import useAuthContext from '../apps/common/modules/auth/hooks/useAuthContext';
import { useAppDispatch, useAppSelector } from '../Redux/Store';
import { IGenericSetState } from '../utils/Interface/CommonInterface';
import { useLocation } from 'react-router-dom';
import { setActive } from '../Redux/Reducer/common';

function SideNav({
    sideNavTab,
    setSideNavTab,
}: {
    sideNavTab: string | number;
    setSideNavTab: IGenericSetState<string | number>;
}) {
    const { isAuthenticated } = useAuthContext();
    const [sideNavLogoComponent, setSideNavLogoComponent] =
        useState<ReactElement>();
    const [sideNavMenuComponent, setSideNavMenuComponent] =
        useState<ReactElement>();

    const { isNavActive } = useAppSelector(state => state.common);

    const dispatch = useAppDispatch();

    const handleClick = event => {
        isNavActive ? dispatch(setActive(false)) : dispatch(setActive(true));
    };
    const location = useLocation();

    useEffect(() => {
        if (
            location.pathname === '/feedcall' ||
            location.pathname === '/feedcallv2' ||
            location.pathname === '/treatments/addtreatment' ||
            location.pathname.includes('/treatments/updatetreatment/')
        ) {
            dispatch(setActive(true));
        }
    }, [dispatch, location.pathname]);

    useEffect(() => {
        const getSideNavLogoAndMenu = () => {
            let logoComponent = <SidebarLogoNGAT />;
            let menuComponent = <></>;
            if (sideNavTab === 'buyPo') {
                logoComponent = <SidebarLogoBuyPo />;
                menuComponent = <SideNavBuyPO />;
            } else if (sideNavTab === 'financial') {
                logoComponent = <SidebarLogoFinancial />;
                menuComponent = <SideNavFinancial />;
            } else if (sideNavTab === 'fm') {
                logoComponent = <SidebarLogoFM />;
                menuComponent = <SideNavFM />;
            }
            setSideNavLogoComponent(logoComponent);
            setSideNavMenuComponent(menuComponent);
        };
        getSideNavLogoAndMenu();
    }, [sideNavTab]);

    return (
        <>
            {isAuthenticated() ? (
                <>
                    <nav
                        className={
                            isNavActive
                                ? 'd-md-active sidenav sidebar-shrink'
                                : 'd-md-active sidenav'
                        }
                    >
                        <div className="sidebar-logo">
                            {sideNavLogoComponent}
                        </div>
                        <SideNavTab setSideNavTab={setSideNavTab} />
                        {sideNavMenuComponent}
                    </nav>

                    <div
                        className={
                            isNavActive
                                ? 'sidenav-caret caret-slide-left d-md-block d-none'
                                : 'sidenav-caret d-md-block d-none'
                        }
                        onClick={handleClick}
                    >
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a>
                            <i className="fas fa-caret-left"></i>
                        </a>
                    </div>
                </>
            ) : null}
        </>
    );
}

export default SideNav;
