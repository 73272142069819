import { Modal, Button } from 'react-bootstrap';
import { TenantSettingsConstant } from '../../utils/constants/TenantSettingsConstant';
import { IDeleteItemRowNew } from '../../apps/shippingTracker/modules/BuyPo/interface/SettingsInterface';
import { useLanguage } from '../../commonHooks/useLocalization';
import { CommonConstants } from '../../utils/constants/CommonConstants';

export const DeleteItemRowNew = ({
    item,
    handleDeleteItem,
    showModal,
    itemName,
}: IDeleteItemRowNew) => {
    const language = useLanguage();

    return (
        <Modal
            className="px-2 pt-5"
            show={showModal}
            centered
            animation
            onHide={() => handleDeleteItem(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title className="text-secondary">
                    <h5 className="modal-title">
                        {item.isAssociated &&
                            (item.isActive
                                ? TenantSettingsConstant.deleteConfirmationModal
                                      .header.associationHeader
                                : TenantSettingsConstant.deleteConfirmationModal
                                      .header.importantNoticeHeader)}
                    </h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="preline-text">
                {item.isAssociated
                    ? item.isActive
                        ? TenantSettingsConstant.deleteConfirmationModal.message.associationMessage.replace(
                              /{Item Name}/g,
                              itemName,
                          )
                        : TenantSettingsConstant.deleteConfirmationModal.message
                              .importantNoticeMessage
                    : TenantSettingsConstant.deleteConfirmationModal.message.confirmationMessage.replace(
                          /{Item Name}/g,
                          itemName,
                      )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="secondary"
                    variant="secondary"
                    onClick={() => handleDeleteItem(false)}
                >
                    {item.isAssociated
                        ? item.isActive
                            ? CommonConstants[language.value].no
                            : CommonConstants[language.value].ok
                        : CommonConstants[language.value].cancel}
                </Button>

                {item.isAssociated && !item.isActive ? null : (
                    <Button
                        variant="secondary"
                        onClick={() => handleDeleteItem(true)}
                    >
                        {item.isAssociated
                            ? CommonConstants[language.value].yes
                            : CommonConstants[language.value].delete}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};
