import { useState, useEffect } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { validateAccordingType } from '../../../../../utils/validation';
import Constants from '../../../../../utils/Constants';
import { useAppDispatch } from '../../../../../Redux/Store';
import { getToastContent } from '../../../../../utils/ToastContent';
import FullPageLoader from '../../../../../components/common/FullPageLoader';
import { TruckingCompanyModalConstants } from '../constants/TruckingCompanyModalConstants';
import {
    activateTruckingCompany,
    fetchInactiveTruckingCompanyInfo,
    handleCreateNewTruckingCompany,
    handleEditTruckingCompany,
} from '../../BuyPo/actions/buyPoActions';
import {
    IBuyPoTruckingCompanyDetail,
    IFetchTruckingCompanyResponse,
} from '../../BuyPo/interface/BuyPoInterface';
import { useModalStatus } from '../../../../../commonHooks/useModalStatus';
import { ActivateItemModal } from '../../BuyPo/components/ActivateItemModal';
import { BuyPOGlobalInfoConstants } from '../../BuyPo/constants/BuyPoConstants';
import { removeCountryCodePrefix } from '../../../../../helpers';
import { ITruckingCompany } from '../interfaces/TruckingCompanyInterface';
import { toastMessage } from '../../../../../Redux/Reducer/common';

const CreateTruckingCompanyModal = (props: any) => {
    const [truckingCompanyDetail, setTruckingCompanyDetail] = useState<any>({
        ...TruckingCompanyModalConstants.truckingCompanyDetailState,
    });
    const [truckingCompanyError, setTruckingCompanyError] = useState<any>({
        ...TruckingCompanyModalConstants.truckingCompanyErrorState,
    });
    const [inActiveTruckingCompany, setInActiveTruckingCompany] =
        useState<IBuyPoTruckingCompanyDetail>();
    const dispatch = useAppDispatch();
    const [loader, setLoader] = useState<any>(false);
    const modalBodyTopInputFields = Object.keys(
        TruckingCompanyModalConstants.form,
    ).filter((_, index) => index < 3);
    const modalBodyBottomInputFields = Object.keys(
        TruckingCompanyModalConstants.form,
    ).filter((_, index) => index > 2);
    const {
        isOpen: isActivateModalOpen,
        open: openActivateModal,
        close: closeActivateModal,
    } = useModalStatus();

    const setTruckingCompanyDetailKeys = (
        fieldName: string,
        value: any,
        type: string | undefined | null = null,
    ) => {
        if (validateAccordingType(type, value) || value === '') {
            const truckingCompanyObj = { ...truckingCompanyDetail };
            truckingCompanyObj[fieldName] = value;

            setTruckingCompanyDetail(truckingCompanyObj);
            if (truckingCompanyError[fieldName]) {
                setTruckingCompanyErrorKeys(fieldName, false);
            }
        }
    };

    const setTruckingCompanyErrorKeys = (errorKey: string, errorValue: any) => {
        const truckingCompanyErrorCopy = { ...truckingCompanyError };
        truckingCompanyErrorCopy[errorKey] = errorValue;
        setTruckingCompanyError(truckingCompanyErrorCopy);
    };

    const fetchTruckingCompanyDetail = () => {
        return {
            ...truckingCompanyDetail,
            name: truckingCompanyDetail.name.trim(),
        };
    };

    const fetchTruckingCompanyId = (
        response: IFetchTruckingCompanyResponse,
    ) => {
        return response.data ? response.data.data.id : '';
    };

    const createTruckingCompanyResponseHandler = async (
        response: IFetchTruckingCompanyResponse,
    ) => {
        if (!response) return;
        const status = Number(response.status);
        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.TRUCKING_COMPANY_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            const inactiveTruckingCompanyInfo =
                await fetchInactiveTruckingCompanyInfo(
                    truckingCompanyDetail.name.trim(),
                );
            setInActiveTruckingCompany(inactiveTruckingCompanyInfo[0]);
            openActivateModal();
        } else if (
            status >= Constants.responseCode.SUCCESS &&
            status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            props.onUpdate?.(truckingCompanyDetail);
            const truckingCompanyId = fetchTruckingCompanyId(response);
            dispatch(toastMessage(getToastContent(response.status)));
            props.closeModalHandler(true, truckingCompanyId);
        }
    };

    const createTruckingCompany = async () => {
        setLoader(true);
        if (!props.editTruckingCompanyDetails) {
            handleCreateNewTruckingCompany(
                setLoader,
                fetchTruckingCompanyDetail,
                createTruckingCompanyResponseHandler,
            );
        } else {
            const updatedTruckingCompanyDetail = { ...truckingCompanyDetail };
            addPlusOneIfBothExist(updatedTruckingCompanyDetail);

            handleEditTruckingCompany(
                setLoader,
                updatedTruckingCompanyDetail,
                createTruckingCompanyResponseHandler,
            );
        }
    };

    const addPlusOneIfBothExist = (truckingCompanyDetail: ITruckingCompany) => {
        const countryCode = TruckingCompanyModalConstants.countryCode;

        if (truckingCompanyDetail.contactNumber) {
            truckingCompanyDetail.contactNumber = `${countryCode}${truckingCompanyDetail.contactNumber}`;
        }
        if (truckingCompanyDetail.altContactNumber) {
            truckingCompanyDetail.altContactNumber = `${countryCode}${truckingCompanyDetail.altContactNumber}`;
        }

        return truckingCompanyDetail;
    };

    const validateFields = () => {
        const truckingCompanyKeys = Object.keys(truckingCompanyError);
        const truckingCompanyErrorCopy = { ...truckingCompanyError };
        let isError = false;
        truckingCompanyKeys.forEach(truckingCompanyKey => {
            //when field is required
            if (
                TruckingCompanyModalConstants.form[truckingCompanyKey]
                    .isRequired &&
                !truckingCompanyDetail[truckingCompanyKey]
            ) {
                truckingCompanyErrorCopy[truckingCompanyKey] = true;
                isError = true;
            } else if (
                TruckingCompanyModalConstants.form[truckingCompanyKey]
                    .validateType
            ) {
                //when validate according to type
                const isValid = validateAccordingType(
                    TruckingCompanyModalConstants.form[truckingCompanyKey]
                        .validateType,
                    truckingCompanyDetail[truckingCompanyKey],
                );
                if (!isValid) {
                    truckingCompanyErrorCopy[truckingCompanyKey] = true;
                    isError = true;
                }
            }
        });
        setTruckingCompanyError(truckingCompanyErrorCopy);
        return isError;
    };

    const createNewTruckingCompany = () => {
        const isError = validateFields();
        if (!isError) {
            createTruckingCompany();
        }
    };

    useEffect(() => {
        if (!props.showModal) {
            setTruckingCompanyDetail({
                ...TruckingCompanyModalConstants.truckingCompanyDetailState,
            });
            setTruckingCompanyError({
                ...TruckingCompanyModalConstants.truckingCompanyErrorState,
            });
        }
    }, [props.showModal]);

    useEffect(() => {
        if (
            props.editTruckingCompanyDetails &&
            Object.keys(props.editTruckingCompanyDetails).length > 0
        ) {
            const editedDetail = { ...props.editTruckingCompanyDetails };

            editedDetail.contactNumber =
                removeCountryCodePrefix(editedDetail.contactNumber) ?? '';
            editedDetail.altContactNumber =
                removeCountryCodePrefix(editedDetail.altContactNumber) ?? '';

            setTruckingCompanyDetail(editedDetail);
        }
    }, [props.editTruckingCompanyDetails]);

    const handleCloseActivateModal = async (
        isActive: boolean,
    ): Promise<void> => {
        if (isActive) {
            if (inActiveTruckingCompany) {
                const inActiveTruckingCompanyCopy = {
                    ...inActiveTruckingCompany,
                    isActive: true,
                };
                await dispatch(
                    activateTruckingCompany(inActiveTruckingCompanyCopy),
                );
                closeActivateModal();
                props.closeModalHandler(
                    true,
                    inActiveTruckingCompany.companyId,
                );
            }
        } else {
            closeActivateModal();
        }
    };

    const ModalBodyTopInput = (fieldName: string, fieldIndex: number) => {
        return (
            <div
                key={fieldIndex}
                className={`col-xl-12 col-lg-12 start-top ${
                    fieldIndex === 2 ? 'mb-4' : 'mb-2'
                }`}
            >
                <Form.Label className="text-secondary">
                    {TruckingCompanyModalConstants.form[fieldName].label}
                    {TruckingCompanyModalConstants.form[fieldName]
                        .isRequired && (
                        <span className="necessaryFields">*</span>
                    )}
                </Form.Label>
                <div className="input-group">
                    {(fieldName === 'contactNumber' ||
                        fieldName === 'altContactNumber') && (
                        <div className="input-group-prepend">
                            <span className="input-group-text">+1</span>
                        </div>
                    )}

                    <Form.Control
                        style={{
                            borderColor: truckingCompanyError[fieldName]
                                ? 'red'
                                : '',
                        }}
                        value={truckingCompanyDetail[fieldName]}
                        onChange={e =>
                            setTruckingCompanyDetailKeys(
                                fieldName,
                                e.target.value,
                                TruckingCompanyModalConstants.form[fieldName]
                                    .type,
                            )
                        }
                        maxLength={
                            TruckingCompanyModalConstants.form[fieldName]
                                .maxLength
                        }
                        className="text-secondary"
                        size="sm"
                        type="text"
                        data-cy={
                            TruckingCompanyModalConstants.form[fieldName].dataCy
                        }
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            <ActivateItemModal
                showModal={isActivateModalOpen}
                closeModalHandler={handleCloseActivateModal}
                itemName={truckingCompanyDetail.name.trim()}
                item={
                    BuyPOGlobalInfoConstants.form.truckingCompany
                        .activateModalConstant
                }
            />
            <Modal
                className="px-2 pt-5"
                size="lg"
                show={props.showModal}
                centered={true}
                animation={true}
                onHide={() => props.closeModalHandler(false, '')}
            >
                {loader ? <FullPageLoader /> : null}
                <Modal.Header closeButton className="border-bottom-0">
                    <Modal.Title className="text-dark">
                        {!props.editTruckingCompanyDetails
                            ? TruckingCompanyModalConstants.title.create
                            : TruckingCompanyModalConstants.title.update}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="border-bottom">
                        {modalBodyTopInputFields.map((fieldName, fieldIndex) =>
                            ModalBodyTopInput(fieldName, fieldIndex),
                        )}
                    </div>
                    <div className="mt-3">
                        {modalBodyBottomInputFields.map(
                            (fieldName, fieldIndex) =>
                                ModalBodyTopInput(fieldName, fieldIndex),
                        )}
                        <div className="col-xl-12 col-lg-12">
                            <div className="mx-0 my-3 d-flex flex-column">
                                <div className="d-flex align-items-center">
                                    <input
                                        className="text-secondary send-notification-checkbox me-2"
                                        checked={
                                            truckingCompanyDetail.sendNotification
                                        }
                                        onChange={() =>
                                            setTruckingCompanyDetailKeys(
                                                'sendNotification',
                                                !truckingCompanyDetail.sendNotification,
                                            )
                                        }
                                        data-size="sm"
                                        type="checkbox"
                                    />
                                    <Form.Label className="text-secondary m-0">
                                        {
                                            TruckingCompanyModalConstants.sendNotification
                                        }
                                    </Form.Label>
                                </div>

                                {truckingCompanyDetail.sendNotification ? (
                                    <div className="send-notification mt-3">
                                        <div className="col-xl-12 col-lg-12 start-top mb-2 form-item">
                                            <Form.Label>
                                                Trucking Company:
                                            </Form.Label>
                                            <div>
                                                {truckingCompanyDetail.name}
                                            </div>
                                        </div>

                                        <div className="col-xl-12 col-lg-12 start-top mb-2 form-item">
                                            <Form.Label>
                                                Trucking Manager 1:
                                            </Form.Label>
                                            <div>
                                                {truckingCompanyDetail.contactName ? (
                                                    <>
                                                        {
                                                            truckingCompanyDetail.contactName
                                                        }{' '}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {truckingCompanyDetail.contactNumber ? (
                                                    <>
                                                        {
                                                            truckingCompanyDetail.contactNumber
                                                        }{' '}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {truckingCompanyDetail.email ? (
                                                    <>
                                                        {
                                                            truckingCompanyDetail.email
                                                        }{' '}
                                                        <br />
                                                    </>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 start-top mb-2 form-item border-bottom">
                                            <Form.Label>
                                                Trucking Manager 2:
                                            </Form.Label>
                                            <div>
                                                {truckingCompanyDetail.altContactName ? (
                                                    <>
                                                        {
                                                            truckingCompanyDetail.altContactName
                                                        }{' '}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {truckingCompanyDetail.altContactNumber ? (
                                                    <>
                                                        {
                                                            truckingCompanyDetail.altContactNumber
                                                        }{' '}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {truckingCompanyDetail.altEmail ? (
                                                    <>
                                                        {
                                                            truckingCompanyDetail.altEmail
                                                        }{' '}
                                                        <br />
                                                    </>
                                                ) : null}
                                            </div>
                                        </div>
                                        {props.buyPODestinationDetail ? (
                                            <>
                                                {props.buyPODestinationDetail
                                                    .name ? (
                                                    <div className="col-xl-12 col-lg-12 start-top mb-2 form-item">
                                                        <Form.Label>
                                                            Destination Company:
                                                        </Form.Label>
                                                        <div>
                                                            {props
                                                                .buyPODestinationDetail
                                                                .name || ''}
                                                        </div>
                                                    </div>
                                                ) : null}
                                                {props.buyPODestinationDetail
                                                    .contactName ||
                                                props.buyPODestinationDetail
                                                    .contactNumber ||
                                                props.buyPODestinationDetail
                                                    .email ? (
                                                    <div className="col-xl-12 col-lg-12 start-top mb-2 form-item">
                                                        <Form.Label>
                                                            Destination Manager
                                                            1:
                                                        </Form.Label>
                                                        <div>
                                                            {props
                                                                .buyPODestinationDetail
                                                                ?.contactName ? (
                                                                <>
                                                                    {
                                                                        props
                                                                            .buyPODestinationDetail
                                                                            ?.contactName
                                                                    }{' '}
                                                                    <br />
                                                                </>
                                                            ) : null}
                                                            {props
                                                                .buyPODestinationDetail
                                                                ?.contactNumber ? (
                                                                <>
                                                                    {
                                                                        props
                                                                            .buyPODestinationDetail
                                                                            ?.contactNumber
                                                                    }{' '}
                                                                    <br />
                                                                </>
                                                            ) : null}
                                                            {props
                                                                .buyPODestinationDetail
                                                                ?.email ? (
                                                                <>
                                                                    {
                                                                        props
                                                                            .buyPODestinationDetail
                                                                            ?.email
                                                                    }{' '}
                                                                    <br />
                                                                </>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </>
                                        ) : null}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer className="border-top-0">
                    <Button
                        className="secondary"
                        onClick={() => props.closeModalHandler(false, '')}
                    >
                        Cancel
                    </Button>
                    <Button onClick={createNewTruckingCompany}>
                        {!props.editTruckingCompanyDetails
                            ? BuyPOGlobalInfoConstants.create
                            : BuyPOGlobalInfoConstants.update}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateTruckingCompanyModal;
