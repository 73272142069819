import { useState, useMemo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Store';
import {
    IBaseDetail,
    ISettingsSexDetail,
} from '../BuyPo/interface/SettingsInterface';
import { useModalStatus } from '../../../../commonHooks/useModalStatus';
import StorageService from '../../../../Services/storage/storage.service';
import {
    IDeleteIds,
    ITenantSettingsFilter,
} from '../../../../utils/Interface/TenantSettingsInterface';
import { TenantSettingsConstant } from '../../../../utils/constants/TenantSettingsConstant';
import Constants from '../../../../utils/Constants';
import {
    checkModalItemAssociationNew,
    deleteItem,
    fetchSex,
} from '../../../../Redux/Actions/tenantSettingsAction';
import { activateSex } from '../BuyPo/actions/buyPoActions';
import { FilterSearchBox } from '../tenantSettings/FilterSearchBox';
import BuyPoSettingsItemDetail from '../tenantSettings/BuypoSettingsItemDetail';
import { DeleteItemRowNew } from '../../../../components/tenantSettings/DeleteItemRowNew';
import { useDebouncedEffect } from '../../../../components/useDebounceEffect';
import CreateSexModal from '../BuyPo/components/CreateSexModal';
import { SexVisibilityCheckbox } from './SexVisibilityCheckbox';

const SexSettings = () => {
    const { filter, loading } = useAppSelector(state => state.tenantSetting);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedSex, setSelectedSex] = useState<ISettingsSexDetail>();
    const {
        isOpen: isEditModalOpen,
        open: openEditModal,
        close: closeEditModal,
    } = useModalStatus();
    const {
        isOpen: isDeleteModalOpen,
        open: openDeleteModal,
        close: closeDeleteModal,
    } = useModalStatus();
    const [sex, setSex] = useState<ISettingsSexDetail[]>([]);
    const dispatch = useAppDispatch();
    const storage = useMemo(() => new StorageService(), []);

    const handleEditSexDetail = (item: IBaseDetail): void => {
        const sex = item as ISettingsSexDetail;
        setSelectedSex(sex);
        openEditModal();
    };

    const setTenantSettingsFilterInStorage = (
        filterValues: ITenantSettingsFilter,
    ) => {
        storage.setValue(
            TenantSettingsConstant.filter.tenantSettingsFilter,
            filterValues,
            Constants.Storage.LOCAL,
        );
    };

    const handleFilter = (name: string, value: string): void => {
        const updatedFilter = { ...filter };
        updatedFilter.sexFilter[name] = value;
        dispatch(fetchSex(setSex, updatedFilter.sexFilter));
        setTenantSettingsFilterInStorage(updatedFilter);
    };

    const handleChange = async (index: number) => {
        const sexList = [...sex];
        const updatedSexList = {
            ...sexList[index],
            isActive: !sexList[index].isActive,
        };
        await dispatch(activateSex(updatedSexList));
        dispatch(fetchSex(setSex, filter.sexFilter));
    };

    const handleItemUpdateFromModal = (updatedItem: ISettingsSexDetail) => {
        const updatedIndex = sex.findIndex(item => item.id === updatedItem.id);

        if (updatedIndex !== -1) {
            setSex(prevItems => {
                const updatedItemsList = [...prevItems];
                updatedItemsList[updatedIndex] = {
                    ...updatedItem,
                };

                return updatedItemsList;
            });
        }
    };

    const closeModalHandler = () => {
        closeEditModal();
    };

    const handleDeleteModalHandler = async (item: IBaseDetail) => {
        const sex = item as ISettingsSexDetail;
        if (sex.id) {
            const isAssociated = await dispatch(
                checkModalItemAssociationNew(
                    Number(sex.id),
                    TenantSettingsConstant.filter.sex.filterType,
                ),
            );
            const updatedSex = {
                ...sex,
                isAssociated: isAssociated,
            };
            setSelectedSex(updatedSex);
            openDeleteModal();
        }
    };

    const handleDeleteSex = async (shouldTakeAction: boolean) => {
        if (shouldTakeAction && selectedSex) {
            const isActiveAndAssociated =
                selectedSex.isAssociated && selectedSex.isActive;
            if (isActiveAndAssociated) {
                const updatedSexList = {
                    ...selectedSex,
                    isActive: !selectedSex.isActive,
                };
                await dispatch(activateSex(updatedSexList));
            } else {
                const deleteIds = [{ id: selectedSex.id }] as IDeleteIds[];
                await dispatch(
                    deleteItem(
                        deleteIds,
                        TenantSettingsConstant.filter.sex.filterType,
                    ),
                );
            }
            dispatch(fetchSex(setSex, filter.sexFilter));
        }
        closeDeleteModal();
    };

    useDebouncedEffect(
        () => {
            if (searchQuery !== undefined) {
                handleFilter('name', searchQuery);
            }
        },
        [searchQuery],
        1000,
    );

    useEffect(() => {
        dispatch(fetchSex(setSex, filter.sexFilter));
    }, [dispatch, storage]);

    return (
        <div className="settings-container">
            <SexVisibilityCheckbox />
            <div className="my-3">
                <h5>{TenantSettingsConstant.filter.sex.heading}</h5>
                <FilterSearchBox
                    filterType={TenantSettingsConstant.filter.sex.filterType}
                    handleFilter={handleFilter}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                ></FilterSearchBox>
                <BuyPoSettingsItemDetail<IBaseDetail>
                    columnDetail={
                        TenantSettingsConstant.filter.sex.columnDetail
                    }
                    itemDetail={sex}
                    handleChange={handleChange}
                    handleEditDetail={handleEditSexDetail}
                    handleDeleteModalHandler={handleDeleteModalHandler}
                    loader={loading}
                    filterType={TenantSettingsConstant.filter.sex.filterType}
                ></BuyPoSettingsItemDetail>
                {isEditModalOpen && selectedSex && (
                    <CreateSexModal
                        editSexDetails={selectedSex}
                        showModal={isEditModalOpen}
                        closeModalHandler={closeModalHandler}
                        onUpdate={handleItemUpdateFromModal}
                    />
                )}
            </div>
            {isDeleteModalOpen && selectedSex && (
                <DeleteItemRowNew
                    item={selectedSex}
                    handleDeleteItem={handleDeleteSex}
                    showModal={isDeleteModalOpen}
                    itemName={TenantSettingsConstant.filter.sex.label}
                ></DeleteItemRowNew>
            )}
        </div>
    );
};

export default SexSettings;
