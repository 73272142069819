import { useEffect, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { getToastContent } from '../../../../../utils/ToastContent';
import FullPageLoader from '../../../../../components/common/FullPageLoader';
import { validateAccordingType } from '../../../../../utils/validation';
import { BuyPOGlobalInfoConstants, BuyPoOrderConstants } from '../../BuyPo/constants/BuyPoConstants';
import { useAppDispatch } from '../../../../../Redux/Store';
import { CommonConstants } from '../../../../../utils/constants/CommonConstants';
import {
    IColorDetail,
    IColorModal,
    IFetchColorResponse,
    IValueAndMaxLength,
} from '../interface/BuyPoInterface';
import {
    activateColor,
    createNewColor,
    fetchInactiveColorInfo,
    handleEditColor,
} from '../actions/buyPoActions';
import { toastMessage } from '../../../../../Redux/Reducer/common';
import { ActivateItemModal } from './ActivateItemModal';
import { useModalStatus } from '../../../../../commonHooks/useModalStatus';

const CreateColorModal = ({
    showModal,
    closeModalHandler,
    editColorDetails,
    onUpdate,
}: IColorModal) => {
    const [colorDetail, setColorDetail] = useState<IColorDetail>({
        ...BuyPoOrderConstants.initialState.colorDetail,
    });
    const [colorError, setColorError] = useState({
        ...BuyPoOrderConstants.initialState.colorError,
    });
    const [inActiveColor, setInActiveColor] = useState<IColorDetail>();
    const {
        isOpen: isActivateModalOpen,
        open: openActivateModal,
        close: closeActivateModal,
    } = useModalStatus();
    const [loader, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    const setColorErrorFields = (fieldName: string, value: boolean) => {
        const colorErrorCopy = { ...colorError };
        colorErrorCopy[fieldName] = value;
        setColorError(colorErrorCopy);
    };

    const setColorDetailFields = (
        fieldName: string,
        fieldValue: string,
        type?: string,
    ) => {
        const isValid = validateAccordingType(type, fieldValue);

        if (isValid) {
            const colorDetailCopy = { ...colorDetail };
            colorDetailCopy[fieldName] = fieldValue;
            setColorDetail(colorDetailCopy);
            if (colorError[fieldName]) {
                setColorErrorFields(fieldName, false);
            }
        }
    };

    const fetchColorPayload = () => {
        return { ...colorDetail, name: colorDetail.name.trim() };
    };

    const fetchColorId = (response: IFetchColorResponse) => {
        return response.data ? response.data.data.id : '';
    };

    const fetchEditColorPayload = () => ({
        name: colorDetail.name,
        id: editColorDetails?.id,
        isActive: colorDetail.isActive,
    });

    const handleCreateColorResponse = (response: IFetchColorResponse) => {
        onUpdate?.(fetchEditColorPayload());
        const colorId = fetchColorId(response);
        dispatch(toastMessage(getToastContent(response.status)));
        closeModalHandler(true, colorId);
    };

    const dynamicForm = (
        inputKey: string,
        inputLabel: string,
        valueAndMaxLength: IValueAndMaxLength,
        valueType?: string,
        inputType = 'text',
    ) => {
        return (
            <>
                <Form.Label className="text-secondary">
                    {inputLabel}
                    <span className="necessaryFields">*</span>
                </Form.Label>
                <Form.Control
                    {...valueAndMaxLength}
                    onChange={e =>
                        setColorDetailFields(
                            inputKey,
                            e.target.value,
                            valueType,
                        )
                    }
                    style={{
                        borderColor: colorError[inputKey] ? 'red' : '',
                    }}
                    className="text-secondary"
                    size="sm"
                    type={inputType}
                />
            </>
        );
    };

    const handleActivateColor = async () => {
        const inactiveColor = await fetchInactiveColorInfo(
            colorDetail.name.trim(),
        );
        setInActiveColor(inactiveColor[0]);
        openActivateModal();
    };

    const validateColorFields = () => {
        const colorDetailKeys = Object.keys(colorDetail);
        const colorErrorCopy = { ...colorError };
        let isError = false;

        colorDetailKeys.forEach(colorDetailKey => {
            if (
                !colorDetail[colorDetailKey] &&
                colorDetailKey !== BuyPOGlobalInfoConstants.isActive
            ) {
                colorErrorCopy[colorDetailKey] = true;
                isError = true;
            }
        });

        setColorError(colorErrorCopy);
        return isError;
    };

    const handleCreateColor = () => {
        const isError = validateColorFields();
        if (!isError) {
            if (!editColorDetails) {
                dispatch(
                    createNewColor(
                        setLoading,
                        fetchColorPayload,
                        handleCreateColorResponse,
                        handleActivateColor,
                    ),
                );
            } else {
                dispatch(
                    handleEditColor(
                        setLoading,
                        handleCreateColorResponse,
                        fetchEditColorPayload,
                        handleActivateColor,
                    ),
                );
            }
        }
    };

    const handleCloseActivateModal = async (
        isActive: boolean,
    ): Promise<void> => {
        if (isActive) {
            if (inActiveColor) {
                const inActiveColorCopy = {
                    ...inActiveColor,
                    isActive: true,
                };
                await dispatch(activateColor(inActiveColorCopy));
                closeActivateModal();
                closeModalHandler(true, inActiveColor.id);
            }
        } else {
            closeActivateModal();
        }
    };

    useEffect(() => {
        setColorDetail({
            ...BuyPoOrderConstants.initialState.colorDetail,
        });
        setColorError({ ...BuyPoOrderConstants.initialState.colorError });
    }, [showModal]);

    useEffect(() => {
        if (editColorDetails && Object.keys(editColorDetails).length > 0) {
            setColorDetail(editColorDetails);
        }
    }, [editColorDetails]);

    return (
        <>
            <ActivateItemModal
                showModal={isActivateModalOpen}
                closeModalHandler={handleCloseActivateModal}
                itemName={colorDetail.name.trim()}
                item={BuyPoOrderConstants.form.color.activateModalConstant}
            />
            <Modal
                className="px-2 pt-5"
                size="lg"
                show={showModal}
                centered
                animation
                onHide={() => closeModalHandler(false, '')}
            >
                {loader && <FullPageLoader />}
                <Modal.Header closeButton className="border-bottom-0">
                    <Modal.Title className="text-dark">
                        {!editColorDetails
                            ? BuyPoOrderConstants.form.color.headingLabel.create
                            : BuyPoOrderConstants.form.color.headingLabel
                                  .update}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="border-bottom">
                        <div className="col-xl-6 col-lg-6 start-top mb-4">
                            {dynamicForm(
                                BuyPoOrderConstants.dynamicFields.colorName.key,
                                BuyPoOrderConstants.dynamicFields.colorName
                                    .label,
                                {
                                    value: colorDetail.name,
                                    maxLength:
                                        BuyPoOrderConstants.dynamicFields
                                            .colorName.maxLength,
                                },
                                BuyPoOrderConstants.dynamicFields.colorName
                                    .valueType,
                            )}
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer className="border-top-0">
                    <Button
                        variant="secondary"
                        className="secondary"
                        onClick={() => closeModalHandler(false, '')}
                    >
                        {CommonConstants.en.cancel}
                    </Button>
                    <Button variant="secondary" onClick={handleCreateColor}>
                        {!editColorDetails
                            ? CommonConstants.en.create
                            : CommonConstants.en.update}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateColorModal;
