import { useEffect, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { validateAccordingType } from '../../../../utils/validation';
import Constants from '../../../../utils/Constants';
import { getToastContent } from '../../../../utils/ToastContent';
import FullPageLoader from '../../../../components/common/FullPageLoader';
import { StateList } from '../../../../utils/constants/StateListConstants';
import { ProvincesTerritoryList } from '../../../../utils/constants/ProvincesTerritoryList';
import {
    BuyPOGlobalInfoConstants,
    LocationModalConstants,
} from '../BuyPo/constants/BuyPoConstants';
import {
    ICreateBuyPoLocationModal,
    IFetchLocationResponse,
    IInactiveBuyPoLocation,
    ILocationDetail,
    ILocationError,
    IState,
} from '../BuyPo/interface/BuyPoInterface';
import { useAppDispatch } from '../../../../Redux/Store';
import {
    activateLocation,
    fetchInactiveLocationInfo,
    handleCreateLocation,
    handleEditLocation,
} from '../BuyPo/actions/buyPoActions';
import { useModalStatus } from '../../../../commonHooks/useModalStatus';
import { ActivateItemModal } from '../BuyPo/components/ActivateItemModal';
import { cattlePO } from '../../featureFlags/cattlePOFeatures';
import { toastMessage } from '../../../../Redux/Reducer/common';

const LocationModal = ({
    closeModalHandler,
    editLocationDetails,
    showModal,
    onUpdate,
    shipPOApiUrl,
}: ICreateBuyPoLocationModal) => {
    const [states, setStates] = useState<Array<IState>>([]);
    const [stateLabel, setStateLabel] = useState<string>(
        LocationModalConstants.form.state.label,
    );
    const [loading, setLoading] = useState(false);
    const [locationDetail, setLocationDetail] = useState<ILocationDetail>({
        ...LocationModalConstants.initialState.locationDetail,
    });
    const [locationError, setLocationError] = useState<ILocationError>({
        ...LocationModalConstants.initialState.locationError,
    });
    const { isBP798On_addCanadaLocation } = cattlePO;
    const dispatch = useAppDispatch();
    const [inActiveLocation, setInActiveLocation] =
        useState<IInactiveBuyPoLocation>();
    const {
        isOpen: isActivateModalOpen,
        open: openActivateModal,
        close: closeActivateModal,
    } = useModalStatus();

    const handleLocationErrors = (
        locationDetailKey: string,
        locationDetailValue: boolean,
    ) => {
        const locationErrorCopy = {
            ...locationError,
            [locationDetailKey]: locationDetailValue,
        };
        setLocationError(locationErrorCopy);
    };

    const handleLocationDetail = (
        fieldName: string,
        value: string,
        type?: string,
    ) => {
        const isValid = validateAccordingType(type, value);
        if (isValid) {
            const locationCopy = { ...locationDetail };
            locationCopy[fieldName] = value;

            setLocationDetail(locationCopy);

            if (locationError.hasOwnProperty(fieldName)) {
                handleLocationErrors(fieldName, false);
            }
        }
    };

    const fetchLocationPayload = () => {
        return { ...locationDetail, name: locationDetail.name.trim() };
    };

    const fetchEditLocationPayload = () => {
        return {
            name: locationDetail.name.trim(),
            id: locationDetail.id || '',
            isActive: locationDetail.isActive,
            addressInfo: {
                address1: locationDetail.address1,
                address2: locationDetail.address2,
                city: locationDetail.city,
                state: locationDetail.state,
                country: locationDetail.country,
                postalCode: locationDetail.postalCode,
                coordinates: locationDetail.coordinates,
            },
        };
    };

    const fetchLocationId = (locationResponse: IFetchLocationResponse) => {
        return locationResponse.data ? locationResponse.data.data.id : '';
    };

    const handleCreateLocationResponse = async (
        locationResponse: IFetchLocationResponse,
    ) => {
        if (!locationResponse) return;
        const status = Number(locationResponse.status);
        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        status,
                        '',
                        Constants.customMessage.LOCATION_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            const inactiveLocationInfo = await fetchInactiveLocationInfo(
                locationDetail.name.trim(),
            );
            setInActiveLocation(inactiveLocationInfo);
            openActivateModal();
        } else if (
            status >= Constants.responseCode.SUCCESS &&
            status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            dispatch(toastMessage(getToastContent(status)));
            const locationId = fetchLocationId(locationResponse);
            onUpdate?.(fetchEditLocationPayload());
            closeModalHandler(locationId, true);
        }
    };

    const dynamicForm = (
        inputKey: string,
        inputLabel: string,
        valueAndMaxLength: { value?: string; maxLength?: number },
        valueType?: string,
        inputType = 'text',
    ) => {
        return (
            <>
                <Form.Label className="text-secondary">
                    {inputLabel}
                    <span className="necessaryFields">*</span>
                </Form.Label>
                <Form.Control
                    {...valueAndMaxLength}
                    onChange={e =>
                        handleLocationDetail(
                            inputKey,
                            e.target.value,
                            valueType,
                        )
                    }
                    className={`text-secondary border ${
                        locationError[inputKey] ? 'border-danger' : ''
                    }`}
                    size="sm"
                    type={inputType}
                />
            </>
        );
    };

    const validateLocation = () => {
        let isError = false;
        const locationErrorCopy = { ...locationError };

        if (!locationDetail.name.trim()) {
            locationErrorCopy.name = true;
            isError = true;
        }

        const isFormValid = validateForm(locationErrorCopy);

        return isError ? isError : !isFormValid;
    };

    const validateForm = (locationErrorCopy: ILocationError) => {
        let isFormValid = true;
        const validateFields = ['postalCode', 'coordinates'];
        const validateTypes = ['zipcode', 'coordinates'];

        validateFields.forEach((validateField, index) => {
            const isValid = validateAccordingType(
                locationDetail.country === LocationModalConstants.canadaState &&
                    validateField === LocationModalConstants.form.zipCode.name
                    ? 'canadaZipcode'
                    : validateTypes[index],
                locationDetail[validateField],
            );

            if (!locationErrorCopy[validateField] && !isValid) {
                locationErrorCopy[validateField] =
                    LocationModalConstants.errorMessageArray[index];
            }

            if (isFormValid && !isValid) {
                isFormValid = false;
            }
        });

        setLocationError(locationErrorCopy);

        return isFormValid;
    };

    const handleCloseModal = () => {
        closeModalHandler();
    };

    const handleSubmit = () => {
        const isError = validateLocation();

        if (!isError) {
            if (!editLocationDetails) {
                handleCreateLocation(
                    setLoading,
                    fetchLocationPayload,
                    handleCreateLocationResponse,
                    shipPOApiUrl,
                );
            } else {
                handleEditLocation(
                    setLoading,
                    fetchEditLocationPayload,
                    handleCreateLocationResponse,
                );
            }
        }
    };

    useEffect(() => {
        if (!showModal) {
            setLocationDetail(
                LocationModalConstants.initialState.locationDetail,
            );
            setLocationError(LocationModalConstants.initialState.locationError);
        }
    }, [showModal]);

    useEffect(() => {
        if (locationDetail.country === LocationModalConstants.canadaState) {
            setStateLabel(LocationModalConstants.form.state.provinceLabel);
            setStates(ProvincesTerritoryList);
        } else {
            setStateLabel(LocationModalConstants.form.state.label);
            setStates(StateList);
        }
    }, [locationDetail.country]);

    useEffect(() => {
        setStates([...StateList]);
    }, []);

    useEffect(() => {
        if (
            editLocationDetails &&
            Object.keys(editLocationDetails).length > 0
        ) {
            setLocationDetail(editLocationDetails);
        }
    }, [editLocationDetails]);

    const handleCloseActivateModal = async (
        isActive: boolean,
    ): Promise<void> => {
        if (isActive) {
            if (inActiveLocation) {
                const inActiveLocationCopy: IInactiveBuyPoLocation = {
                    ...inActiveLocation,
                    isActive: true,
                };
                await dispatch(activateLocation(inActiveLocationCopy));
                closeActivateModal();
                closeModalHandler(inActiveLocation.id, true);
            }
        } else {
            closeActivateModal();
        }
    };

    return (
        <>
            <ActivateItemModal
                showModal={isActivateModalOpen}
                closeModalHandler={handleCloseActivateModal}
                itemName={locationDetail.name.trim()}
                item={
                    BuyPOGlobalInfoConstants.form.location.activateModalConstant
                }
            />
            <Modal
                className="px-2 pt-5"
                size="lg"
                centered
                animation
                show={showModal}
                onHide={handleCloseModal}
            >
                {loading && <FullPageLoader />}
                <Modal.Header closeButton className="border-bottom-0">
                    <Modal.Title className="text-dark">
                        {!editLocationDetails
                            ? shipPOApiUrl
                                ? LocationModalConstants.header.shipCreate
                                : LocationModalConstants.header.create
                            : LocationModalConstants.header.update}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="border-bottom">
                        <div className="col-xl-6 col-lg-6 start-top mb-4">
                            {dynamicForm(
                                LocationModalConstants.form.name.name,
                                LocationModalConstants.form.name.label,
                                {
                                    value: locationDetail.name,
                                    maxLength:
                                        LocationModalConstants.form.name
                                            .maxLength,
                                },
                            )}
                        </div>
                    </div>
                    <div className="border-bottom mt-4">
                        <div className="row mx-0 mb-lg-2">
                            <div className="col-xl-6 col-lg-6 start-top mb-2 px-0">
                                <Form.Label className="text-secondary">
                                    {LocationModalConstants.form.address1.label}
                                </Form.Label>
                                <Form.Control
                                    name={
                                        LocationModalConstants.form.address1
                                            .name
                                    }
                                    value={locationDetail.address1}
                                    maxLength={
                                        LocationModalConstants.form.address1
                                            .maxLength
                                    }
                                    onChange={e =>
                                        handleLocationDetail(
                                            e.target.name,
                                            e.target.value,
                                        )
                                    }
                                    className="text-secondary"
                                    size="sm"
                                    type="text"
                                />
                            </div>
                            <div className="col-xl-6 col-lg-6 start-top mb-2 px-xl-2 px-lg-2 px-0">
                                <Form.Label className="text-secondary">
                                    {
                                        LocationModalConstants.form
                                            .gpsCoordinates.label
                                    }
                                </Form.Label>
                                <Form.Control
                                    placeholder={
                                        LocationModalConstants.form
                                            .gpsCoordinates.placeholder
                                    }
                                    className="text-secondary"
                                    name={
                                        LocationModalConstants.form
                                            .gpsCoordinates.name
                                    }
                                    value={locationDetail.coordinates}
                                    maxLength={
                                        LocationModalConstants.form
                                            .gpsCoordinates.maxLength
                                    }
                                    onChange={e =>
                                        handleLocationDetail(
                                            e.target.name,
                                            e.target.value,
                                        )
                                    }
                                    size="sm"
                                    type="text"
                                />
                                {locationError.coordinates &&
                                typeof locationError.coordinates !==
                                    'boolean' ? (
                                    <Form.Label className="text-danger">
                                        {locationError.coordinates}
                                    </Form.Label>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 start-top mb-2">
                            <Form.Label className="text-secondary">
                                {LocationModalConstants.form.address2.label}
                            </Form.Label>
                            <Form.Control
                                name={LocationModalConstants.form.address2.name}
                                value={locationDetail.address2}
                                maxLength={
                                    LocationModalConstants.form.address2
                                        .maxLength
                                }
                                onChange={e =>
                                    handleLocationDetail(
                                        e.target.name,
                                        e.target.value,
                                    )
                                }
                                className="text-secondary"
                                size="sm"
                                type="text"
                                ata-cy="addressTwo"
                            />
                        </div>
                        <div className="row mx-0 mb-lg-2">
                            <div className="col-xl-6 col-lg-6 mb-2 start-top px-0">
                                <Form.Label className="text-secondary">
                                    {LocationModalConstants.form.city.label}
                                </Form.Label>
                                <Form.Control
                                    value={locationDetail.city}
                                    maxLength={
                                        LocationModalConstants.form.city
                                            .maxLength
                                    }
                                    name={LocationModalConstants.form.city.name}
                                    onChange={event =>
                                        handleLocationDetail(
                                            event.target.name,
                                            event.target.value,
                                        )
                                    }
                                    className="text-secondary"
                                    size="sm"
                                    type="text"
                                />
                            </div>
                            <div className="col-xl-3 col-lg-3 mb-2 start-top ps-xl-2 ps-lg-2 px-0">
                                <Form.Label className="text-secondary">
                                    {stateLabel}
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    type="select"
                                    className="text-secondary"
                                    size="sm"
                                    name={
                                        LocationModalConstants.form.state.name
                                    }
                                    onChange={e =>
                                        handleLocationDetail(
                                            e.target.name,
                                            e.target.value,
                                        )
                                    }
                                    value={locationDetail.state}
                                >
                                    {states.map((state, key) => (
                                        <option key={key} value={state.value}>
                                            {state.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </div>
                            <div className="col-xl-3 col-lg-3 mb-2 start-top ps-xl-2 ps-lg-2 px-0">
                                <Form.Label className="text-secondary">
                                    {LocationModalConstants.form.zipCode.label}
                                </Form.Label>
                                <Form.Control
                                    placeholder={
                                        locationDetail.country ===
                                        LocationModalConstants.canadaState
                                            ? LocationModalConstants.form
                                                  .zipCode.canadaPlaceholder
                                            : LocationModalConstants.form
                                                  .zipCode.placeholder
                                    }
                                    className="text-secondary"
                                    value={locationDetail.postalCode}
                                    maxLength={
                                        LocationModalConstants.form.zipCode
                                            .maxLength
                                    }
                                    name={
                                        LocationModalConstants.form.zipCode.name
                                    }
                                    onChange={event =>
                                        handleLocationDetail(
                                            event.target.name,
                                            event.target.value,
                                        )
                                    }
                                    size="sm"
                                    type="text"
                                />
                                {locationError.postalCode &&
                                    typeof locationError.postalCode !==
                                        'boolean' && (
                                        <Form.Label className="text-danger">
                                            {locationError.postalCode}
                                        </Form.Label>
                                    )}
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 start-top mb-4">
                            <Form.Label className="text-secondary">
                                {LocationModalConstants.form.country.label}
                            </Form.Label>
                            {isBP798On_addCanadaLocation ? (
                                <Form.Control
                                    as="select"
                                    type="select"
                                    className="text-secondary"
                                    size="sm"
                                    name={
                                        LocationModalConstants.form.country.name
                                    }
                                    onChange={event =>
                                        handleLocationDetail(
                                            event.target.name,
                                            event.target.value,
                                        )
                                    }
                                    value={locationDetail.country}
                                >
                                    {BuyPOGlobalInfoConstants.countries.map(
                                        (country, key) => (
                                            <option
                                                key={key}
                                                value={country.value}
                                            >
                                                {country.name}
                                            </option>
                                        ),
                                    )}
                                </Form.Control>
                            ) : (
                                <Form.Control
                                    value="US"
                                    disabled
                                    className="text-secondary"
                                    size="sm"
                                    type="text"
                                />
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-top-0">
                    <Button className="secondary" onClick={handleCloseModal}>
                        {LocationModalConstants.footer.cancel}
                    </Button>
                    <Button onClick={handleSubmit}>
                        {!editLocationDetails
                            ? LocationModalConstants.footer.create
                            : LocationModalConstants.footer.update}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default LocationModal;
