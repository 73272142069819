import { AnyAction } from '@reduxjs/toolkit';
import {
    IInventory,
    IInventoryLots,
    INewInventory,
} from '../../utils/Interface/HealthSection/InventoryInterface';
import {
    IBottleSize,
    IMedicalLot,
} from '../../utils/Interface/HealthSection/MedicalItemInterface';
import { ActionTypes } from '../Actions';
import { MEDICAL_INVENTORY_INITIAL_STATE } from '../Constants/medicalInventoryInitialState';
import { ICsvData } from '../../utils/Interface/CommonInterface';

const MedicalInventoryReducer = (
    state = MEDICAL_INVENTORY_INITIAL_STATE,
    action: AnyAction,
) => {
    switch (action.type) {
        case ActionTypes.SET_MEDICAL_INVENTORY:
            return {
                ...state,
                medicalInventory: action.payload as IInventory[],
            };
        case ActionTypes.APPEND_MEDICAL_INVENTORY:
            return {
                ...state,
                medicalInventory: [
                    ...state.medicalInventory,
                    ...action.payload,
                ] as IInventory[],
            };
        case ActionTypes.SET_ADJUSTMENT_INVENTORY:
            return {
                ...state,
                adjustmentInventory: action.payload as IInventory[],
            };
        case ActionTypes.SET_COMPLETE_ADJUSTMENT_INVENTORY:
            return {
                ...state,
                completeMedicalInventory: action.payload as ICsvData[],
            };

        case ActionTypes.APPEND_ADJUSTMENT_INVENTORY:
            return {
                ...state,
                adjustmentInventory: [
                    ...state.adjustmentInventory,
                    ...action.payload,
                ] as IInventory[],
            };
        case ActionTypes.ADD_MEDICAL_INVENTORY:
            return {
                ...state,
                createMedicalInventory: action.payload as INewInventory,
            };
        case ActionTypes.SET_INVENTORY_LOTS:
            return {
                ...state,
                inventoryLots: action.payload as IInventoryLots[],
            };
        case ActionTypes.SET_MEDICAL_LOTS:
            return {
                ...state,
                medicalLots: action.payload as IMedicalLot[],
            };
        case ActionTypes.SET_MEDICAL_INVENTORY_FILTER:
            return {
                ...state,
                medicalInventoryFilter: action.payload,
            };
        case ActionTypes.SET_BOTTLE_SIZES:
            return {
                ...state,
                bottleSizes: action.payload as IBottleSize[],
            };
        case ActionTypes.SET_ADJUSTMENT_HISTORY_FILTER:
            return {
                ...state,
                adjustmentHistoryFilter: action.payload,
            };
        case ActionTypes.SET_MEDICAL_INVENTORY_LOADING:
            return {
                ...state,
                isLoadingMedicalInventory: action.payload,
            };
        case ActionTypes.UPDATE_SUBSEQUENT_LOT_CHANGE_AMOUNT:
            return {
                ...state,
                medicalInventory: state.medicalInventory.map(item => {
                    if (
                        +item.medicalItemId !==
                            +action.payload.inventory.medicalItemId ||
                        (item?.lotId ? +item.lotId : null) !==
                            +action.payload.inventory.lotId
                    ) {
                        return item;
                    } else {
                        return {
                            ...item,
                            subsequentLotChangeAmounts:
                                action.payload.subsequentLotChangeAmount,
                        };
                    }
                }),
            };
        case ActionTypes.UPDATE_ALL_SUBSEQUENT_LOT_CHANGE_AMOUNTS:
            return {
                ...state,
                medicalInventory: state.medicalInventory.map(item => {
                    if (
                        action.payload.allAdjustmentHistoryResponse[
                            item.medicalItemId
                        ]
                    ) {
                        return {
                            ...item,
                            subsequentLotChangeAmounts:
                                action.payload.allAdjustmentHistoryResponse[
                                    item.medicalItemId
                                ],
                        };
                    } else {
                        return {
                            ...item,
                            subsequentLotChangeAmounts: 0,
                        };
                    }
                }),
            };
        case ActionTypes.SET_MEDICAL_INVENTORY_SUBMIT_PENDING:
            return {
                ...state,
                submitPending: action.payload,
            };
        default:
            return state;
    }
};

export default MedicalInventoryReducer;
