import { LabelConstant } from '../../../../../utils/LabelConstant';
export const TruckingCompanyModalConstants = {
    title: {
        create: 'Create New Trucking Company',
        update: 'Update Trucking Company',
    },
    sendNotification: 'Send Notification',
    countryCode: '+1',
    truckingCompanyDetailState: {
        name: '',
        email: '',
        altEmail: '',
        contactNumber: '',
        altContactNumber: '',
        contactName: '',
        altContactName: '',
        sendNotification: false,
    },
    truckingCompanyErrorState: {
        name: '',
        email: '',
        altEmail: '',
        contactNumber: '',
        altContactNumber: '',
        contactName: '',
        altContactName: '',
    },
    form: {
        name: {
            label: 'Trucking Company Name',
            isRequired: true,
            type: 'string',
            dataCy: 'truckingCompanyName',
            maxLength: 30,
        },
        email: {
            label: 'Email 1',
            isRequired: true,
            type: null,
            validateType: 'email',
            dataCy: 'emailOne',
            maxLength: 50,
        },
        altEmail: {
            label: 'Email 2',
            isRequired: false,
            type: null,
            validateType: 'email',
            dataCy: 'emailTwo',
            maxLength: 50,
        },
        contactName: {
            label: 'Manager Name 1',
            isRequired: true,
            type: 'string',
            dataCy: 'managerNameOne',
            maxLength: 50,
        },
        altContactName: {
            label: 'Manager Name 2',
            isRequired: false,
            type: 'string',
            dataCy: 'managerNameTwo',
            maxLength: 50,
        },
        contactNumber: {
            label: 'Phone Number 1',
            isRequired: true,
            type: 'number',
            validateType: 'mobileLength',
            dataCy: 'phoneNumberOne',
            maxLength: 10,
        },
        altContactNumber: {
            label: 'Phone Number 2',
            isRequired: false,
            type: 'number',
            validateType: 'mobileLength',
            dataCy: 'phoneNumberTwo',
            maxLength: 10,
        },
    },
};

export const PickupDateModalConstants = {
    DESTINATIONS: [
        { name: LabelConstant.Destination.SELECT_DESTINATION, id: '' },
        {
            name: LabelConstant.Destination.CREATE_DESTINATION,
            id: LabelConstant.Destination.CREATE_DESTINATION,
        },
    ],
    TRUCKING_COMPANIES: [
        { name: LabelConstant.TruckingCompany.SELECT_COMPANY, companyId: '' },
        {
            name: LabelConstant.TruckingCompany.CREATE_COMPANY,
            companyId: LabelConstant.TruckingCompany.CREATE_COMPANY,
        },
    ],

    labels: {
        editDate: 'Edit Pick-up Date',
        newDate: 'New Pickup Date',
        truckingManager: {
            title: 'Trucking Company',
            manager1: 'Trucking Manager 1:',
            manager2: 'Trucking Manager 2:',
        },
        destinationManager: {
            title: 'Destination Company',
            manager1: 'Destination Manager 1:',
            manager2: 'Destination Manager 2:',
        },
        sendTextCC: 'Send a CC (copy) txt message to me:',
        sendEmailCC: 'Send a CC (copy) email to me:',
    },
    form: {
        labels: {
            destination: 'destination',
            truckingCompany: 'truckingCompany',
            email: 'email',
            altEmail: 'altEmail',
            altContactNumber: 'altContactNumber',
            mobile: 'mobile',
            contactNumber: 'contactNumber',
        },
        submit: {
            submitSendNotification: 'Save and Notify',
            submitDoNotSendNotification: 'Save Only',
        },
    },
    modalMessage: {
        doNotSend: 'and Do Not Send',
        sendSelected: 'and Send to selected',
        confirmMessage:
            'Would you like to send notifications to the following?',
    },
};
